import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angular2-qrcode';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { BoardUserComponent } from './board-user/board-user.component';

import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { CandidatesProfileComponent } from './candidates/candidates-profile/candidates-profile.component';
import { CandidatesPasswordResetComponent } from './candidates/candidates-password-reset/candidates-password-reset.component';
import { CandidatesListComponent } from './candidates/candidates-list/candidates-list.component';
import { CandidatesDetailComponent } from './candidates/candidates-detail/candidates-detail.component';
import { EmployerProfileComponent } from './employer/employer-profile/employer-profile.component';
import { EmployerPasswordResetComponent } from './employer/employer-password-reset/employer-password-reset.component';
import { EmployerPostJobComponent } from './employer/employer-post-job/employer-post-job.component';
import { JobListComponent } from './job-list/job-list.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { FileUploadModule } from 'ng2-file-upload';
import { EmployerManageJobsComponent } from './employer/employer-manage-jobs/employer-manage-jobs.component';
import { EmployerManageApplicationsComponent } from './employer/employer-manage-applications/employer-manage-applications.component';
import { CandidatesSortlistedJobComponent } from './candidates/candidates-sortlisted-job/candidates-sortlisted-job.component';
import { CandidatesAppliedJobComponent } from './candidates/candidates-applied-job/candidates-applied-job.component';
import { EmployerManageSubemployersComponent } from './employer/employer-manage-subemployers/employer-manage-subemployers.component';
import { EmployerCreateSubemployersComponent } from './employer/employer-create-subemployers/employer-create-subemployers.component';
import { ManageUsersComponent } from './admin/manage-users/manage-users.component';
import { ManageJobsComponent } from './admin/manage-jobs/manage-jobs.component';
import { PasswordResetComponent } from './admin/password-reset/password-reset.component';
import { EmployersListComponent } from './employer/employers-list/employers-list.component';
import { EmployersDetailComponent } from './employer/employers-detail/employers-detail.component';
import { ExamFormComponent } from './candidates/exam-form/exam-form.component';
import { FormatTimePipe } from './candidates/exam-form/exam-form.component';
import { ManageExamsComponent } from './admin/manage-exams/manage-exams.component';
import { ExamDetailsComponent } from './exam-details/exam-details.component';
import { EmployerManageExamsComponent } from './employer/employer-manage-exams/employer-manage-exams.component';
import { ExamVideoComponent } from './exam-video/exam-video.component';
import { VideoExamFormComponent } from './candidates/video-exam-form/video-exam-form.component';
import { EditJobComponent } from './admin/edit-job/edit-job.component';
import { ManageDeactivatedUsersComponent } from './admin/manage-deactivated-users/manage-deactivated-users.component';
import { UploadResumeComponent } from './candidates/upload-resume/upload-resume.component';
import { ExamAlertComponent } from './candidates/exam-alert/exam-alert.component';
import { CandidatesJobAlertComponent } from './candidates/candidates-job-alert/candidates-job-alert.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PricingComponent } from './pricing/pricing.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { Pricing1Component } from './pricing1/pricing1.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { JwPaginationModule } from 'jw-angular-pagination';
import { ConnectionServiceModule } from 'ng-connection-service';
import { EmployeefeaturesComponent } from './employeefeatures/employeefeatures.component';
import { EmployerfeaturesComponent } from './employerfeatures/employerfeatures.component';
import { ResumeTemplateComponent } from './candidates/resume-template/resume-template.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { AdminExamDetailsComponent } from './admin-exam-details/admin-exam-details.component';
import { UserSignupReportComponent } from './admin/user-signup-report/user-signup-report.component';
import { JobPostedReportComponent } from './admin/job-posted-report/job-posted-report.component';
import { JobDetailedReportComponent } from './admin/job-detailed-report/job-detailed-report.component';
import { ExamListComponent } from './admin/exam-list/exam-list.component';
import { AddBlogComponent } from './admin/add-blog/add-blog.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AdminBlogListComponent } from './admin/admin-blog-list/admin-blog-list.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { MonthlySignupReportComponent } from './admin/monthly-signup-report/monthly-signup-report.component';
import { ExcelService } from './excel.service';
import { EditBlogComponent } from './admin/edit-blog/edit-blog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    BoardAdminComponent,
    BoardModeratorComponent,
    BoardUserComponent,
    CandidatesProfileComponent,
    CandidatesPasswordResetComponent,
    CandidatesListComponent,
    CandidatesDetailComponent,
    EmployerProfileComponent,
    EmployerPasswordResetComponent,
    EmployerPostJobComponent,
    JobListComponent,
    JobDetailsComponent,
    EmployerManageJobsComponent,
    EmployerManageApplicationsComponent,
    CandidatesSortlistedJobComponent,
    CandidatesAppliedJobComponent,
    EmployerManageSubemployersComponent,
    EmployerCreateSubemployersComponent,
    ManageUsersComponent,
    ManageJobsComponent,
    PasswordResetComponent,
    EmployersListComponent,
    EmployersDetailComponent,
    ExamFormComponent,
    FormatTimePipe,
    ManageExamsComponent,
    ExamDetailsComponent,
    EmployerManageExamsComponent,
    ExamVideoComponent,
    VideoExamFormComponent,
    EditJobComponent,
    ManageDeactivatedUsersComponent,
    UploadResumeComponent,
    ExamAlertComponent,
    CandidatesJobAlertComponent,
    AboutComponent,
    ContactComponent,
    PricingComponent,
    BlogListComponent,
    EmailVerificationComponent,
    ForgotPasswordComponent,
    Pricing1Component,
    TermsConditionsComponent,
    EmployeefeaturesComponent,
    EmployerfeaturesComponent,
    ResumeTemplateComponent,
    AdminExamDetailsComponent,
    UserSignupReportComponent,
    JobPostedReportComponent,
    JobDetailedReportComponent,
    ExamListComponent,
    AddBlogComponent,
    AdminBlogListComponent,
    BlogDetailsComponent,
    MonthlySignupReportComponent,
    EditBlogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FileUploadModule,
    QRCodeModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    JwPaginationModule,
    ConnectionServiceModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    EditorModule
  ],
  providers: [authInterceptorProviders, ExcelService],
  bootstrap: [AppComponent]
})
export class AppModule { }
