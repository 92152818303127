<!DOCTYPE html>
<html>


<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>clicktocareers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section>
            <div class="block  gray">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner2">
                                <div class="inner-title2">
                                    <h3>Contact</h3>
                                    <span>Keep up to date with the latest news</span>
                                </div>
                                <div class="page-breacrumbs">
                                    <!-- <ul class="breadcrumbs">
                                        <li><a href="#" title="">Home</a></li>
                                        <li><a href="#" title="">Pages</a></li>
                                        <li><a href="#" title="">Contact</a></li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 column">
                            <div class="contact-form">
                                <h3>Keep In Touch</h3>
                                <form (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <span class="pf-title">Full Name</span>
                                            <div class="pf-field">
                                                <input type="text" placeholder="Full Name" name="fullName" [(ngModel)]="form.fullName" />
                                                <div *ngIf="fullNameError" class="alert-danger">
                                                    Name is Mandatory.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <span class="pf-title">Email</span>
                                            <div class="pf-field">
                                                <input type="text" placeholder="Email" name="email" [(ngModel)]="form.email" />
                                                <div *ngIf="emailError" class="alert-danger">
                                                    Email is Mandatory.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <span class="pf-title">Subject</span>
                                            <div class="pf-field">
                                                <input type="text" placeholder="Subject" name="subject" [(ngModel)]="form.subject" />
                                                <div *ngIf="subjectError" class="alert-danger">
                                                    Subject is Mandatory.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <span class="pf-title">Message</span>
                                            <div class="pf-field">
                                                <textarea name="message" [(ngModel)]="form.message" placeholder="Message"></textarea>
                                                <div *ngIf="messageError" class="alert-danger">
                                                    Message is Mandatory.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <button type="submit">Send</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6 column">
                            <div class="contact-textinfo">
                                <h3>GET IN TOUCH</h3>
                                <ul>
                                    <li><i class="la la-map-marker"></i><span>No.45/3, 1st Floor, Gopal Krishna Complex, <br>Residency Rd, Ashok Nagar, Bengaluru, 560025<br>
                                        Karnataka<br>
                                        India
                                </span></li>
                                    <li><i class="la la-phone"></i><span>Call Us : +91-9620695529</span></li>
                                    <!-- <li><i class="la la-fax"></i><span>Fax : 0934 343 343</span></li> -->
                                    <li><i class="la la-envelope-o"></i><span>Email : <a href="mailto:info@clicktocareers.com" class="__cf_email__">info@clicktocareers.com</a></span></li>

                                </ul>
                                <!-- <a class="fill" href="#" title="">See on Map</a><a href="#" title="">Directions</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/contact.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:41 GMT -->

</html>