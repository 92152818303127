<!DOCTYPE html>
<html>


<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Exam</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

    <!-- Styles -->

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <div class="col-lg-12 column" *ngIf="!isLoggedIn" style="padding: 70px;">
                            Please login to attend the exam!!!
                        </div>
                        <div class="profile-form-edit" *ngIf="isLoggedIn && noInternetConnection" style="padding: 200px 0; text-align: center;">
                            <h1>THERE IS NO INTERNET CONNECTION</h1>
                            <img width="111px" src="assets/images/resource/nowifi.png" alt="No Internet Connection" />
                        </div>
                        <div class="col-lg-12 column" *ngIf="isLoggedIn && !noInternetConnection">
                            <div class="padding-left">
                                <div class="profile-form-edit" *ngIf="!isStartTest && !isEndTest">
                                    <!-- <span>Automated video interviewing platform ZORA</span> -->
                                    <h3 style="text-align: center; font-weight: bold;">Do's & Dont's in Written Interviews</h3>
                                    <div class="row" style="margin-bottom: 40px;">
                                        <div class="col-md-6"><img src="assets/images/resource/dos.png" alt="" style="display: block; margin: auto; width: 150px;">
                                            <span><b>DO'S</b></span><br>
                                            <span>• Do check comfortable seating position</span><br>
                                            <span>• Do a manual submit of responses to question by pressing “Submit” once you have
                                                completed your paper</span><br>
                                            <span>• Do check proper working of internet connection and power supply</span><br>
                                            <span>• Do keep track of the test timer</span><br>
                                            <span>• Do ready and understand the question before answering</span><br>
                                        </div>
                                        <!-- <div class="col-md-4"><img src="assets/images/resource/logo.png" alt="" style="display: block; margin: auto; width: 150px;"></div> -->
                                        <div class="col-md-6"><img src="assets/images/resource/donts.png" alt="" style="display: block; margin: auto; width: 150px;">
                                            <span><b>DONT'S</b></span><br>
                                            <span>• Do not have anybody else present in the test taking room apart from yourself</span><br>
                                            <span>• Do not share the internet bandwidth during course of the test</span><br>
                                            <span>• Do no talk to anybody while taking the test</span><br>
                                            <span>• Do no share your screen with anybody</span><br>
                                            <span>• Do not try to reach out to helpdesk to know your score and results</span><br></div>
                                    </div>
                                    <h3 style="text-align: center; font-weight: bold;">GOOD LUCK</h3>
                                    <span style="text-align: center; display: block;">Please click the Start button when you are ready to begin your interview</span>
                                    <!-- <div class="row">
                                        <div class="col-md-6">

                                        </div>
                                        <div class="col-md-6">

                                        </div>
                                    </div> -->
                                    <button type="button" (click)="startExam()" style="background: #910B08; margin-right: 54px;">Start Written Interview</button>
                                </div>
                                <!-- <div class="profile-title">
                                    <h3>Online Exam</h3>
                                    <span>{{counter | formatTime}}</span>
                                </div>
                                <div class="profile-form-edit" *ngIf="!isStartTest">
                                    <button type="button" (click)="startExam()">Start online test</button>
                                </div> -->
                                <div class="profile-form-edit" *ngIf="isStartTest">
                                    <span></span>
                                    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <span class="pf-title"></span>
                                                
                                                <div class="videxamrow col-lg-12">
                                                    <span class="videotimer">Time Left: {{counter | formatTime}}</span>
                                                    <div class="pf-title span-txt mt-auto">Question {{index+1}}) {{question}}</div>
                                                    <div class="pf-field">
                                                        <textarea name="answer" [(ngModel)]="answer" placeholder="Answer" style="width: 100%;"></textarea>
                                                    </div>
                                                    <div class=" span-txt col-lg-5 m-auto text-center">Question: {{index+1}}/{{questionLength}}</div>
                                                    <div class=" span-txt col-lg-5 m-auto text-center" *ngIf="!isLastQuestion" (click)="nextQuestion(true)" style="cursor: pointer;">
                                                        NEXT
                                                    </div>
                                                    <div class=" span-txt col-lg-5 m-auto text-center" *ngIf="isLastQuestion" (click)="onSubmit()" style="cursor: pointer;">
                                                        SUBMIT
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="profile-form-edit" *ngIf="isEndTest" style="padding: 200px 0;">
                                    <h3 style="text-align: center; font-weight: bold;">Thank you for using Click To Careers automated written interviewing platform</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

<!-- Mirrored from grandetest.com/theme/c2c-html/candidates_profile.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:14 GMT -->

</html>