<!DOCTYPE html>
<html>



<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Click to Careers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome {{user.firstName}} {{user.lastName}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="employer_profile" title=""><i class="la la-file-text"></i>Company Profile</a></li>
                                        <li><a href="employer_manage_jobs" title=""><i class="la la-briefcase"></i>Manage Jobs</a><span class="badge" *ngIf="isJobAlert"><i class='la la-bell'></i></span></li>
                                        <li><a href="candidates_list" title=""><i class="la la-flash"></i>Talent Search</a></li>
                                        <!-- <li><a href="employer_transactions" title=""><i class="la la-money"></i>Transactions</a></li> -->
                                        <!-- <li><a href="employer_resume.html" title=""><i class="la la-paper-plane"></i>Resumes</a></li> -->
                                        <!-- <li><a href="employer_packages.html" title=""><i class="la la-user"></i>Packages</a></li> -->
                                        <li><a href="employer_post_job" title=""><i class="la la-file-text"></i>Post a New Job</a></li>
                                        <li><a href="employer_password_reset" title=""><i class="la la-lock"></i>Change Password</a></li>
                                        <li><a href="employer_manage_users" title=""><i class="la la-users"></i>Manage Employers</a></li>
                                        <li><a href="employer_create_users" title=""><i class="la la-user"></i>Create Employers</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="profile-title" id="mp">
                                    <h3>My Profile</h3>
                                    <div class="profile-form-edit alert-warning" *ngIf="!user.isActive" style="padding: 30px; margin-top: 20px;">
                                        <h5> <img src="assets/images/resource/warning.png" alt=""> <b> Verify your account!! </b></h5>
                                        <span>Please confirm your email address by clicking on the link sent to <b>{{user.email}}</b></span><br>
                                        <span>1. Check your spam folder.</span><br>
                                        <span>2. <a (click)="resendActivationMail()" style="text-decoration: underline;"> Resend the confirmation email.</a></span>
                                    </div>
                                    <div class="upload-img-bar">
                                        <span><img  [src]="url" alt="" /></span>
                                        <div class="upload-info">
                                            <input accept="image/png, image/jpeg" style="display: none" type="file" ng2FileSelect [uploader]="uploader" #fileInput>
                                            <button class="btn btn-new btn-round" (click)="fileInput.click()"><i class="fa fa-upload"></i>Upload Image</button>
                                            <span>Max file size is 1MB, Minimum dimension: 270x210 And Suitable files are .jpg & .png</span>
                                        </div>

                                        <!-- <span><img  [src]="bannerUrl" alt="" /></span> -->
                                        <!-- <div class="upload-info">
                                            <input accept="image/png, image/jpeg" style="display: none" type="file" ng2FileSelect [uploader]="bannerUploader" #fileInputBanner>
                                            <button class="btn btn-new btn-round" (click)="fileInputBanner.click()"><i class="fa fa-upload"></i>Upload Banner Image</button>
                                            <span>Max file size is 1MB, Minimum dimension: 1920x1000 And Suitable files are .jpg & .png</span>
                                        </div> -->
                                    </div>
                                </div>

                                <div class="profile-form-edit">
                                    <form name="form" (ngSubmit)="onSubmit()" #f="ngForm">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <span class="pf-title">Company Name<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <input type="text" name="company" [(ngModel)]="company.name" placeholder="Company Name">
                                                    <div *ngIf="companyNameError" class="alert-danger">
                                                        Company name is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-6">
                                                <span class="pf-title">Allow In Search</span>
                                                <div class="pf-field">
                                                    <select class="chosen-dropdown" name="allowInSearch" [(ngModel)]="user.allowInSearch">
													    <option value="true">Yes</option>
													    <option value="false">No</option>
												    </select>
                                                </div>
                                            </div> -->
                                            <div class="col-lg-6">
                                                <span class="pf-title">Since</span>
                                                <div class="pf-field">
                                                    <input type="number" name="establishedYear" [(ngModel)]="company.establishedYear" placeholder="Established Year" required>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-6">
                                                <span class="pf-title">Team Size</span>
                                                <div class="pf-field">
                                                    <input type="number" name="size" [(ngModel)]="company.size" placeholder="Team Size">
                                                </div>
                                            </div> -->

                                            <div class="col-lg-12">
                                                <span class="pf-title">Industry<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="industry" [(ngModel)]="company.industry">
													<option>Accounting</option>
													<option>Administration and Office Support</option>
													<option>Advertisting,Arts and Media</option>
                                                    <option>Banking and Financial Services</option>
                                                    <option>Call Centre and Customer Service</option>
                                                    <option>CEO and General Mangement</option>
                                                    <option>Community Services and Development</option>
                                                    <option>Constructions</option>
                                                    <option>Consultating and Strategy</option>
                                                    <option>Design and Architecture</option>
                                                    <option>Eductation and Training</option>
                                                    <option>Engineering</option> 
                                                    <option>Farming Animals and Conservation</option>
                                                    <option>Government and Defence</option>
                                                    <option>Healthcare and Medical</option>
                                                    <option>Hospitallty and Toursim</option>
                                                    <option>Human Resources and Recruitment</option>
                                                    <option>Information and Communication Technology</option>
                                                    <option>Insurance and Superannuation</option>
                                                    <option>Legal</option>
                                                    <option>Manufacturing, Tranport and Logistics</option>
                                                    <option>Marketing and Communication</option>
                                                    <option>Mining, Resources and Energy</option>
                                                    <option>Real Estate and Property</option>
                                                    <option>Retail and Consumer Products</option>
                                                    <option>Sales</option>
                                                    <option>Science and Technology</option>
                                                    <option>Sports and Recreation</option>
                                                    <option>Trades and Services</option>
                                                </select>
                                                    <div *ngIf="industrytypeNameError" class="alert-danger">
                                                        Company industry is Mandatory.
                                                    </div>
                                                </div>
                                                <!-- <div class="pf-field no-margin">
                                                    <input type="text" name="categories" [(ngModel)]="categories" placeholder="Industry" />
                                                </div> -->
                                            </div>
                                            <div class="col-lg-12">
                                                <span class="pf-title">About the company<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <textarea name="about" [(ngModel)]="company.about" placeholder="About the company"></textarea>
                                                    <div *ngIf="descriptionError" class="alert-danger">
                                                        About the company is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="col-lg-6">
                                                <span class="pf-title">Facebook</span>
                                                <div class="pf-field">
                                                    <input type="text" name="facebookProfile" [(ngModel)]="company.facebookProfile" placeholder="www.facebook.com" />
                                                    <i class="fa fa-facebook"></i>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <span class="pf-title">Linkedin</span>
                                                <div class="pf-field">
                                                    <input type="text" name="linkedinProfile" [(ngModel)]="company.linkedinProfile" placeholder="www.Linkedin.com" />
                                                    <i class="fa fa-linkedin"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">

                                            <div class="col-lg-12">
                                                <span class="pf-title">Youtube Link</span>
                                                <div class="pf-field">
                                                    <input type="text" name="youtubeVideo" [(ngModel)]="company.youtubeVideo" placeholder="youtubeLink" />
                                                    <i class="fa fa-twitter"></i>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-lg-4">
                                                <span class="pf-title">Phone Number</span>
                                                <div class="pf-field">
                                                    <input type="number" name="phone" [(ngModel)]="user.phone" placeholder="Phone Number" />
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <span class="pf-title">Email<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <input type="text" name="email" value="{{user.email}}" placeholder="Email" readonly/>
                                                    <div *ngIf="emailError" class="alert-danger">
                                                        Email is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <span class="pf-title">Website</span>
                                                <div class="pf-field">
                                                    <input type="text" name="website" [(ngModel)]="company.website" placeholder="Website" />
                                                    <!-- <div *ngIf="websiteError" class="alert-danger">
                                                        Website is Mandatory.
                                                    </div> -->
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <span class="pf-title">Country</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Country" class="chosen-dropdown" name="countries" [(ngModel)]="company.country" (change)="getStates($event.target.value)">
                                                        <option *ngFor="let country of countries" value="{{country.name}}">{{country.name}}</option>
                                                    </select>


                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">State</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select State" class="chosen-dropdown" name="states" [(ngModel)]="company.state" (change)="getCities($event.target.value)">
                                                        <option *ngFor="let state of states" value="{{state.name}}">{{state.name}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <span class="pf-title">City</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select city" class="chosen-dropdown" name="cities" [(ngModel)]="company.city">
                                                        <option *ngFor="let city of cities" value="{{city.name}}">{{city.name}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <button type="submit">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_profile.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:52 GMT -->

</html>