import { Component, OnInit } from '@angular/core';
import jsPDF, * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { UserService } from 'src/app/_services/user.service';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';
import { GlobalConstants } from 'src/app/global-constants';

@Component({
  selector: 'app-resume-template',
  templateUrl: './resume-template.component.html',
  styleUrls: ['./resume-template.component.css']
})
export class ResumeTemplateComponent implements OnInit {
  user: User;
  url: string;

  constructor(private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit(): void {
    this.userService.getUserById(this.route.snapshot.params.id).subscribe(
      data => {
        this.user = data;
        this.url = GlobalConstants.apiURL + this.user.imageUrl;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );  
  }

  public convetToPDF() {
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      heightLeft -= pageHeight;  
      while (heightLeft >= 0) {  
        position = heightLeft - imgHeight;  
        pdf.addPage();  
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);  
        heightLeft -= pageHeight;  
      }  
      pdf.save('new-file.pdf'); // Generated PDF
    });
  }
}
