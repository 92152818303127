<!DOCTYPE html>
<html>


<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>clicktocareers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

    <!-- Styles -->

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome {{user.firstName}} {{user.lastName}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="candidates_profile" title=""><i class="la la-file-text"></i>My Profile</a></li>
                                        <li><a href="upload_resume" title=""><i class="la la-file-text"></i>Video Resume</a></li>
                                        <li><a href="candidates_job_alert" title=""><i class="la la-flash"></i>Job Alerts</a></li>
                                        <li><a href="candidates_shortlisted_jobs" title=""><i class="la la-money"></i>Shorlisted Job</a></li>
                                        <li><a href="candidates_applied_jobs" title=""><i class="la la-paper-plane"></i>Applied Job</a></li>
                                        <li><a href="exam_pending" title=""><i class="la la-user"></i>Exam Alerts</a><span class="badge" *ngIf="isExamAlert"><i class='la la-bell'></i></span></li>
                                        <li><a href="candidates_password_reset" title=""><i class="la la-flash"></i>Change Password</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="profile-title">
                                    <h3>My Video Resume</h3>
                                    <!-- <div class="upload-img-bar">
                                        <span class="round"><img  [src]="url" alt="" /></span>
                                        <div class="upload-info">
                                            <input accept="image/png, image/jpeg" style="display: none" type="file" ng2FileSelect [uploader]="uploader" #fileInput>
                                            <button class="btn btn-new btn-round" (click)="fileInput.click()"><i class="fa fa-upload"></i>Upload Image</button>
                                            <span>Max file size is 1MB, Minimum dimension: 270x210 And Suitable files are .jpg & .png</span>
                                        </div>

                                        <div class="upload-info">
                                            <input accept="application/pdf, application/msword" style="display: none" type="file" ng2FileSelect [uploader]="uploaderResume" #fileInputResume>
                                            <button class="btn btn-new btn-round" (click)="fileInputResume.click()"><i class="fa fa-upload"></i>Upload Resume</button>
                                            <span>Max file size is 1MB And Suitable files .pdf</span>
                                        </div>
                                    </div> -->
                                </div>
                                <button class="viresume" type="button" (click)="startVideo()">View Video Resume</button>
                                <div class="profile-form-edit col-12" *ngIf="!isStart">
                                    <!-- <span>Automated video interviewing platform ZORA</span> -->
                                    <h3 style="text-align: center; font-weight: bold;">Do's & Dont's in Video Resume</h3>
                                    <div class="row" style="margin-bottom: 40px;">
                                        <div class="col-md-6"><img src="assets/images/resource/dos.png" alt="" style="display: block; margin: auto; width: 150px;">
                                            <span><b>DO's</b></span><br>
                                            <span>• Do look at the camera</span><br>
                                            <span>• Do test out lighting and volume for good quality</span><br>
                                            <span>• Do check your internet connection</span><br>
                                            <span>• Do dress professionally</span><br>
                                            <span>• Do practice!</span><br>
                                        </div>
                                        <!-- <div class="col-md-4"><img src="assets/images/resource/logo.png" alt="" style="display: block; margin: auto; width: 150px;"></div> -->
                                        <div class="col-md-6"><img src="assets/images/resource/donts.png" alt="" style="display: block; margin: auto; width: 150px;">
                                            <span><b>DONT's</b></span><br>
                                            <span>• Don't sit in a distracting enviorment</span><br>
                                            <span>• Don't move around too much</span><br>
                                            <span>• Don't read off a screen</span><br>
                                            <span>• Don't be too casual</span><br>
                                            <span>• Don't speak too fast</span><br></div>
                                    </div>
                                    <img src="assets/images/resource/tips.jpg" alt="" style="display: block; margin: auto; width: 340px;">
                                    <!-- <h3 style="text-align: center; font-weight: bold;">GOOD LUCK</h3>
                                    <span style="text-align: center; display: block;">Please click the Start button when you are ready to begin your interview</span> -->
                                    <!-- <div class="row">
                                        <div class="col-md-6">

                                        </div>
                                        <div class="col-md-6">

                                        </div>
                                    </div> -->
                                    <button type="button" (click)="startVideo()" style=" margin-bottom: 10px; background-color: #910B08;">Begin</button>
                                </div>
                                <div class="row" id="startTest">
                                    <div class="col-xs-12">
                                        <!-- <div *ngIf="user.videoUrl">
                                            <video [src]="videoResumeurl" controls #videoPlayer>
                                            </video>
                                        </div> -->
                                        <video [src]="videoResumeurl" onerror="this.src='';" controls #videoPlayer class="video"></video>
                                    </div>
                                    <div class="row vidres">
                                        <div class="col-xs-12 mar recvis" style=" margin-bottom: 10px;">


                                            <button class="visible" style="background-color: #910B08;" md-raised-button color="primary" *ngIf="!user.showVideoResume" (click)="displayVideoResume(true)"> Employer Visibility : Yes </button>
                                            <button class="visible" style="background-color: #910B08;" md-raised-button color="primary" *ngIf="user.showVideoResume" (click)="displayVideoResume(false)"> Employer Visibility : No </button>
                                            <button class="stopvis" style="background-color: #910B08;" md-raised-button color="primary" (click)="stopRecording()"> Stop</button>
                                            <button style="background-color: #910B08;" md-raised-button color="primary" (click)="startRecording()"> Record/ Re-record </button>
                                            <!-- <button md-raised-button color="primary" (click)="startRecording()"> Re-record </button> -->

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

    <div class="profile-sidebar">
        <span class="close-profile"><i class="la la-close"></i></span>
        <div class="can-detail-s">
            <div class="cst"><img src="assets/images/resource/es1.jpg" alt="" /></div>
            <h3>David CARLOS</h3>
            <span><i>UX / UI Designer</i> at Atract Solutions</span>
            <p><a href="https://grandetest.com/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="e784958286938e91828b869e829594d7dfdfa7808a868e8bc984888a">[email&#160;protected]</a></p>
            <p>Member Since, 2017</p>
            <p><i class="la la-map-marker"></i>Istanbul / Turkey</p>
        </div>
        <div class="tree_widget-sec">
            <ul>
                <li><a href="candidates_profile.html" title=""><i class="la la-file-text"></i>My Profile</a></li>
                <li><a href="candidates_my_resume.html" title=""><i class="la la-briefcase"></i>My Resume</a></li>
                <li><a href="candidates_shortlist.html" title=""><i class="la la-money"></i>Shorlisted Job</a></li>
                <li><a href="candidates_applied_jobs.html" title=""><i class="la la-paper-plane"></i>Applied Job</a></li>
                <li><a href="candidates_job_alert.html" title=""><i class="la la-user"></i>Job Alerts</a></li>
                <li><a href="candidates_cv_cover_letter.html" title=""><i class="la la-file-text"></i>Cv & Cover Letter</a></li>
                <li><a href="candidates_change_password.html" title=""><i class="la la-flash"></i>Change Password</a></li>
                <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
            </ul>
        </div>
    </div>
    <!-- Profile Sidebar -->

</body>

<!-- Mirrored from grandetest.com/theme/c2c-html/candidates_profile.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:14 GMT -->

</html>