<section id="scroll-here" style="z-index: 0; padding-top: 150px;">
    <div class="block">
        <div data-velocity="-.1" style="background: url(assets/images/resource/parallax3.jpg) repeat scroll 50% 422.28px transparent; top: -65px;" class="parallax scrolly-invisible no-parallax"></div>
        <!-- PARALLAX BACKGROUND IMAGE -->
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="heading">
                        <h2>Click to Careers Employer Features</h2>
                        <span>.</span>
                    </div>
                    <!-- Heading -->
                    <div class="blog-sec">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="my-blog">
                                    <div class="blog-thumb" style="text-align: center;">
                                        <a href="#" title=""><i class="la la-play-circle-o" style="float: left; width: 100%; color :#910B08; font-size: 181px;"></i></a>
                                        <!-- <div class="blog-metas">
                                            <a href="#" title="">March 29, 2017</a>
                                            <a href="#" title="">0 Comments</a>
                                        </div> -->
                                    </div>
                                    <div class="blog-details">
                                        <h3>Automated Video Interviews
                                        </h3>
                                        <p>Conduct and watch video interviews where you add questions and candidates answer them as in a real interview. Assess right talent for credibility and soft-skills.
                                        </p>
                                        <a class="signup-popup" title="">Signup <i class="la la-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="my-blog">
                                    <div class="blog-thumb" style="text-align: center;">
                                        <a href="#" title=""><i class="la la-edit" style="float: left; width: 100%; color: #910B08;font-size: 181px;"></i></a>
                                        <!-- <div class="blog-metas">
                                            <a href="#" title="">March 29, 2017</a>
                                            <a href="#" title="">0 Comments</a>
                                        </div> -->
                                    </div>
                                    <div class="blog-details">
                                        <h3>Online Written Interviews</h3>
                                        <p>Ensure that you have the right candidate on board with the help of a well-planned written interview process, right after the video interviews have been conducted.
                                        </p>
                                        <a class="signup-popup" title="">Signup <i class="la la-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>