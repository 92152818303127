import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { Company } from 'src/app/models/company';
import { UserService } from 'src/app/_services/user.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { FileUploadModule, FileUploader } from 'ng2-file-upload';
import { GlobalConstants } from 'src/app/global-constants';
import { GeneralUtilService } from 'src/app/_services/general-util.service';
import { Country } from 'src/app/models/country';

const URL = GlobalConstants.apiURL + 'api/updateCompanyProfilePic';
const BANNER_URL = GlobalConstants.apiURL + 'api/updateCompanyBannerImage';

@Component({
  selector: 'app-employer-profile',
  templateUrl: './employer-profile.component.html',
  styleUrls: ['./employer-profile.component.css']
})
export class EmployerProfileComponent implements OnInit {
  user: User;
  imageChanged:boolean = false;
  url:string;
  bannerUrl:string;
  fileData;
  imageSrc: string;
  categories: string;
  company: Company;
  uploader: FileUploader = new FileUploader({url: URL, itemAlias: 'file', autoUpload: true, allowedMimeType: ['image/png', 'image/jpeg'] });
  bannerUploader: FileUploader = new FileUploader({url: BANNER_URL, itemAlias: 'file', autoUpload: true, allowedMimeType: ['image/png', 'image/jpeg'] });
  companyNameError: boolean = false;
  descriptionError: boolean = false;
  emailError: boolean = false;
  websiteError: boolean = false;
  countryError: boolean = false;
  cityError: boolean = false;
  industrytypeNameError: boolean = false;
  countries: Country[];
  states: any[];
  cityList: any[] = [];
  stateList: any[] = [];
  cities: any[] ;
  isJobAlert: boolean = false;
  
  constructor(private userService: UserService, private tokenStorageService: TokenStorageService, private utilService : GeneralUtilService) { }

  ngOnInit(): void {
    this.url = "assets/images/resource/employer.png";
    this.bannerUrl = "assets/images/resource/mpf1.jpg";
    this.company = new Company();
    const currentUser = this.tokenStorageService.getUser();
    this.isJobAlert = currentUser.jobAlert;
    this.userService.getByUsername(currentUser.email).subscribe(
      data => {
        this.user = data;
        if(this.user.company.imageUrl) {
          this.url = GlobalConstants.apiURL + this.user.company.imageUrl;
        }
        if(this.user.company.bannerUrl) {
          this.bannerUrl = GlobalConstants.apiURL + this.user.company.bannerUrl;
        }
        if(this.user.categories) {
          for (let index = 0; index < this.user.categories.length; index++) {
            const element = this.user.categories[index];
            this.categories = this.categories + element ;
            if (this.user.categories.length != index+1 ) {
              this.categories = this.categories  + ", ";
            }
          }
        }
        if(this.user.company.name) {
          this.company = this.user.company;
        }
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
    this.utilService.getAllCountries().subscribe(data =>{
      this.countries = data;
      if (this.company.state) {
        this.getStates(this.company.country);
      }
    });
    
    this.uploader.onAfterAddingFile = (file) => { 
      file.file.name = this.user.company._id+".jpg";
      file.withCredentials = false;

    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        this.url = GlobalConstants.apiURL + JSON.parse(response).path;
        this.userService.updateCompanyImageUrl(this.user.company._id).subscribe(
          data => {
            this.company = data;
            alert("Uploaded Successfully");
            window.location.reload();
          },
          err => {
           alert(JSON.parse(err.error).message);
          }
        );
    };

    this.bannerUploader.onAfterAddingFile = (file) => { 
      file.file.name = this.user.company._id+".jpg";
      file.withCredentials = false;

    };
    this.bannerUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        this.bannerUrl = GlobalConstants.apiURL + JSON.parse(response).path;
        this.userService.updateCompanyBannerUrl(this.user.company._id).subscribe(
          data => {
            this.company = data;
            alert("Uploaded Successfully");
            window.location.reload();
          },
          err => {
           alert(JSON.parse(err.error).message);
          }
        );
    };

    
  }

  onSubmit() {
    this.user.company = this.company;
    if (this.categories) {
      var categoryList = this.categories.split(',');
      this.user.categories = [];
      
      categoryList.forEach(element => {
        element = element.trim();
        if(element){
          this.user.categories.push(element);
        }
      });
        
    }

    this.invalidDescription();
    this.invalidCity();
    this.invalidCompany();
    this.invalidCountry();
    this.invalidEmail();
    // this.invalidWebsite();
    this.invalidindustrytype();
    if (!this.companyNameError && !this.descriptionError && !this.cityError && !this.emailError && !this.countryError && !this.industrytypeNameError) {
      this.userService.updateEmployerProfile(this.user).subscribe(
        data => {
          this.user = data;
          alert("Updated Successfully");
          window.location.reload();
        },
        err => {
         alert(JSON.parse(err.error).message);
        }
      );
    }
    else {
      alert("Please fill mandatory fields");
    }
  }

  getStates(countryName): any{
    this.states = [];
    this.stateList = [];
    this.utilService.getCitiesFromCountyName(countryName).subscribe(data=>{
    
      data[0].states.map((stateList) => {
        this.stateList.push(stateList)
      }
      );
      for(var i=0 ; i < this.stateList.length ; i++){
        this.states = this.states.concat(this.stateList[i])
      }
      this.cities = [];
      this.cityList = [];
      for(var i=0 ; i < this.stateList.length ; i++){
        if (this.stateList[i].name == this.company.state) {
          this.cityList.push(this.stateList[i].cities);
        }
      }
      for(var i=0 ; i < this.cityList.length ; i++){
        this.cities = this.cities.concat(this.cityList[i])
      }
    });
  }

    getCities(statename): any{
      this.cities = [];
      this.cityList = [];
      for(var i=0 ; i < this.stateList.length ; i++){
        if (this.stateList[i].name == statename) {
          this.cityList.push(this.stateList[i].cities);
        }
      }
      for(var i=0 ; i < this.cityList.length ; i++){
        this.cities = this.cities.concat(this.cityList[i])
      }
    }

  onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.fileData = file;
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result.toString();
      reader.readAsDataURL(file);
      this.uploadProfilePic();
    }
  }

  uploadProfilePic() {
    const uploadData = new FormData();
    uploadData.append('file', this.fileData);
    this.userService.updateUserProfilePic(uploadData).subscribe(
      data => {
        console.log("uploaded====");
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  resendActivationMail() {
    this.userService.resendActivationMail(this.user._id).subscribe(
      data => {
        alert("Check your mail")
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  invalidCompany() {
    if( this.user.company.name == "" || this.user.company.name == undefined){
      this.companyNameError =  true;
    } else {
      this.companyNameError = false;
    }
  }

  invalidDescription() {
    if( this.company.about == "" || this.company.about == undefined){
      this.descriptionError =  true;
    } else {
      this.descriptionError = false;
    }
  }

  invalidEmail() {
    if( this.user.email == "" || this.user.email == undefined){
      this.emailError =  true;
    } else {
      this.emailError = false;
    }
  }

  invalidWebsite() {
    if( this.user.company.website == "" || this.user.company.website == undefined){
      this.websiteError =  true;
    } else {
      this.websiteError = false;
    }
  }

  invalidCity() {
    if( this.company.city == "" || this.company.city == undefined){
      this.cityError =  true;
    } else {
      this.cityError = false;
    }
  }

  invalidCountry() {
    if( this.company.country == "" || this.company.country == undefined){
      this.countryError =  true;
    } else {
      this.countryError = false;
    }
  }

  invalidindustrytype() {
    if( this.company.industry == "" || this.company.industry == undefined){
      this.industrytypeNameError =  true;
    } else {
      this.industrytypeNameError = false;
    }
  }
}
