<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_post_new.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:47 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Job Hunt</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">


</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="admin_manage_users" title=""><i class="la la-users"></i>Manage Active Users</a></li>
                                        <li><a href="admin_manage_deactivated_users" title=""><i class="la la-users"></i>Manage Deactivated Users</a></li>
                                        <li><a href="admin_manage_jobs" title=""><i class="la la-briefcase"></i>Manage Jobs</a></li>
                                        <li><a href="exam_list" title=""><i class="la la-file-text"></i>View All Exams</a></li>
                                        <li><a href="add_blog" title=""><i class="la la-file-text"></i>Add Blog</a></li>
                                        <li><a href="admin_blog_list" title=""><i class="la la-file-text"></i>Blog List</a></li>
                                        <li><a href="monthly_user_signup_report" title=""><i class="la la-file"></i>Monthly Signup Report</a></li>
                                        <li><a href="user_signup_report" title=""><i class="la la-file"></i>Signup Report</a></li>
                                        <li><a href="job_post_report" title=""><i class="la la-file"></i>Job Posted Report</a></li>
                                        <li><a href="job_detailed_report" title=""><i class="la la-file"></i>Job Detailed Report</a></li>
                                        <li><a href="admin_password_reset" title=""><i class="la la-lock"></i>Change Password</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="profile-title">
                                    <h3>Post a New Job</h3>
                                </div>
                                <div class="profile-form-edit">
                                    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <span class="pf-title">Job Title</span>
                                                <div class="pf-field">
                                                    <input type="text" name="title" [(ngModel)]="job.title" placeholder="Job Title" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <span class="pf-title">Description</span>
                                                <div class="pf-field">
                                                    <textarea name="description" [(ngModel)]="job.description" placeholder="Job Description"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Job Type</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="type" [(ngModel)]="job.type">
													<option>Full Time</option>
													<option>Part Time</option>
													<option>Freelance</option>
													<option>Internship</option>
													<option>Contract</option>
												</select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Categories</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="categories" [(ngModel)]="job.categories">
													<option>Web Development</option>
													<option>Web Designing</option>
													<option>Art & Culture</option>
													<option>Reading & Writing</option>
												</select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Offerd Salary</span>
                                                <div class="pf-field">
                                                    <input type="number" name="offerdSalary" [(ngModel)]="job.offerdSalary" placeholder="Offerd Salary" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Career Level</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="careerLevel" [(ngModel)]="job.careerLevel">
													<option>Intermediate</option>
													<option>Normal</option>
													<option>Experienced</option>
												</select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Experience</span>
                                                <div class="pf-field">
                                                    <input type="number" name="experience" [(ngModel)]="job.experience" placeholder="Experience" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Industry</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="industry" [(ngModel)]="job.industry">
													<option>Web Development</option>
													<option>Web Designing</option>
													<option>Art & Culture</option>
													<option>Reading & Writing</option>
												</select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Qualification</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="qualification" [(ngModel)]="job.qualification">
													<option>Diploma</option>
													<option>Inter</option>
													<option>Bachelor</option>
													<option>Graduate</option>
												</select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Application Deadline Date</span>
                                                <div class="pf-field">
                                                    <input type="date" placeholder="Due date" name="dueDate" [(ngModel)]="job.dueDate" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <span class="pf-title">Skill Requirments</span>
                                                <div class="pf-field">
                                                    <input type="text" name="skills" [(ngModel)]="skills" placeholder="Skills" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Country</span>
                                                <div class="pf-field">
                                                    <input type="text" name="country" [(ngModel)]="job.country" placeholder="Country" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">City</span>
                                                <div class="pf-field">
                                                    <input type="text" name="city" [(ngModel)]="job.city" placeholder="City" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <span class="pf-title">Complete Address</span>
                                                <div class="pf-field">
                                                    <textarea name="address" [(ngModel)]="job.address" placeholder="Address"></textarea>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <span class="pf-title">Interview Type</span>
                                                <div class="pf-field">
                                                    <div class="select-user">
                                                        <span (click)="getInterviewType('No Test')">No Test</span>
                                                        <span (click)="getInterviewType('Online Test')">Online Test</span>
                                                        <span (click)="getInterviewType('Video Test')">Video Test</span>
                                                        <span (click)="getInterviewType('Video And Written Test')">Video And Written Test</span>
                                                    </div>
                                                    <!-- <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="examType" [(ngModel)]="job.examType">
                                                        <option>Online Test</option>
                                                        <option>Video Test</option>
                                                        <option>Video And Written Test</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <!-- <span class="pf-title">Time For Questions to answer</span>
                                                <div class="pf-field">
                                                    <input type="number" name="examTime" [(ngModel)]="job.examTime" placeholder="Time" />
                                                </div> -->
                                            </div>
                                            <div class="col-lg-12" *ngIf="job.examType && job.examType != 'No Test'">
                                                <div class="border-title">
                                                    <h3 *ngIf="job.examType == 'Video Test' || job.examType == 'Video And Written Test'">Video Exam Questions</h3>
                                                    <h3 *ngIf="job.examType == 'Online Test'">Written Test Questions</h3>
                                                    <a (click)="addQuestion()"><i class="la la-plus" ></i> Add Questions</a>
                                                </div>
                                                <input type="text" class="qust" name="question" [(ngModel)]="question.question" placeholder="Question" style="width: 75%;" />
                                                <input type="text" class="qust" name="questionTime" [(ngModel)]="question.time" placeholder="Question Time" style="width: 20%;" />
                                                <div class="edu-history-sec">
                                                    <div class="edu-history" *ngFor="let question of questions; let index = index;trackBy:trackByIndex;">
                                                        <i class="la la-graduation-cap"></i>
                                                        <div class="edu-hisinfo row">
                                                            <div class="col-lg-12">
                                                                <span class="pf-title">Question {{index+1}}</span>
                                                                <div class="pf-field">
                                                                    <input type="text" class="qust" name="question_{{index}}" [(ngModel)]="questions[index].question" placeholder="Question" style="width: 75%;" />
                                                                    <input type="text" class="qust" name="questionTime_{{index}}" [(ngModel)]="questions[index].time" placeholder="Question Time" style="width: 20%;" />

                                                                    <!-- <span class="pf-title" style="width: 75%;"><b>Question:</b> {{question.question}}</span>
                                                                    <span class="pf-title" style="width: 20%;"><b>Time:</b> {{question.time}} mins</span> -->
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <ul class="action_job">
                                                            <li><span>Delete</span><a (click)="deleteQuestion(index)" title=""><i class="la la-trash-o"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="job.examType == 'Video And Written Test'" style="border-top: 1px solid black;">
                                                <div class="border-title">
                                                    <h3>Written Test Questions</h3><a (click)="addQuestion2()"><i class="la la-plus" ></i> Add Questions</a>
                                                </div>
                                                <input type="text" class="qust" name="question2" [(ngModel)]="question2.question" placeholder="Question" style="width: 75%;" />
                                                <input type="text" class="qust" name="questionTime2" [(ngModel)]="question2.time" placeholder="Question Time" style="width: 20%;" />
                                                <div class="edu-history-sec">
                                                    <div class="edu-history" *ngFor="let question of questions2; let index = index;trackBy:trackByIndex;">
                                                        <i class="la la-graduation-cap"></i>
                                                        <div class="edu-hisinfo row">
                                                            <div class="col-lg-12">
                                                                <span class="pf-title">Question {{index+1}}</span>
                                                                <div class="pf-field">
                                                                    <input type="text" class="qust" name="question2_{{index}}" [(ngModel)]="questions2[index].question" placeholder="Question" style="width: 75%;" />
                                                                    <input type="text" class="qust" name="questionTime2_{{index}}" [(ngModel)]="questions2[index].time" placeholder="Question Time" style="width: 20%;" />
                                                                    <!-- <span class="pf-title" style="width: 75%;"><b>Question:</b> {{question.question}}</span>
                                                                    <span class="pf-title" style="width: 20%;"><b>Time:</b> {{question.time}} mins</span> -->
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <ul class="action_job">
                                                            <li><span>Delete</span><a (click)="deleteQuestion2(index)" title=""><i class="la la-trash-o"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <button type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_post_new.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:50 GMT -->

</html>