<!DOCTYPE html>
<html>



<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Click to careers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section>
            <div class="block gray">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner2">
                                <div class="inner-title2">
                                    <h3>Pricing</h3>
                                    <span>Keep up to date with the latest Offers</span>
                                </div>
                                <div class="page-breacrumbs">
                                    <!-- <ul class="breadcrumbs">
                                        <li><a href="#" title="">Home</a></li>
                                        <li><a href="#" title="">Pages</a></li>
                                        <li><a href="#" title="">Pricing</a></li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section>
            <div class="block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="heading">
                                <h2>Buy Our Employees Plans And Packeges</h2>
                                <span>One of our jobs has some kind of flexibility option - such as telecommuting, a part-time schedule or a flexible or flextime schedule.</span>
                            </div>
                            <!-- Heading -->
                            <div class="plans-sec">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="pricetable">
                                            <div class="pricetable-head">
                                                <h3>Basic</h3>
                                                <h2><i></i>Free</h2>
                                                <!-- <span>15 Days</span> -->
                                            </div>
                                            <!-- Price Table -->
                                            <ul>
                                                <li>Create a profile</li>
                                                <li>Upload your resume</li>
                                                <li>Apply for UNLIMITED jobs</li>
                                                <li>Create resume templates</li>
                                            </ul>
                                            <!-- <a href="#" title="">BUY NOW</a> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="pricetable active">
                                            <div class="pricetable-head">
                                                <h3>Standard(Recommended)</h3>
                                                <h2><i></i>Free</h2>
                                                <!-- <span>20 Days</span> -->
                                            </div>
                                            <!-- Price Table -->
                                            <ul>
                                                <li>Create a profile</li>
                                                <li>Upload your resume</li>
                                                <li>Apply for UNLIMITED jobs</li>
                                                <li>Create resume templates</li>
                                                <li>Create a 10 min video resume</li>
                                                <li>Store your video resume for 15 days</li>
                                                <li>Featured Candidates</li>
                                            </ul>
                                            <!-- <a href="#" title="">BUY NOW</a> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="pricetable">
                                            <div class="pricetable-head">
                                                <h3>Premium</h3>
                                                <h2><i></i>Free</h2>
                                                <!-- <span>2 Month</span> -->
                                            </div>
                                            <!-- Price Table -->
                                            <ul>
                                                <li>Create a profile</li>
                                                <li>Upload your resume</li>
                                                <li>Apply for UNLIMITED jobs</li>
                                                <li>Create resume templates</li>
                                                <li>Create a 10 min video resume</li>
                                                <li>Store your video resume for 30 days</li>
                                                <li>Featured Candidates</li>
                                            </ul>
                                            <!-- <a href="#" title="">BUY NOW</a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <table class="pkges-table" style="margin: auto; width: 90%; margin-top: 30px;">
                            <thead>
                                <tr>
                                    <td>Features</td>
                                    <td class="text-center">Basic </td>
                                    <td class="text-center">Standard(Recommended)</td>
                                    <td class="text-center">Premium</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Create profile</h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Upload Resume</h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Video Resume Availablity </h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span>0 days</span>
                                    </td>
                                    <td class="text-center">
                                        <span>15 days</span>
                                    </td>
                                    <td class="text-center">
                                        <span>30 days</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Upto 10 Minutes video Recording </h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Featured Candidate </h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Apply for unlimated jobs </h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>

</body>



</html>