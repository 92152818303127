import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-constants';
import { Exam } from 'src/app/models/exam';
import { User } from 'src/app/models/user';
import { ExamService } from 'src/app/_services/exam.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-exam-alert',
  templateUrl: './exam-alert.component.html',
  styleUrls: ['./exam-alert.component.css']
})
export class ExamAlertComponent implements OnInit {

  user: User;
  exams: Exam[];
  apiURL = GlobalConstants.apiURL;

  constructor(private userService: UserService, private tokenStorageService: TokenStorageService, private examService: ExamService) { }

  ngOnInit(): void {
    const currentUser = this.tokenStorageService.getUser();
    currentUser.examAlert = false;
    const USER_KEY = 'auth-user';
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(currentUser));

    this.examService.getExamsByUser(currentUser.id).subscribe(
      data => {
        this.exams = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );

    
    
  }
}
