export class Languages {
    public static languages = [
        "Assamese",
        "Bengali",
        "Dogri",
        "Gujarati",
        "Hindi",
        "Kashmiri",
        "Konkani",
        "Maithili",
        "Marathi",
        "Nepali",
        "Oriya",
        "Punjabi",
        "Sanskrit",
        "Sindhi",
        "Urdu",
        "Kannada",
        "Manipuri",
        "Bodo",
        "Santali",
        "Malayalam",
        "Tamil",
        "Telugu",
        "Bulgarian",
        "Dutch",
        "English",
        "Croatian",
        "Czech",
        "Danish",
        "Estonian",
        "Finnish",
        "French",
        "German",
        "Greek",
        "Hungarian",
        "Irish",
        "Italian",
        "Latvian",
        "Lithuanian",
        "Maltese",
        "Polish",
        "Portuguese",
        "Romanian",
        "Slovak",
        "Slovene",
        "Spanish",
        "Swedish",
        "Mandarin",
        "Cantonese",
        "Hunanese",
        "Thai",
        "Malay"
    ]
}