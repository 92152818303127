<!DOCTYPE html>
<html lang="en-US" style="margin-top: 130px;">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Resume Template</title>

    <noscript>
      <style type="text/css">
        [data-aos] {
            opacity: 1 !important;
            transform: translate(0) scale(1) !important;
        }
      </style>
    </noscript>
</head>

<body id="top">
    <header class="d-print-none">
        <div class="container text-center text-lg-left">
            <div class="py-3 clearfix">
                <h1 class="site-title mb-0">{{user.firstName}} {{user.lastName}}</h1>
                <div class="site-nav">
                    <nav role="navigation">
                        <ul class="nav justify-content-center">
                            <li class="nav-item">
                                <div class="d-print-none" data-aos="fade-left" data-aos-delay="200">
                                    <!-- <a class="btn btn-light text-dark shadow-sm mt-1 me-1" href="right-resume.pdf" target="_blank">Download CV</a> -->
                                    <a class="btn shadow-sm mt-1" (click)="convetToPDF()" style="color: white;background-color: #910b08;
                                    border-color: #910b08">Download CV</a></div>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <div class="page-content">
        <div class="container">
            <div class="cover shadow-lg bg-white" id="contentToConvert">
                <div class="cover-bg p-3 p-lg-4 text-white" style="padding: 3em !important;">
                    <div class="row">
                        <div class="col-lg-4 col-md-5">
                            <div class="avatar hover-effect bg-white shadow-sm p-1"><img [src]="url" onerror="this.src='assets/images/resource/employee.png';" width="200" height="200" /></div>
                        </div>
                        <div class="col-lg-8 col-md-7 text-md-start">
                            <h2 class="h1 mt-2" data-aos="fade-left" data-aos-delay="0">{{user.firstName}} {{user.lastName}}</h2>
                            <p data-aos="fade-left" data-aos-delay="100" style="color: white;">{{user.jobTitle}}</p>
                            <!-- <div class="d-print-none" data-aos="fade-left" data-aos-delay="200"><a class="btn btn-light text-dark shadow-sm mt-1 me-1" href="right-resume.pdf" target="_blank">Download CV</a><a class="btn btn-success shadow-sm mt-1" href="#contact">Hire Me</a></div> -->
                        </div>
                    </div>
                </div>
                <div class="about-section pt-4 px-3 px-lg-4 mt-1" style="padding: 3em !important;">
                    <div class="row">
                        <div class="col-md-6">
                            <h2 class="h3 mb-3">About Me</h2>
                            <p>{{user.description}}</p>
                        </div>
                        <div class="col-md-5 offset-md-1">
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <div class="pb-1">Age</div>
                                </div>
                                <div class="col-sm-8">
                                    <div class="pb-1 text-secondary">{{user.age}}</div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="pb-1">Email</div>
                                </div>
                                <div class="col-sm-8">
                                    <div class="pb-1 text-secondary">{{user.email}}</div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="pb-1">Phone</div>
                                </div>
                                <div class="col-sm-8">
                                    <div class="pb-1 text-secondary">{{user.phone}}</div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="pb-1">Address</div>
                                </div>
                                <div class="col-sm-8">
                                    <div class="pb-1 text-secondary">{{user.city}}, {{user.state}}, {{user.country}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="d-print-none" />
                <div class="skills-section px-3 px-lg-4" style="padding: 3em !important;">
                    <h2 class="h3 mb-3">Professional Skills</h2>
                    <div class="row">
                        <div class="col-md-6" *ngFor="let skill of user.skills; let i = index">
                            <div class="mb-2"><span>{{skill}}</span>

                            </div>

                        </div>
                    </div>
                </div>
                <hr class="d-print-none" />
                <div class="work-experience-section px-3 px-lg-4" style="padding: 3em !important;" *ngIf="user.workList.length != 0">
                    <h2 class="h3 mb-4">Work Experience</h2>
                    <div class="timeline">
                        <div class="timeline-card timeline-card-primary card shadow-sm" *ngFor="let work of user.workList; let i = index">
                            <div class="card-body">
                                <div class="h5 mb-1">{{work.designation}} <span class="text-muted h6">at {{work.company}}</span></div>
                                <div class="text-muted text-small mb-2">{{work.startMonth}}, {{work.startYear}} - {{work.endMonth}}, {{work.endYear}}</div>
                                <div>{{work.experience}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="d-print-none" />
                <div class="page-break"></div>
                <div class="education-section px-3 px-lg-4 pb-4" style="padding: 3em !important;">
                    <h2 class="h3 mb-4">Education</h2>
                    <div class="timeline">
                        <div class="timeline-card timeline-card-success card shadow-sm" *ngFor="let education of user.educationList; let i = index">
                            <div class="card-body">
                                <div class="h5 mb-1">{{education.educationLevel}} <span class="text-muted h6">from {{education.institute}}</span></div>
                                <div class="text-muted text-small mb-2">{{education.startYear}} - {{education.endYear}}</div>
                                <div>{{education.highlight}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="d-print-none" />
                <div class="work-experience-section px-3 px-lg-4" style="padding: 3em !important;" *ngIf="user.skillList.length != 0">
                    <h2 class="h3 mb-4">Licences & Certifications</h2>
                    <div class="timeline">
                        <div class="timeline-card timeline-card-primary card shadow-sm" *ngFor="let skill of user.skillList; let i = index">
                            <div class="card-body">
                                <div class="h5 mb-1">{{skill.title}} <span class="text-muted h6" *ngIf="skill.organisation">from {{skill.organisation}}</span></div>
                                <div class="text-muted text-small mb-2">{{skill.startMonth}}, {{skill.startYear}} - {{skill.endMonth}}, {{skill.endYear}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</body>

</html>