import { Component, Injectable, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import { Subscription, timer } from 'rxjs';
import { Exam } from 'src/app/models/exam';
import { ExamService } from 'src/app/_services/exam.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-exam-form',
  templateUrl: './exam-form.component.html',
  styleUrls: ['./exam-form.component.css']
})
export class ExamFormComponent implements OnInit {

  exam: Exam;
  question: string;
  answer: string;
  isLastQuestion: boolean = false;
  index: number = 0;
  timer;
  countDown: Subscription;
  counter = 60;
  tick = 1000;
  limit : number;
  isStartTest: boolean = false;
  isEndTest: boolean = false;
  isLoggedIn: boolean = false;
  currentUser: any;
  questionLength: number = 0;
  isConnected = true;  
  noInternetConnection: boolean;  

  constructor(private examService: ExamService, private route: ActivatedRoute, private tokenStorageService: TokenStorageService, private connectionService: ConnectionService) {
    this.connectionService.monitor().subscribe(isConnected => {  
      this.isConnected = isConnected;  
      if (this.isConnected) {  
        this.noInternetConnection=false;  
      }  
      else {  
        this.noInternetConnection=true;  
        // this.isLoggedIn = false;
        clearTimeout(this.timer);
      }  
    })  
  }

  ngOnInit(): void {
    this.currentUser = this.tokenStorageService.getUser();
    if (this.currentUser) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
    this.examService.getExamById(this.route.snapshot.params.id).subscribe(
      data => {
        this.exam = data;
        this.questionLength = this.exam.questions.length;
        // this.exam.answers = [];
        if (this.exam.answers) {
          this.index = this.exam.answers.length;
        }
        if(this.index+1 >= this.exam.questions.length) {
          this.isLastQuestion = true;
        }
        this.answer = "";
        this.question = this.exam.questions[this.index].question;
        // this.exam.timeDuration = 1;
        this.counter = 60 * this.exam.questions[this.index].time;
        this.limit = this.counter * 1000;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
    
  }

  startExam() {
    this.isStartTest = true;
    if(this.index+1 == this.exam.questions.length) {
      this.isLastQuestion = true;
    }
    this.timer = setTimeout (() => {
      if (!this.isLastQuestion) {
        this.nextQuestion();
      } else {
        this.onSubmit();
      }
    }, this.limit);
    this.countDown = this.getCounter(this.tick)
      .subscribe(() => this.counter--);
  }


  getCounter(tick) {
    return timer(0, tick);
  }

  nextQuestion(clicked?) {
    alert("Next question");
    this.index++;
    this.counter = 60 *  this.exam.questions[this.index].time;
    if (clicked) {
      clearTimeout(this.timer);
    }
    this.exam.answers.push(this.answer);
    this.question = this.exam.questions[this.index].question;
    this.answer = "";
    if(this.index+1 == this.exam.questions.length) {
      this.isLastQuestion = true;
    }
    this.examService.saveExam(this.exam).subscribe(
      data => {
        console.log("success");
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
    // this.limit = this.counter * 1000;
    // clearTimeout(setTimeout);
    this.timer =setTimeout (() => {
      if(this.index+1 == this.exam.questions.length) {
        this.onSubmit();
      } 
      else {
        this.nextQuestion();
      }
   }, this.limit);
  }

  onSubmit() {
    this.isEndTest = true;
    this.isStartTest = false;
    this.exam.answers.push(this.answer);
    this.examService.saveExam(this.exam).subscribe(
      data => {
        console.log("success");
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    //MM:SS format
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );

    // for HH:MM:SS
    //const hours: number = Math.floor(value / 3600);
    //const minutes: number = Math.floor((value % 3600) / 60);
    //return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}