<section id="scroll-here" style="z-index: 0; padding-top: 150px;">
    <div class="block">
        <div data-velocity="-.1" style="background: url(assets/images/resource/parallax3.jpg) repeat scroll 50% 422.28px transparent; top: -65px;" class="parallax scrolly-invisible no-parallax"></div>
        <!-- PARALLAX BACKGROUND IMAGE -->
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="heading">
                        <h2>Click to Careers Employee Features</h2>
                        <span>.</span>
                    </div>
                    <!-- Heading -->
                    <div class="blog-sec">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="my-blog">
                                    <div class="blog-thumb" style="text-align: center;">
                                        <a href="#" title=""><i class="la la-laptop" style="float: left; width: 100%; color: #910B08;font-size: 181px;"></i></a>
                                    </div>
                                    <div class="blog-details">
                                        <h3>Video Resume</h3>
                                        <p>Impress employers, not just with words but your personality, speech, and skills in action… add a video resume to your profile and be future-ready.</p>
                                        <a class="signup-popup" title="">Signup <i class="la la-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>