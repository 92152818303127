<!DOCTYPE html>
<html>


<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>C2C</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">
</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section>
            <div class="block  gray">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner2">
                                <div class="inner-title2">
                                    <h3>About Us</h3>
                                    <!-- <span>Keep up to date with the latest news</span> -->
                                </div>
                                <!-- <div class="page-breacrumbs">
								<ul class="breadcrumbs">
									<li><a href="#" title="">Home</a></li>
									<li><a href="#" title="">Pages</a></li>
									<li><a href="#" title="">About Us</a></li>
								</ul>
							</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="about-us">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h3>About Click To Careers</h3>
                                    </div>
                                    <div class="col-lg-7">
                                        <p>Click to Careers is a one-stop destination and a facilitator for job seekers and employers. With a wide- ranging portfolio that aims at offering employment opportunities across different industry domains, we make
                                            finding and offering jobs easy for both employers and prospective employees.</p>
                                        <p>We are a multi-purpose platform that bring recruiters and candidates close to each other by offering a common base where they can connect and meet their requirements. We work not just as a job searching portal but
                                            as a complete industry solution with advanced and upgraded technologies that reduce search time and optimize results.</p>
                                        <p>Keeping in tune with advanced work trends, we have evolved our system by offering video resumes and video interviews connecting deserving candidates to potential clients for easy selection online. This novel format
                                            not only helps in reducing time and resources spent on interview arrangements but also facilitates applicants to evaluate their fit into the prospective business culture. We offer job-seekers an edge over conventional
                                            application procedures, keep them ahead in this fast-paced competitive world and provide them with an added advantage to prove their talents.</p>
                                    </div>
                                    <div class="col-lg-5">
                                        <img src="assets/images/resource/logo.png" alt="" />
                                    </div>
                                    <div class="col-lg-12">

                                    </div>
                                </div>
                                <!-- <div class="tags-share">
						 		<div class="share-bar">
					 				<a href="#" title="" class="share-fb"><i class="fa fa-facebook"></i></a><a href="#" title="" class="share-twitter"><i class="fa fa-twitter"></i></a><a href="#" title="" class="share-google"><i class="la la-google"></i></a><span>Share</span>
					 			</div>
				 			</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/about.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:40 GMT -->

</html>