import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'src/app/global-constants';
import { Company } from 'src/app/models/company';
import { Exam } from 'src/app/models/exam';
import { Job } from 'src/app/models/job';
import { User } from 'src/app/models/user';
import { ExamService } from 'src/app/_services/exam.service';
import { JobService } from 'src/app/_services/job.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-employer-manage-applications',
  templateUrl: './employer-manage-applications.component.html',
  styleUrls: ['./employer-manage-applications.component.css']
})
export class EmployerManageApplicationsComponent implements OnInit {

  job: Job;
  company: Company;
  displayedUsers: User[];
  exams: Exam[];
  displayExams: Exam[];
  // appliedUsers: User[];
  // appliedUsers: User[];
  isApplied: boolean = true;
  isShortlisted: boolean = false;
  currentUser;
  isApplication: boolean = false;
  isExam: boolean = false;
  apiURL = GlobalConstants.apiURL;

  constructor(private route: ActivatedRoute, private jobService: JobService, private examService: ExamService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.jobService.getJobDetails(this.route.snapshot.params.id).subscribe(
      data => {
        this.job = data;
        this.displayedUsers = this.job.appliedUsers;
        this.company = data.postedBy.company;

      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
    this.examService.getExamsByJobForEmployer(this.route.snapshot.params.id).subscribe(
      data => {
        this.exams = data;
        this.allExam();
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
    this.isApplication = true;
    this.isExam = false;
  }

  viewApplications(){
    this.isApplication = true;
    this.isExam = false;
  }

  viewExams(){
    this.isApplication = false;
    this.isExam = true;
  }

  shortlist(userId) {
    this.jobService.shortlistUser(this.job._id, userId).subscribe(
      data => {
        alert("Application Shotlisted Successfully");
        this.job = data;
        this.displayedUsers = this.job.appliedUsers;
        this.company = data.postedBy.company;
        window.location.reload();
      },
      err => {
        alert("Already added");
       console.log(JSON.parse(err.error).message);
      }
    );
  }

  reject(userId) {
    this.jobService.rejectUser(this.job._id, userId).subscribe(
      data => {
        alert("Application Rejected Successfully");
        this.job = data;
        this.company = data.postedBy.company;
        window.location.reload();
      },
      err => {
        alert("Already added");
       console.log(JSON.parse(err.error).message);
      }
    );
  }

  allApplication() {
    this.isApplied = true;
    this.isShortlisted = false;
    this.displayedUsers = this.job.appliedUsers;
  }

  shortlistedApplication() {
    this.isApplied = false;
    this.isShortlisted = true;
    this.displayedUsers = this.job.shortlistedUsers;
  }

  rejectedApplication() {
    this.isApplied = false;
    this.isShortlisted = true;
    this.displayedUsers = this.job.rejectedUsers;
  }

  selectExamCandidate(examId) {
    this.examService.updateExamStatus(examId, "Selected").subscribe(
      data => {
        this.ngOnInit();
        this.isApplication = false;
        this.isExam = true;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  rejectExamCandidate(examId) {
    this.examService.updateExamStatus(examId, "Rejected").subscribe(
      data => {
        this.ngOnInit();
        this.isApplication = false;
        this.isExam = true;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  selectedCandidates() {
    this.displayExams = [];
    this.exams.forEach(element => {
      if (element.status == "Selected") {
        this.displayExams.push(element);
      }
    });
  }

  allWrittenInteview() {
    this.displayExams = [];
    this.exams.forEach(element => {
      if (element.examType == 'Online Test' && element.status != "Selected") {
        this.displayExams.push(element);
      }
    });
  }

  allVideoInteview() {
    this.displayExams = [];
    this.exams.forEach(element => {
      if (element.examType == 'Video Test' && element.status != "Selected") {
        this.displayExams.push(element);
      }
    });
  }

  allExam() {
    this.displayExams = [];
    this.exams.forEach(element => {
      if (element.status != "Selected") {
        this.displayExams.push(element);
      }
    });
  }

  addFavourite(examId) {
    this.examService.updateExamCandidateFavouriteStatus(examId, "true").subscribe(
      data => {
        this.ngOnInit();
        this.isApplication = false;
        this.isExam = true;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }


  removeFavourite(examId) {
    this.examService.updateExamCandidateFavouriteStatus(examId, "false").subscribe(
      data => {
        this.ngOnInit();
        this.isApplication = false;
        this.isExam = true;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

}
