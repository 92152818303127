import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { BoardModeratorComponent } from './board-moderator/board-moderator.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { CandidatesProfileComponent } from './candidates/candidates-profile/candidates-profile.component';
import { CandidatesPasswordResetComponent } from './candidates/candidates-password-reset/candidates-password-reset.component';
import { EmployerProfileComponent } from './employer/employer-profile/employer-profile.component';
import { EmployerPasswordResetComponent } from './employer/employer-password-reset/employer-password-reset.component';
import { EmployerPostJobComponent } from './employer/employer-post-job/employer-post-job.component';
import { JobListComponent } from './job-list/job-list.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { EmployerManageJobsComponent } from './employer/employer-manage-jobs/employer-manage-jobs.component';
import { EmployerManageApplicationsComponent } from './employer/employer-manage-applications/employer-manage-applications.component';
import { CandidatesSortlistedJobComponent } from './candidates/candidates-sortlisted-job/candidates-sortlisted-job.component';
import { CandidatesAppliedJobComponent } from './candidates/candidates-applied-job/candidates-applied-job.component';
import { EmployerManageSubemployersComponent } from './employer/employer-manage-subemployers/employer-manage-subemployers.component';
import { EmployerCreateSubemployersComponent } from './employer/employer-create-subemployers/employer-create-subemployers.component';
import { ManageUsersComponent } from './admin/manage-users/manage-users.component';
import { ManageJobsComponent } from './admin/manage-jobs/manage-jobs.component';
import { PasswordResetComponent } from './admin/password-reset/password-reset.component';
import { CandidatesListComponent } from './candidates/candidates-list/candidates-list.component';
import { CandidatesDetailComponent } from './candidates/candidates-detail/candidates-detail.component';
import { EmployersListComponent } from './employer/employers-list/employers-list.component';
import { EmployersDetailComponent } from './employer/employers-detail/employers-detail.component';
import { ExamFormComponent } from './candidates/exam-form/exam-form.component';
import { ManageExamsComponent } from './admin/manage-exams/manage-exams.component';
import { ExamDetailsComponent } from './exam-details/exam-details.component';
import { EmployerManageExamsComponent } from './employer/employer-manage-exams/employer-manage-exams.component';
import { ExamVideoComponent } from './exam-video/exam-video.component';
import { VideoExamFormComponent } from './candidates/video-exam-form/video-exam-form.component';
import { EditJobComponent } from './admin/edit-job/edit-job.component';
import { ManageDeactivatedUsersComponent } from './admin/manage-deactivated-users/manage-deactivated-users.component';
import { UploadResumeComponent } from './candidates/upload-resume/upload-resume.component';
import { ExamAlertComponent } from './candidates/exam-alert/exam-alert.component';
import { CandidatesJobAlertComponent } from './candidates/candidates-job-alert/candidates-job-alert.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PricingComponent } from './pricing/pricing.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { Pricing1Component } from './pricing1/pricing1.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { EmployeefeaturesComponent } from './employeefeatures/employeefeatures.component';
import { EmployerfeaturesComponent } from './employerfeatures/employerfeatures.component';
import { ResumeTemplateComponent } from './candidates/resume-template/resume-template.component';
import { AdminExamDetailsComponent } from './admin-exam-details/admin-exam-details.component';
import { LoginActivateGuard } from './login-activate.guard';
import { UserSignupReportComponent } from './admin/user-signup-report/user-signup-report.component';
import { JobPostedReportComponent } from './admin/job-posted-report/job-posted-report.component';
import { JobDetailedReportComponent } from './admin/job-detailed-report/job-detailed-report.component';
import { ExamListComponent } from './admin/exam-list/exam-list.component';
import { AddBlogComponent } from './admin/add-blog/add-blog.component';
import { AdminBlogListComponent } from './admin/admin-blog-list/admin-blog-list.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { MonthlySignupReportComponent } from './admin/monthly-signup-report/monthly-signup-report.component';
import { EditBlogComponent } from './admin/edit-blog/edit-blog.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'user', component: BoardUserComponent },
  { path: 'mod', component: BoardModeratorComponent },
  { path: 'admin', component: BoardAdminComponent },
  { path: 'candidates_profile', component: CandidatesProfileComponent, canActivate: [LoginActivateGuard] },
  { path: 'candidates_password_reset', component: CandidatesPasswordResetComponent, canActivate: [LoginActivateGuard] },
  { path: 'employer_password_reset', component: EmployerPasswordResetComponent, canActivate: [LoginActivateGuard] },
  { path: 'employer_profile', component: EmployerProfileComponent, canActivate: [LoginActivateGuard] },
  // { path: 'employer_password_reset', component: EmployerPasswordResetComponent },
  { path: 'employer_post_job', component: EmployerPostJobComponent, canActivate: [LoginActivateGuard] },
  { path: 'job_list', component: JobListComponent },
  { path: 'job_list/:title/:city', component: JobListComponent },
  { path: 'job_list/:industry', component: JobListComponent },
  { path: 'job_details/:id', component: JobDetailsComponent },
  { path: 'employer_manage_jobs', component: EmployerManageJobsComponent, canActivate: [LoginActivateGuard] },
  { path: 'employer_manage_users', component: EmployerManageSubemployersComponent, canActivate: [LoginActivateGuard] },
  { path: 'employer_create_users', component: EmployerCreateSubemployersComponent, canActivate: [LoginActivateGuard] },
  { path: 'employer_manage_applications/:id', component: EmployerManageApplicationsComponent, canActivate: [LoginActivateGuard] },
  { path: 'candidates_shortlisted_jobs', component: CandidatesSortlistedJobComponent, canActivate: [LoginActivateGuard] },
  { path: 'candidates_applied_jobs', component: CandidatesAppliedJobComponent, canActivate: [LoginActivateGuard] },
  { path: 'admin_manage_users', component: ManageUsersComponent, canActivate: [LoginActivateGuard] },
  { path: 'admin_manage_jobs', component: ManageJobsComponent, canActivate: [LoginActivateGuard] },
  { path: 'admin_password_reset', component: PasswordResetComponent, canActivate: [LoginActivateGuard] },
  { path: 'candidates_list', component: CandidatesListComponent },
  { path: 'candidates_details/:id', component: CandidatesDetailComponent },
  { path: 'employers_list', component: EmployersListComponent },
  { path: 'employer_details/:id', component: EmployersDetailComponent },
  { path: 'exam/:id', component: ExamFormComponent },
  { path: 'manage_exam/:id', component: ManageExamsComponent, canActivate: [LoginActivateGuard] },
  { path: 'exam_details/:id', component: ExamDetailsComponent, canActivate: [LoginActivateGuard] },
  { path: 'employer_manage_exam/:id', component: EmployerManageExamsComponent, canActivate: [LoginActivateGuard] },
  { path: 'exam_video', component: ExamVideoComponent },
  { path: 'online_video_test/:id', component: VideoExamFormComponent, canActivate: [LoginActivateGuard] },
  { path: 'edit_job/:id', component: EditJobComponent, canActivate: [LoginActivateGuard] },
  { path: 'admin_manage_deactivated_users', component: ManageDeactivatedUsersComponent, canActivate: [LoginActivateGuard] },
  { path: 'upload_resume', component: UploadResumeComponent, canActivate: [LoginActivateGuard] },
  { path: 'exam_pending', component: ExamAlertComponent, canActivate: [LoginActivateGuard] },
  { path: 'candidates_job_alert', component: CandidatesJobAlertComponent, canActivate: [LoginActivateGuard] },
  { path: 'about', component: AboutComponent },
  { path: 'terms', component: TermsConditionsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'pricing1', component: Pricing1Component },
  { path: 'employerfeatures', component: EmployerfeaturesComponent }, 
  { path: 'employeefeatures', component: EmployeefeaturesComponent },
  { path: 'blog_list', component: BlogListComponent },
  { path: 'email_verification/:id', component: EmailVerificationComponent },
  { path: 'reset_password', component: ForgotPasswordComponent },
  { path: 'resume_template/:id', component: ResumeTemplateComponent, canActivate: [LoginActivateGuard] },
  { path: 'admin_exam_details/:id', component: AdminExamDetailsComponent, canActivate: [LoginActivateGuard] },
  { path: 'user_signup_report', component: UserSignupReportComponent, canActivate: [LoginActivateGuard] },
  { path: 'monthly_user_signup_report', component: MonthlySignupReportComponent, canActivate: [LoginActivateGuard] },
  { path: 'job_post_report', component: JobPostedReportComponent, canActivate: [LoginActivateGuard] },
  { path: 'job_detailed_report', component: JobDetailedReportComponent, canActivate: [LoginActivateGuard] },
  { path: 'exam_list', component: ExamListComponent, canActivate: [LoginActivateGuard], data: { roles: ["ROLE_ADMIN"]} },
  { path: 'add_blog', component: AddBlogComponent, canActivate: [LoginActivateGuard] },
  { path: 'edit_blog/:id', component: EditBlogComponent, canActivate: [LoginActivateGuard] },
  { path: 'admin_blog_list', component: AdminBlogListComponent, canActivate: [LoginActivateGuard] },
  { path: 'blog_details/:id', component: BlogDetailsComponent },
  { path: '**', redirectTo: '' }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
