<!DOCTYPE html>
<html>



<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Click to careers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

    <!-- Styles -->

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome {{user.firstName}} {{user.lastName}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="candidates_profile" title=""><i class="la la-file-text"></i>My Profile</a></li>
                                        <li><a href="upload_resume" title=""><i class="la la-file-text"></i>Video Resume</a></li>
                                        <li><a href="candidates_job_alert" title=""><i class="la la-flash"></i>Job Alerts</a></li>
                                        <li><a href="candidates_shortlisted_jobs" title=""><i class="la la-money"></i>Shorlisted Job</a></li>
                                        <li><a href="candidates_applied_jobs" title=""><i class="la la-paper-plane"></i>Applied Job</a></li>
                                        <li><a href="exam_pending" title=""><i class="la la-user"></i>Exam Alerts</a><span class="badge" *ngIf="isExamAlert"><i class='la la-bell'></i></span></li>
                                        <li><a href="candidates_password_reset" title=""><i class="la la-flash"></i>Change Password</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="profile-title">
                                    <h3>My Profile</h3>
                                    <div class="upload-img-bar">
                                        <div class="upload-info">
                                            <span class="round"><img  src="assets/images/resource/resume.png" alt="" style="width: 180px;" /></span>
                                            <input accept=".pdf,.doc,.docx" style="display: none" type="file" ng2FileSelect [uploader]="uploaderResume" #fileInputResume>
                                            <button class="btn btn-new btn-round" (click)="fileInputResume.click()"><i class="fa fa-upload"></i>Upload Resume</button>
                                            <span><a href="{{resumeUrl}}" target="_blank" download *ngIf="resumeUrl">View resume</a></span>
                                        </div>

                                        <div class="upload-info">
                                            <span class="round"><img  src="assets/images/resource/videoresume.png" alt="" style="width: 180px;" /></span>
                                            <!-- <input accept="application/pdf, application/msword" style="display: none" type="file" ng2FileSelect [uploader]="uploaderResume" #fileInputResume> -->
                                            <button class="btn btn-new btn-round" (click)="videoResume()"><i class="fa fa-upload"></i>Create Video Resume</button>
                                            <span></span>
                                            <!-- <span>Max file size is 1MB And Suitable files .pdf</span> -->
                                        </div>

                                        <div class="upload-info">
                                            <span class="round"><img  [src]="url" alt="" style="width: 180px;" /></span>
                                            <input accept="image/png, image/jpeg" style="display: none" type="file" ng2FileSelect [uploader]="uploader" #fileInput>
                                            <button class="btn btn-new btn-round" (click)="fileInput.click()"><i class="fa fa-upload"></i>Upload Image</button>
                                            <span></span>
                                            <!-- <span>Max file size is 1MB, Minimum dimension: 270x210 And Suitable files are .jpg & .png</span> -->
                                        </div>


                                    </div>
                                </div>
                                <div class="profile-form-edit alert-warning" *ngIf="!user.isActive" style="padding: 30px;">
                                    <h5> <img src="assets/images/resource/warning.png" alt=""> <b> Verify your account!! </b></h5>
                                    <span>Please confirm your email address by clicking on the link sent to <b>{{user.email}}</b></span><br>
                                    <span>1. Check your spam folder.</span><br>
                                    <span>2. <a (click)="resendActivationMail()" style="text-decoration: underline;"> Resend the confirmation email.</a></span>
                                </div>
                                <div class="profile-form-edit">
                                    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <span class="pf-title">First Name</span>
                                                <div class="pf-field">
                                                    <input type="text" name="firstName" [(ngModel)]="user.firstName" placeholder="First Name">
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Last Name</span>
                                                <div class="pf-field">
                                                    <input type="text" name="lastName" [(ngModel)]="user.lastName" placeholder="Last Name">
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Current Job Title<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <input type="text" name="jobTitle" [(ngModel)]="user.jobTitle" placeholder="Current Job Title" />
                                                    <div *ngIf="jobTitleError" class="alert-danger">
                                                        Current Job Title is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Allow In Search</span>
                                                <div class="pf-field">
                                                    <select class="chosen-dropdown" name="allowInSearch" [(ngModel)]="user.allowInSearch">
													    <option value="true">Yes</option>
													    <option value="false">No</option>
												    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <span class="pf-title">Total Experience in Years<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <!-- <input type="number" name="experienceYear" [(ngModel)]="user.experienceYear" placeholder="Experience in Year" /> -->
                                                    <select class="chosen-dropdown" name="experienceYear" [(ngModel)]="user.experienceYear" placeholder="Experience in Year">
													    <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="18">18</option>
                                                        <option value="19">19</option>
                                                        <option value="20">20</option>
                                                        <option value="21">21</option>
                                                        <option value="22">22</option>
                                                        <option value="23">23</option>
                                                        <option value="24">24</option>
                                                        <option value="25">25</option>
                                                        <option value="26">26</option>
                                                        <option value="27">27</option>
                                                        <option value="28">28</option>
                                                        <option value="29">29</option>
                                                        <option value="30">30</option>
                                                        <option value="30+">30+</option>
                                                    </select>
                                                    <div *ngIf="experienceError" class="alert-danger">
                                                        Experience is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <span class="pf-title">Total Experience in Months<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <!-- <input type="number" name="experienceMonth" [(ngModel)]="user.experienceMonth" placeholder="Experience in Month" min="0" max="11" /> -->
                                                    <select class="chosen-dropdown" name="experienceMonth" [(ngModel)]="user.experienceMonth" placeholder="Experience in Month">
													    <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
												    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Age</span>
                                                <div class="pf-field">
                                                    <input type="number" name="age" [(ngModel)]="user.age" placeholder="Age" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Current Salary In Rupees Per Annum</span>
                                                <div class="pf-field">
                                                    <input type="number" name="currentSalary" [(ngModel)]="user.currentSalary" placeholder="eg:20,00,000" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Gender<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="gender" [(ngModel)]="user.gender">
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Frans Gender">Trans</option>
                                                        <option value="dontwishtospecify">Don't Wish to specify</option>
                                                    </select>
                                                    <div *ngIf="genderError" class="alert-danger">
                                                        Gender is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Education Levels<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="education" [(ngModel)]="user.education">
                                                        <option value="10th">10th</option>
                                                        <option value="12th">12th</option>
                                                        <option value="Diploma">Diploma</option>
                                                        <option value="Graduate">Graduate</option>
                                                        <option value="Post Graduate">Post Graduate</option>
                                                        <option value="Doctorate">Doctorate</option>
                                                    </select>
                                                    <div *ngIf="educationError" class="alert-danger">
                                                        Education is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="border-title">
                                                    <span class="pf-title">Languages Known<span style=color:#910B08>*</span></span>
                                                    <!-- <a (click)="addLanguage()" title="" class="col-lg-3"><i class="la la-plus"></i> Add Language</a> -->
                                                </div>
                                                <div class="pf-field">
                                                    <div class="pf-skillfield">
                                                        <!-- <input type="text" name="languages" [(ngModel)]="languages" placeholder="eg:English, German, Hindi" /> -->
                                                        <input type="text" placeholder="Languages" matInput [formControl]="languagesControl" [matAutocomplete]="autoLanguage" name="language" [(ngModel)]="language">
                                                        <mat-autocomplete #autoLanguage="matAutocomplete">
                                                            <mat-option *ngFor="let languageItem of languagesOptions | async" [value]="languageItem">
                                                                {{languageItem}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                        <div class="border-title" style="width: auto; margin: 15px;">
                                                            <a (click)="addLanguage()" title=""><i class="la la-plus"></i> Add Language</a>
                                                        </div>
                                                        <!-- <select data-placeholder="Please Select skill" class="chosen-dropdown" name="language" [(ngModel)]="language">
                                                            <option *ngFor="let languageItem of languages" value="{{languageItem}}">{{languageItem}}</option>
                                                        </select> -->
                                                        <div *ngIf="languageError" class="alert-danger">
                                                            Languages known is Mandatory.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="pf-field no-margin">
                                                    <ul class="tags">
                                                        <li class="addedTag" *ngFor="let language of user.languages; let index = index;">{{language}}<span (click)="deleteLanguage(index)" class="tagRemove">x</span><input type="hidden" name="tags[]" value="language"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="border-title">
                                                    <span class="pf-title col-lg-9">Skills<span style=color:#910B08>*</span> <span id="tooltip_targetAdress" class="tipctc" title="Add skills like php, photoshop,excel etc.">?</span></span>
                                                    <!-- <a (click)="addSkill()" title="" class="col-lg-3"><i class="la la-plus"></i> Add Skill</a> -->
                                                </div>
                                                <div class="pf-skillfield no-margin">
                                                    <input type="text" placeholder="Skill" matInput [formControl]="myControl" [matAutocomplete]="auto" name="skill" [(ngModel)]="skill">
                                                    <mat-autocomplete #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <div class="border-title" style="width: auto; margin: 15px;">
                                                        <a (click)="addSkill()" title=""><i class="la la-plus"></i> Add Skill</a>
                                                    </div>
                                                    <!-- <input type="text" name="skill" [(ngModel)]="skill" placeholder="Skills" /> -->
                                                    <!-- <select data-placeholder="Please Select skill" class="chosen-dropdown" name="skill" [(ngModel)]="skill">
                                                        <option *ngFor="let skillItem of skillList" value="{{skillItem}}">{{skillItem}}</option>
                                                    </select> -->
                                                    <div *ngIf="skillError" class="alert-danger">
                                                        Skill is Mandatory.
                                                    </div>
                                                </div>
                                                <div class="pf-field no-margin">
                                                    <ul class="tags">
                                                        <li class="addedTag" *ngFor="let skill of user.skills; let index = index;">{{skill}}<span (click)="deleteSkill(index)" class="tagRemove">x</span><input type="hidden" name="tags[]" value="skill"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <span class="pf-title">Personal Summary <span id="tooltip_targetAdress" class="tipctc" title="Highlight your unique experiences, ambitions and strengths.">?</span></span>
                                                <div class="pf-field">
                                                    <textarea name="description" [(ngModel)]="user.description" placeholder="Personal Summary"></textarea>
                                                    <!-- <div *ngIf="descriptionError" class="alert-danger">
                                                        Description is Mandatory.
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">
                                                <span class="pf-title">Phone Number<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <input type="text" name="phone" [(ngModel)]="user.phone" placeholder="Phone Number" />
                                                </div>
                                                <div *ngIf="phoneError" class="alert-danger">
                                                    Phone Number is Mandatory.
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Email</span>
                                                <div class="pf-field">
                                                    <input type="text" name="email" value="{{user.email}}" placeholder="Email" readonly />
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <span class="pf-title">Country<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Country" class="chosen-dropdown" name="countries" [(ngModel)]="user.country" (change)="getStates($event.target.value)">
                                                        <option *ngFor="let country of countries" value="{{country.name}}">{{country.name}}</option>
                                                    </select>
                                                    <div *ngIf="countryError" class="alert-danger">
                                                        Country is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">State<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select State" class="chosen-dropdown" name="states" [(ngModel)]="user.state" (change)="getCities($event.target.value)">
                                                        <option *ngFor="let state of states" value="{{state.name}}">{{state.name}}</option>
                                                    </select>
                                                    <div *ngIf="stateError" class="alert-danger">
                                                        State is Mandatory.
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <span class="pf-title">City<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select city" class="chosen-dropdown" name="cities" [(ngModel)]="user.city">
                                                        <option *ngFor="let city of cities" value="{{city.name}}">{{city.name}}</option>
                                                    </select>
                                                    <div *ngIf="cityError" class="alert-danger">
                                                        City is Mandatory.
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12 manage-jobs-sec">
                                                <div class="border-title">
                                                    <h3>Education<span style=color:#910B08>*</span></h3>
                                                </div>

                                                <div *ngIf="educationListError" class="alert-danger">
                                                    Education is Mandatory.
                                                </div>
                                                <div class="edu-history-sec">
                                                    <div class="edu-history" *ngFor="let education of user.educationList; let i = index">
                                                        <i class="la la-graduation-cap"></i>
                                                        <div class="edu-hisinfo row">
                                                            <div class="col-lg-6">
                                                                <span class="pf-title">Education Level</span>
                                                                <div class="pf-field">
                                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="educationLevel_{{i}}" [(ngModel)]="education.educationLevel">
                                                                        <option value="10th">10th</option>
                                                                        <option value="12th">12th</option>
                                                                        <option value="Diploma">Diploma</option>
                                                                        <option value="Graduate">Graduate</option>
                                                                        <option value="Post Graduate">Post Graduate</option>
                                                                        <option value="Doctorate">Doctorate</option>                                                                        
                                                                    </select>
                                                                    <div *ngIf="eduListError[i]" class="alert-danger">
                                                                        Education Level is Mandatory.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <span class="pf-title">Start Year</span>
                                                                <div class="pf-field">
                                                                    <input type="number" name="startYear_{{i}}" [(ngModel)]="education.startYear" />

                                                                    <div *ngIf="eduListStartYearError[i]" class="alert-danger">
                                                                        Start Year is Mandatory.
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <span class="pf-title">End Year</span>
                                                                <div class="pf-field">
                                                                    <input type="number" name="endYear_{{i}}" [(ngModel)]="education.endYear" />

                                                                    <div *ngIf="eduListEndYearError[i]" class="alert-danger">
                                                                        End Year is Mandatory.
                                                                    </div>
                                                                </div>
                                                                <div class="remember-label">
                                                                    <input type="checkbox" name="isStudying_{{i}}" id="isStudying_{{i}}" [(ngModel)]="education.isStudying"><label for="isStudying_{{i}}" style="padding: 0 0 0 22px;">Education in progress</label>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <span class="pf-title">Institute</span>
                                                                <div class="pf-field">
                                                                    <input type="text" name="institute_{{i}}" [(ngModel)]="education.institute" />
                                                                    <div *ngIf="eduListInstituteError[i]" class="alert-danger">
                                                                        Institute is Mandatory.
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <span class="pf-title">Stream</span>
                                                                <div class="pf-field">
                                                                    <input type="text" name="stream_{{i}}" [(ngModel)]="education.stream" />
                                                                    <div *ngIf="eduListStreamError[i]" class="alert-danger">
                                                                        Stream is Mandatory.
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <span class="pf-title">Course Highlights</span>
                                                                <div class="pf-field">
                                                                    <textarea name="highlight_{{i}}" [(ngModel)]="education.highlight" placeholder="Course Highlights"></textarea>
                                                                    <div *ngIf="eduError" class="alert-danger">
                                                                        Course Highlights is Mandatory.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul class="action_job">
                                                            <li><span>Delete</span><a (click)="deleteEducation(i)" title=""><i class="la la-trash-o"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div class="border-title">
                                                        <a (click)="addEducation()"><i class="la la-plus" ></i> Add Education</a>
                                                    </div>
                                                </div>
                                                <div class="border-title">
                                                    <h3>Work Experience</h3>
                                                </div>
                                                <div class="edu-history-sec">
                                                    <div class="edu-history style2" *ngFor="let work of user.workList; let i = index">
                                                        <i></i>
                                                        <div class="edu-hisinfo row">
                                                            <div class="col-lg-6">
                                                                <span class="pf-title">Job Title</span>
                                                                <div class="pf-field">
                                                                    <input type="text" name="designation_{{i}}" [(ngModel)]="work.designation" />
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6">
                                                                <span class="pf-title">Company</span>
                                                                <div class="pf-field">
                                                                    <input type="text" name="company_{{i}}" [(ngModel)]="work.company" />
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <span class="pf-title">Start Year</span>
                                                                <div class="pf-field">
                                                                    <input type="number" name="workstartYear_{{i}}" [(ngModel)]="work.startYear" />
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <span class="pf-title">Start Month</span>
                                                                <div class="pf-field">
                                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="workstartMonth_{{i}}" [(ngModel)]="work.startMonth">
                                                                        <option value="January">January</option>
                                                                        <option value="February">February</option>                                                                    
                                                                        <option value="March">March</option>                                                                    
                                                                        <option value="April">April</option>                                                                    
                                                                        <option value="May">May</option>                                                                    
                                                                        <option value="June">June</option>                                                                    
                                                                        <option value="July">July</option>                                                                    
                                                                        <option value="August">August</option>                                                                    
                                                                        <option value="September">September</option>                                                                    
                                                                        <option value="October">October</option>                                                                    
                                                                        <option value="November">November</option>                                                                    
                                                                        <option value="December">December</option>                                                                    
                                                                    </select>
                                                                </div>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <span class="pf-title">End Year</span>
                                                                <div class="pf-field">
                                                                    <input type="number" name="workendYear_{{i}}" id="workendYear_{{i}}" [(ngModel)]="work.endYear" [disabled]="work.isWorking" />
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <span class="pf-title">End Month</span>
                                                                <div class="pf-field">
                                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="workendMonth_{{i}}" id="workendMonth_{{i}}" [(ngModel)]="work.endMonth" [disabled]="work.isWorking">
                                                                        <option value="January">January</option>
                                                                        <option value="February">February</option>                                                                    
                                                                        <option value="March">March</option>                                                                    
                                                                        <option value="April">April</option>                                                                    
                                                                        <option value="May">May</option>                                                                    
                                                                        <option value="June">June</option>                                                                    
                                                                        <option value="July">July</option>                                                                    
                                                                        <option value="August">August</option>                                                                    
                                                                        <option value="September">September</option>                                                                    
                                                                        <option value="October">October</option>                                                                    
                                                                        <option value="November">November</option>                                                                    
                                                                        <option value="December">December</option>                                                                    
                                                                    </select>
                                                                </div>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <span class="pf-title"></span>
                                                                <div class="pf-field">
                                                                    <div class="remember-label">
                                                                        <input type="checkbox" name="isWorking_{{i}}" id="isWorking_{{i}}" [(ngModel)]="work.isWorking" (click)="disableFields(i)"><label for="isWorking_{{i}}" style="padding: 0 0 0 22px;">Still in role</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <span class="pf-title">Notice Period</span>
                                                                <div class="pf-field">
                                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="noticePeriod_{{i}}" [(ngModel)]="work.noticePeriod">
                                                                        <option value="1 month">1 month</option>
                                                                        <option value="2 months">2 months</option>
                                                                        <option value="3 months">3 months</option>
                                                                        <option value="4 months">4 months</option>
                                                                        <option value="5 months">5 months</option>
                                                                        <option value="6 months">6 months</option>                                                                     
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <span class="pf-title">Experience</span>
                                                                <div class="pf-field">
                                                                    <textarea name="experience_{{i}}" [(ngModel)]="work.experience" placeholder="Experience"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul class="action_job">
                                                            <li><span>Delete</span><a (click)="deleteWork(i)" title=""><i class="la la-trash-o"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div class="border-title">
                                                        <a (click)="addWork()" title=""><i class="la la-plus"></i> Add Experience</a>
                                                    </div>
                                                </div>

                                                <div class="border-title">
                                                    <h3>Licences & Certifications<span id="tooltip_targetAdress" class="tipctc" title="Patents, licences, certifications etc.">?</span></h3>
                                                </div>
                                                <div class="edu-history-sec">
                                                    <div class="edu-history style2" *ngFor="let skill of user.skillList; let i = index">
                                                        <i></i>
                                                        <div class="edu-hisinfo row">
                                                            <div class="col-lg-6">
                                                                <span class="pf-title">Licence or certification name</span>
                                                                <div class="pf-field">
                                                                    <input type="text" name="title_{{i}}" [(ngModel)]="skill.title" />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <span class="pf-title">Issuing organisation (optional)</span>
                                                                <div class="pf-field">
                                                                    <input type="text" name="organisation_{{i}}" [(ngModel)]="skill.organisation" />
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <span class="pf-title">Issue year</span>
                                                                <div class="pf-field">
                                                                    <input type="number" name="startYear_{{i}}" [(ngModel)]="skill.startYear" />
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <span class="pf-title">Issue month</span>
                                                                <div class="pf-field">
                                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="startMonth_{{i}}" [(ngModel)]="skill.startMonth">
                                                                        <option value="January">January</option>
                                                                        <option value="February">February</option>                                                                    
                                                                        <option value="March">March</option>                                                                    
                                                                        <option value="April">April</option>                                                                    
                                                                        <option value="May">May</option>                                                                    
                                                                        <option value="June">June</option>                                                                    
                                                                        <option value="July">July</option>                                                                    
                                                                        <option value="August">August</option>                                                                    
                                                                        <option value="September">September</option>                                                                    
                                                                        <option value="October">October</option>                                                                    
                                                                        <option value="November">November</option>                                                                    
                                                                        <option value="December">December</option>                                                                    
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <span class="pf-title">Expiry year</span>
                                                                <div class="pf-field">
                                                                    <input type="number" name="endYear_{{i}}" [(ngModel)]="skill.endYear" />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <span class="pf-title">Expiry month</span>
                                                                <div class="pf-field">
                                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="endMonth_{{i}}" [(ngModel)]="skill.endMonth">
                                                                        <option value="January">January</option>
                                                                        <option value="February">February</option>                                                                    
                                                                        <option value="March">March</option>                                                                    
                                                                        <option value="April">April</option>                                                                    
                                                                        <option value="May">May</option>                                                                    
                                                                        <option value="June">June</option>                                                                    
                                                                        <option value="July">July</option>                                                                    
                                                                        <option value="August">August</option>                                                                    
                                                                        <option value="September">September</option>                                                                    
                                                                        <option value="October">October</option>                                                                    
                                                                        <option value="November">November</option>                                                                    
                                                                        <option value="December">December</option>                                                                    
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul class="action_job">
                                                            <li><span>Delete</span><a (click)="deleteSkills(i)" title=""><i class="la la-trash-o"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div class="border-title">
                                                        <a (click)="addSkills()" title=""><i class="la la-plus"></i> Add Skills</a>
                                                    </div>
                                                </div>
                                            </div>



					   <div class="col-lg-12">
                                                <span class="pf-title">Resume Content</span>
                                                <div class="pf-field">
       
<img src="https://melquiades.in/public/pdf.jpeg" alt="PDF" width="80%" height="auto">



 
<textarea rows="40" cols="100">

1 Research and IT Training and Development in Evolving and Trending Industry Skills 
EXPERT PROFILE 
Dr. G. P. Bhole (Computer Engineering Educator| Researcher | IoT and Wireless Network Expert) with 25+ years of experience in research and education in the domain of wireless networks and IoT. 
Dr. Sandeep Udmale (Computer Engineering Educator | Researcher | AI and ML Expert) with 15+ years’ experience in research and education in the domain of AI, Data science and Machine learning. 
Dr. Preeti Godabole (Computer Engineering Educator | Researcher | IoT Expert | Cybersecurity Enthusiast) 
PROJECTS UNDERTAKEN – 
• Developing Training centers, Training infrastructure, Training Module, Curriculum, and Training materials in IT, Computer Engineering, Programming, Computer Hard ware, Artificial Intelligence, Machine Learning, Data Science, IoT and Cybersecurity. 
• Training naïve and expert users in AI, ML, Data Science, IoT and Cybersecurity 
• Research and publications in reputed internationally recognized journals in trending domains. 
• Mentoring for creation of project proposals and receiving of grants 
• Use of Pedagogy and active learning techniques in training programs 
• Use of technology and tools to setup center of excellence in IoT, Data Science and AI. 
2 E-platforms (mobile and website) and Platform Building 
EXPERT PROFILE 
Ajit Maya: 22+ years of experience as technical consultant and developer 
Dharmendra Choudhary: 20+ years of experience in platform development Anand Malagi: 20+ years of experience in website and app development 
PROJECTS UNDERTAKEN – 
• Customized Web Solutions: Micro-services, Java, Python 
• Customized Mobile Solutions: React Native, Swift, Kotlin 
• Data Analytics: Power BI, Tableau, Looker, Metabase, Azure Data Factory, Data Bricks, MS Fabric, Machine Learning 
• Emerging Technologies: Blockchain, DevSecOps, Cyber and Information Security 
• UI/UX: Competitive Analysis, User Flow, Wireframes, Style Guide, High Fidelity Mockups, Prototypes 
• Quality Assurance: Smoke, Sanity, Integration, System, Security, Compatibility & Automation Testing 
• Methodologies: Agile, CICD Cloud Storage and Networking Services 
EXPERT PROFILE 
Mr. Pramod Katti (Hardware and Network Engineer) 
Mr. Anand (IT evangelist with 20 years of experience as a technology consultant in Cloud and Networking) 
Mr. Pradeep Rao (IT evangelist with 20 years of experience as a technology consultant in software architecture) 
PROJECTS UNDERTAKEN – 
• Provided solutions in virtualization, network, storage, IoT 
• Provided cloud based solutions to manufacturing, construction and IT industries. 
• Provided solutions in the area of system, network, and application security 
• Has served numerous clients in Banking, Medical, Government and IT.
• Cloud Platforms Used : AWS, GCP
• Cloud Services: EC2, S3, Lambda, Google Compute Engine, Google Cloud Storage, etc. 
• Remote Monitoring and Logging: CloudWatch, Site 24x7.


  
</textarea>                                        
	
						</div>
                                            </div>


                                            <div class="col-lg-12">
                                                <button type="submit">Update</button>
                                                <button type="button" (click)="resumeTemplate()" *ngIf="user.profileUpdated">View Resume Template</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

    <div class="profile-sidebar">
        <span class="close-profile"><i class="la la-close"></i></span>
        <div class="can-detail-s">
            <div class="cst"><img src="assets/images/resource/es1.jpg" alt="" /></div>
            <h3>David CARLOS</h3>
            <span><i>UX / UI Designer</i> at Atract Solutions</span>
            <p><a href="https://grandetest.com/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="e784958286938e91828b869e829594d7dfdfa7808a868e8bc984888a">[email&#160;protected]</a></p>
            <p>Member Since, 2017</p>
            <p><i class="la la-map-marker"></i>Istanbul / Turkey</p>
        </div>
        <div class="tree_widget-sec">
            <ul>
                <li><a href="candidates_profile.html" title=""><i class="la la-file-text"></i>My Profile</a></li>
                <li><a href="candidates_my_resume.html" title=""><i class="la la-briefcase"></i>My Resume</a></li>
                <li><a href="candidates_shortlist.html" title=""><i class="la la-money"></i>Shorlisted Job</a></li>
                <li><a href="candidates_applied_jobs.html" title=""><i class="la la-paper-plane"></i>Applied Job</a></li>
                <li><a href="candidates_job_alert.html" title=""><i class="la la-user"></i>Job Alerts</a></li>
                <li><a href="candidates_cv_cover_letter.html" title=""><i class="la la-file-text"></i>Cv & Cover Letter</a></li>
                <li><a href="candidates_change_password.html" title=""><i class="la la-flash"></i>Change Password</a></li>
                <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
            </ul>
        </div>
    </div>
    <!-- Profile Sidebar -->

</body>



</html>
