<div id="app">
    <div class="responsive-header">
        <div class="responsive-menubar">
            <div class="res-logo">
                <a href="" title=""><img src="assets/images/resource/logo.png" alt="" class="logost" style="width: 80px;" /></a>
            </div>
            <div class="menu-resaction">
                <div class="res-openmenu">
                    <img src="assets/images/icon5.png" alt="" />
                </div>
                <div class="res-closemenu">
                    <img src="assets/images/icon6.png" alt="" />
                </div>
            </div>
        </div>
        <div class="responsive-opensec">
            <div class="btn-extars">
                <a *ngIf="showEmployerBoard" href="employer_post_job" title="" class="post-job-btn"><i class="la la-plus"></i>Post Jobs</a>
                <ul class="account-btns" *ngIf="!isLoggedIn">
                    <li class="signup-popup"><a title="" style="background: none repeat scroll 0 0 #910B08;
                        border: 2px solid #910B08;
                        border-radius: 0 3px 3px 0;
                        color: rgb(255, 255, 255);
                        float: left;
                        min-width: 120px;
                        padding: 8px 19px; margin-right: 4px;"><i class="la la-key"></i> Sign Up</a></li>
                    <li class="signin-popup"><a title="" style="background: none repeat scroll 0 0 #910B08;
                        border: 2px solid #910B08;
                        border-radius: 0 3px 3px 0;
                        color: rgb(255, 255, 255);
                        float: left;
                        min-width: 120px;
                        padding: 8px 29px;"><i class="la la-external-link-square"></i> Login</a></li>
                </ul>
                <ul class="account-btns" *ngIf="isLoggedIn">
                    <li *ngIf="showEmployerBoard"><a href="employer_profile" title="" style="color: #96a0bd;"> Dashboard</a></li>
                    <li *ngIf="showEmployeeBoard"><a href="candidates_profile" title="" style="color: #96a0bd;"> Dashboard</a></li>
                    <li><a title="" (click)="logout()" style="color: #96a0bd; margin-left: 13px;">LogOut</a></li>
                </ul>
            </div>
            <!-- Btn Extras -->
            <!-- <form class="res-search">
                <input type="text" placeholder="Job title, keywords or company name" />
                <button type="submit"><i class="la la-search"></i></button>
            </form> -->
            <div class="responsivemenu">
                <ul>
                    <li>
                        <a href="" title="">Home</a>
                    </li>
                    <li>
                        <a href="about" title="">About us</a>
                    </li>
                    <li *ngIf="showEmployeeBoard">
                        <a href="employers_list" title=""> Employers</a>
                    </li>
                    <li *ngIf="showEmployerBoard">
                        <a href="candidates_list" title="">Talent Search</a>
                    </li>
                    <li>
                        <a href="blog_list" title="">Blog</a>
                    </li>
                    <li>
                        <a href="job_list" title="">Jobs</a>
                    </li>
                    <li>
                        <a href="contact" title="">Contact Us</a>
                    </li>
                    <!-- <li>
                        <a href="pricing" title="">Pricing</a>
                    </li> -->

                    <li class="menu-item-has-children">
                        <a href="#" title="">Pricing</a>
                        <ul>
                            <li><a href="pricing" title="">Employer Pricing</a></li>
                            <li><a href="pricing1" title="">Employee Pricing</a></li>
                        </ul>
                    </li>
                    <li class="menu-item-has-children">
                        <a href="#" title="">Features</a>
                        <ul>
                            <li><a href="employeefeatures" title="">Employee Features</a></li>
                            <li><a href="employerfeatures" title="">Employer Features</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <header class="stick-top forsticky">
        <div class="menu-sec">
            <div class="container">
                <div class="logo">
                    <a href="" title=""><img class="hidesticky" src="assets/images/resource/logo.png" alt="" style="width: 80px;" /><img class="showsticky" src="assets/images/resource/logo10.png" alt="" /></a>
                </div>
                <!-- Logo -->
                <div class="btn-extars">
                    <a *ngIf="showEmployerBoard" href="employer_post_job" title="" class="post-job-btn"><i class="la la-plus"></i>Post Jobs</a>
                    <a *ngIf="!isLoggedIn" title="" class="post-job-btn signin-popup"><i class="la la-plus"></i>Post Jobs</a>
                    <ul class="account-btns" *ngIf="!isLoggedIn">
                        <li class="signin-popup"><a title="" class="login-btn" style="color: white;"><i class="la la-external-link-square"></i> Login</a></li>
                        <li class="signup-popup"><a title="" class="signup-btn" style="color: white;"><i class="la la-key"></i> Sign Up</a></li>
                    </ul>
                    <ul class="account-btns" *ngIf="isLoggedIn">
                        <li *ngIf="showEmployerBoard">
                            <a href="employer_profile" title="" style="margin-top: 37px;"><img src="assets/images/resource/dashboard.png" alt=""> Dashboard</a>
                        </li>
                        <li *ngIf="showEmployeeBoard">
                            <a href="candidates_profile" title="" style="margin-top: 37px;"><img src="assets/images/resource/dashboard.png" alt=""> Dashboard</a>
                        </li>
                        <li style="margin-left: 20px;">
                            <a title="" style="margin-top: 37px;" data-toggle="modal" data-target="#myModal"><img src="assets/images/resource/logout.png" alt=""> LogOut</a>
                        </li>
                    </ul>
                </div>
                <!-- Btn Extras -->
                <nav>
                    <ul>
                        <li>
                            <a href="" title=""><img src="assets/images/resource/home.png" alt=""> Home</a>
                        </li>
                        <li *ngIf="showEmployeeBoard">
                            <a href="employers_list" title=""><img src="assets/images/resource/employment.png" alt=""> Employers</a>
                            <!-- <ul>
                                <li><a href="employers_list" title=""> Employer List</a></li>
                                <li *ngIf="showEmployerBoard"><a href="employer_profile" title=""> Employer Dashboard</a></li>
                            </ul> -->
                        </li>
                        <li *ngIf="showEmployerBoard">
                            <a href="candidates_list" title=""><img src="assets/images/resource/employees.png" alt=""> Talent Search</a>
                            <!-- <ul>
                                <li><a href="candidates_list" title="">Candidates List</a></li>
                                <li *ngIf="showEmployeeBoard"><a href="candidates_profile" title="">Candidates Dashboard</a></li>
                            </ul> -->
                        </li>
                        <li>
                            <a href="blog_list" title=""><img src="assets/images/resource/blog.png" alt=""> Blog</a>
                        </li>
                        <li *ngIf="showEmployeeBoard || !isLoggedIn">
                            <a href="job_list" title=""><img src="assets/images/resource/job.png" alt=""> Jobs</a>
                        </li>
                        <!-- <li>
                            <a href="pricing" title=""><img src="assets/images/resource/price.png" alt=""> Pricing</a>
                        </li> -->
                        <li class="menu-item-has-children">
                            <a title=""><img src="assets/images/resource/price.png" alt="">Pricing</a>
                            <ul>
                                <li><a href="pricing" title="">Employer Pricing</a></li>
                                <li><a href="pricing1" title="">Employee Pricing</a></li>
                            </ul>
                        </li>
                        <li class="menu-item-has-children">
                            <a title=""><img src="assets/images/resource/features.png" alt="">Features</a>
                            <ul>
                                <li><a href="employeefeatures" title="">Employee Features</a></li>
                                <li><a href="employerfeatures" title="">Employer Features</a></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
                <!-- Menus -->
            </div>
        </div>
    </header>

    <div>
        <router-outlet></router-outlet>
    </div>

    <div class="d-block d-sm-none" style="margin-bottom: 40px;">
        <div *ngIf="!isLoggedIn" class="row" style="width: 100%; position: fixed; bottom: 0px; z-index: 90; left: 0; right: 0; margin: auto;">
            <div class="signin-popup" style="width: 50%; text-align: center; padding: 8px 19px; background: none repeat scroll 0 0 #910B08; border: 2px solid #910B08;"><a title="" class="login-btn" style="color: white; float: none; background: none repeat scroll 0 0 #910B08; border: 2px solid #910B08;"><i class="la la-external-link-square"></i> Login</a></div>
            <div class="signup-popup" style="width: 50%; text-align: center; padding: 8px 19px; background: none repeat scroll 0 0 #910B08; border: 2px solid #910B08;"><a title="" class="signup-btn" style="color: white; float: none;"><i class="la la-key"></i> Sign Up</a></div>
        </div>
    </div>

    <footer class="d-none d-md-block">
        <div class="block">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 column">
                        <div class="widget">
                            <div class="about_widget">
                                <!-- <div class="logo">
                                    <a href="index.html" title=""><img src="assets/images/resource/logo.png" alt="" style="width: 80px;" /></a>
                                </div> -->
                                <h3 class="footer-title">Connect With Us</h3>

                                <span>No.45/3, 1st Floor,</span>
                                <span>Gopalakrishna Complex,</span>
                                <span>Residency Road, Bengaluru - 560025</span>
                                <span>Karnataka</span>
                                <span>India</span>
                                <!-- <span><a href="info@c2c.com" class="__cf_email__" data-cfemail="3c55525a537c56535e54495248125f5351">info@c2c.com</a></span> -->
                                <div class="social">

                                    <a target="blank" href="https://www.facebook.com/CTCJOBPORTAL/" title=""><i class="la la-facebook"></i></a>
                                    <a target="blank" href="https://www.instagram.com/clicktocareers_/?hl=en" title=""><i class="la la-instagram"></i></a>
                                    <a target="blank" href="https://www.linkedin.com/company/clicktocareers" title=""><i class="la la-linkedin"></i></a>
                                    <a target="blank" href="https://youtube.com/channel/UChMae6eDePL8dFQBXzz2eTA" title=""><i class="la la-youtube"></i></a>

                                </div>
                            </div>
                            <!-- About Widget -->
                        </div>
                    </div>
                    <div class="col-lg-4 column">
                        <div class="widget">
                            <h3 class="footer-title">Quick Links</h3>
                            <div class="link_widgets">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <!-- <a href="#" title="">Privacy & Security </a> -->
                                        <a href="terms" title="">Terms of Service</a>
                                        <!-- <a href="#" title="">FAQ's </a> -->
                                        <a href="about" title="">About us </a>
                                        <!-- <a href="#" title="">Lending Licnses </a>
                                        <a href="#" title="">Disclaimers </a> -->
                                    </div>
                                    <div class="col-lg-6">
                                        <a href="pricing" title="">Employer Pricing </a>
                                        <a href="pricing1" title="">Employee Pricing </a>
                                        <a href="#" title="">How It Works </a>
                                        <!-- <a href="#" title="">For Employers </a>
                                        <a href="#" title="">Underwriting </a> -->
                                        <a href="contact" title="">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 column">
                        <div class="widget">
                            <h3 class="footer-title"></h3>
                            <div class="link_widgets">
                                <div class="row">
                                    <!-- <div class="col-lg-12">
                                        <a href="#" title="">US Jobs</a>
                                        <a href="#" title="">Canada Jobs</a>
                                        <a href="#" title="">UK Jobs</a>
                                        <a href="#" title="">Emplois en Fnce</a>
                                        <a href="#" title="">Jobs in Deuts</a>
                                        <a href="#" title="">Vacatures China</a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 column">
                        <div class="widget">
                            <div class="download_widget">
                                <a href="#" title="location"><img src="assets/images/resource/qr-code-2.png" alt="" style="width: 160px;" /></a>
                                <a href="https://www.google.com/maps/dir//clicktocareers/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bae3d91e0a38a5d:0xe76078fe76ac13b1?sa=X&ved=2ahUKEwia8pLxmeztAhVA8HMBHWjVCS4Q9RcwDXoECBYQBQ" title="" style="color: #b0bac3;" target="_blank" class="address">Open in google maps </a>
                                <!-- <a href="#" title=""><img src="assets/images/resource/dw2.png" alt="" /></a>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-line">
            <span>© 2021 ~clicktocareers All rights reserved. Developed by zthplanet</span>
            <!-- <a href="#scrollup" class="scrollup" title=""><i class="la la-arrow-up"></i></a> -->
        </div>
    </footer>
    <div class="account-popup-area signin-popup-box">
        <div class="account-popup">
            <form name="form" (ngSubmit)="f.form.valid && onLogin()" #f="ngForm" novalidate>
                <span class="close-popup" (click)="resetForm(f)"><i class="la la-close"></i></span>
                <h3 class="popup-heading">Login</h3>
                <!-- <div class="select-user">
                <span>Candidate</span>
                <span>Employer</span>
            </div> -->
                <div class="cfield" style="margin-top: 30px;">
                    <input type="text" class="form-control" name="username" [(ngModel)]="form.username" required #username="ngModel" placeholder="Email id" />
                    <i class="la la-user"></i>
                    <div class="alert-danger" role="alert" *ngIf="f.submitted && username.invalid">
                        Email id is required!
                    </div>
                </div>
                <div class="cfield">
                    <input type="password" class="form-control" name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel" placeholder="********" />
                    <i class="la la-key"></i>
                    <div class="alert-danger" role="alert" *ngIf="f.submitted && password.invalid">
                        <div *ngIf="password.errors.required">Password is required</div>
                        <div *ngIf="password.errors.minlength">
                            Password must be at least 6 characters
                        </div>
                    </div>
                </div>
                <p class="remember-label">
                    <input type="checkbox" name="cb" id="cb1"><label for="cb1">Remember me</label>
                </p>
                <a href="reset_password" title="">Forgot Password?</a>
                <button type="submit">Login</button>
                <div class="cfield">
                    <div class="alert-warning" *ngIf="f.submitted && isLoginFailed">
                        Login failed!<br />{{ errorMessage }}
                    </div>
                </div>
            </form>
            <p>Don't have an account? <a class="signup-popup" title="" style="color: #910B08;">Sign up </a></p>
        </div>
    </div>
    <!-- LOGIN POPUP -->

    <div class="account-popup-area signup-popup-box">
        <div class="account-popup">
            <form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                <span class="close-popup" (click)="resetForm(f)"><i class="la la-close"></i></span>
                <h3 class="popup-heading">Sign Up</h3>
                <div class="select-user" style="margin-bottom: 30px;">
                    <span (click)="getRole('employee')">Candidate</span>
                    <span (click)="getRole('employer')">Employer</span>
                    <div class="alert-danger" *ngIf="roleError">
                        <div style="padding: 10px 0;">Role is required</div>
                    </div>
                </div>
                <div class="cfield">
                    <input type="text" class="form-control" name="firstName" [(ngModel)]="form.firstName" required #firstName="ngModel" placeholder="First Name" />
                    <div class="alert-danger" *ngIf="f.submitted && firstName.invalid">
                        <div *ngIf="firstName.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="cfield">
                    <input type="text" class="form-control" name="lastName" [(ngModel)]="form.lastName" required #lastName="ngModel" placeholder="Last Name" />
                    <div class="alert-danger" *ngIf="f.submitted && lastName.invalid">
                        <div *ngIf="email.errors.required">Last Name is required</div>
                    </div>
                </div>
                <div class="cfield">
                    <input type="text" class="form-control" name="phone" [(ngModel)]="form.phone" required #phone="ngModel" placeholder="Phone Number" />
                    <i class="la la-phone"></i>
                    <div class="alert-danger" *ngIf="f.submitted && lastName.invalid">
                        <div *ngIf="email.errors.required">Phone Number is required</div>
                    </div>
                </div>

                <div class="cfield">
                    <input type="email" class="form-control" name="email" [(ngModel)]="form.email" required email #email="ngModel" placeholder="Email" />
                    <div class="alert-danger" *ngIf="f.submitted && email.invalid">
                        <div *ngIf="email.errors.required">Email is required</div>
                        <div *ngIf="email.errors.email">
                            Email must be a valid email address
                        </div>
                    </div>
                </div>
                <div class="cfield">
                    <input type="password" class="form-control" name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel" placeholder="********" />
                    <div class="alert-danger" *ngIf="f.submitted && password.invalid">
                        <div *ngIf="password.errors.required">Password is required</div>
                        <div *ngIf="password.errors.minlength">
                            Password must be at least 6 characters
                        </div>
                    </div>
                </div>
                <div class="remember-label">
                    <input type="checkbox" name="checkbox" id="agree" [(ngModel)]="isChecked"><label for="agree" style="padding: 0 0 0 22px;">I have read and agree to the  <a href="terms" title="" style="text-decoration: underline;">Terms and Conditions and Privacy Policy</a></label>
                    <div class="alert-danger" *ngIf="isCheckedError">
                        Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy
                    </div>
                </div>
                <div class="cfield" style="border: none; margin: inherit;">
                    <re-captcha [(ngModel)]="form.captcha" name="captcha" required siteKey="6Lck0T4aAAAAALuMgG9lNQe5Z4Fv59OCwicJOEw4" #captcha="ngModel"></re-captcha>
                    <div class="alert-danger" *ngIf="f.submitted && captcha.invalid">
                        <div *ngIf="captcha.errors.required">Invalid Captcha</div>
                    </div>
                </div>
                <button type="submit">Signup</button>

                <div class="cfield">
                    <div class="alert-warning" *ngIf="f.submitted && isSignUpFailed">
                        Signup failed!<br />{{ errorMessage }}
                    </div>
                </div>
            </form>

            <p>Already a user? <a class="signin-popup" title="" style="color: #910B08;">Log in </a></p>

            <div class="alert alert-success" *ngIf="isSuccessful">
                Your registration is successful!
            </div>
        </div>
    </div>
    <!-- SIGNUP POPUP -->
    <!-- Modal -->
    <div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <!-- <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div> -->
                <div class="modal-body">
                    <h3 class="popup-heading" style="text-align: center; padding: 0px;">Survey</h3>
                    <form name="form" (ngSubmit)="sendSurvey()" #f="ngForm" novalidate>
                        <span class="pf-title col-lg-8">1. How easy did you find our service to use (5 being very easy)</span>
                        <div class="pf-field col-lg-4">
                            <select data-placeholder="Please Select Specialism" class="form-control dropdown-survey" name="serviceRate" [(ngModel)]="form.serviceRate">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
							</select>
                        </div>
                        <span class="pf-title col-lg-8">2. How would you rate our website out of 5 (5 being excellent)</span>
                        <div class="pf-field col-lg-4">
                            <select data-placeholder="Please Select Specialism" class="form-control dropdown-survey" name="websiteRate" [(ngModel)]="form.websiteRate">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
							</select>
                        </div>
                        <span class="pf-title col-lg-8">3. How likely are you to recommend our website to a friend</span>
                        <div class="pf-field col-lg-4">
                            <select data-placeholder="Please Select Specialism" class="form-control dropdown-survey" name="recommendRate" [(ngModel)]="form.recommendRate">
                                <option>Not Likely at all</option>
                                <option>Not likely</option>
                                <option>Likely</option>
                                <option>Very Likely</option>
							</select>
                        </div>
                        <span class="pf-title col-lg-8">4. How did you hear about us</span>
                        <div class="pf-field col-lg-4">
                            <select data-placeholder="Please Select Specialism" class="form-control dropdown-survey" name="hearAbout" [(ngModel)]="form.hearAbout">
                                <option>Through a friend</option>
                                <option>LinkedIn</option>
                                <option>Social Media</option>
                                <option>Other</option>
							</select>
                        </div>
                        <span class="pf-title col-lg-12">5. Other comment</span>
                        <div class="pf-field col-lg-12">
                            <textarea name="comment" [(ngModel)]="form.comment" placeholder="Comment"></textarea>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="signup-btn" data-dismiss="modal" (click)="logout()">Close</button>
                    <button type="submit" class="login-btn" (click)="sendSurvey()">Submit</button>
                </div>
            </div>

        </div>
    </div>

</div>