import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-constants';
import { Job } from 'src/app/models/job';
import { User } from 'src/app/models/user';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-candidates-applied-job',
  templateUrl: './candidates-applied-job.component.html',
  styleUrls: ['./candidates-applied-job.component.css']
})
export class CandidatesAppliedJobComponent implements OnInit {

  user: User;
  jobs: Job[];
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  isExamAlert: boolean = false;
  apiURL = GlobalConstants.apiURL;

  constructor(private userService: UserService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    const currentUser = this.tokenStorageService.getUser();
    this.isExamAlert = currentUser.examAlert;
    
    this.userService.getByUsername(currentUser.email).subscribe(
      data => {
        this.user = data;
        this.jobs = data.appliedJobs;
        
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }
}
