<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/c2c-html/employer_single1.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:44 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Job Hunt</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">


</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>{{company.name}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 column">
                            <div class="job-single-sec style3">
                                <div class="job-head-wide">
                                    <div class="row">
                                        <div class="col-lg-10">
                                            <div class="job-single-head3 emplye">
                                                <div class="job-thumb"> <img src="{{apiURL}}{{company.imageUrl}}" onerror="this.src='assets/images/resource/employer.png';" alt="" style="width: 180px; height: 180px;" /></div>
                                                <div class="job-single-info3">
                                                    <h3>{{company.name}}</h3>
                                                    <span><a href="{{company.website}}" target="_blank"><i class="la la-unlink"></i> {{company.website}}</a> </span><br><br><br>
                                                    <span><i class="la la-map-marker"></i>{{company.city}}, {{company.country}}</span>

                                                </div>
                                            </div>
                                            <!-- Job Head -->
                                        </div>
                                        <div class="col-lg-2">

                                            <div class="emply-btns">
                                                <!-- <a class="seemap" href="#" title=""><i class="la la-map-marker"></i> See On Map</a> -->
                                                <a class="signin-popup followus" (click)="addFavourite()" title="" *ngIf="!isLoggedIn"><i class="la la-paper-plane"></i> Log in to Follow us</a>
                                                <a class="followus" (click)="addFavourite()" title="" *ngIf="isLoggedIn && !isFollowing"><i class="la la-paper-plane"></i> Follow us</a>
                                                <a class="followus" (click)="addFavourite()" title="" *ngIf="isLoggedIn && isFollowing" [class.disabled]="isFollowing ? true : null"><i class="la la-paper-plane"></i> Following</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="job-wide-devider">
                                    <div class="row">
                                        <div class="col-lg-8 column">
                                            <div class="job-details">
                                                <h3>About Business Network</h3>
                                                <p style="white-space: pre-wrap;">{{company.about}}</p>
                                            </div>
                                            <div class="recent-jobs">
                                                <h3>Jobs from Business Network</h3>
                                                <div class="job-list-modern">
                                                    <div class="job-listings-sec no-border">
                                                        <div class="job-listing wtabs noimg" *ngFor="let job of jobs | slice: 0:6">
                                                            <a href="/job_details/{{job._id}}" title=" ">
                                                                <div class="job-title-sec">
                                                                    <div class="c-logo" style="padding: 15px;"> <img [src]="apiURL+job.company.imageUrl" onerror="this.src='assets/images/resource/employer.png';" style="width: 100px;" /> </div>
                                                                    <h3 style="padding: 0 20px;">
                                                                        <a href="/job_details/{{job._id}}" title=" ">{{job.title}}</a></h3>
                                                                    <div class="job-lctn " style="padding: 0 20px;">
                                                                        <span style="padding: 0 20px; color: #888888;">
                                                                            <i class="la la-briefcase" title="Experience"></i> {{job.experience}} years
                                                                        </span>
                                                                        <br>
                                                                        <span style="padding: 0 20px; color: #888888;">
                                                                            <i class="la la-industry" title="Industry"></i> {{job.industry}}
                                                                        </span>
                                                                        <br>
                                                                        <span style="padding: 0 20px; color: #888888;">
                                                                            <i class="la la-map-marker" title="Location"></i>{{job.city}}, {{job.country}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="job-style-bx ">
                                                                    <span class="job-is ft ">{{job.type}}</span>
                                                                    <i>{{convert(job.postedOn)}}</i>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 column">
                                            <div class="job-overview">
                                                <h3>Company Information</h3>
                                                <ul>
                                                    <!-- <li><i class="la la-eye"></i>
                                                        <h3>Viewed </h3><span>164</span></li>
                                                    <li><i class="la la-file-text"></i>
                                                        <h3>Posted Jobs</h3><span>4</span></li> -->
                                                    <li *ngIf="company.city"><i class="la la-map"></i>
                                                        <h3>Locations</h3><span>{{company.city}}, {{company.country}}</span></li>
                                                    <li *ngIf="company.industry"><i class="la la-bars"></i>
                                                        <h3>Industry</h3><span>{{company.industry}}</span></li>
                                                    <li *ngIf="company.establishedYear"><i class="la la-clock-o"></i>
                                                        <h3>Since</h3><span>{{company.establishedYear}}</span></li>
                                                    <li *ngIf="company.facebookProfile"><i class="la la-facebook"></i>
                                                        <h3>Facebook</h3><span><a href="company.facebookProfile" target="_blank">{{company.facebookProfile}}</a></span></li>
                                                    <li *ngIf="company.linkedinProfile"><i class="la la-linkedin"></i>
                                                        <h3>LinkedIn</h3><span><a href="company.linkedinProfile" target="_blank">{{company.linkedinProfile}}</a></span></li>
                                                    <!-- <li><i class="la la-users"></i>
                                                        <h3>Team Size</h3><span>{{company.size}}</span></li> -->
                                                    <!-- <li><i class="la la-user"></i>
                                                        <h3>Followers</h3><span>15</span></li> -->
                                                </ul>
                                            </div>
                                            <!-- Job Overview -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </div>

</body>

<!-- Mirrored from grandetest.com/theme/c2c-html/employer_single1.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:45 GMT -->

</html>