<!DOCTYPE html>
<html>



<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Click To Careers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape d-none d-md-block">
            <div class="block no-padding">
                <div *ngIf="!bannerImg" data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <div *ngIf="bannerImg" data-velocity="-.1" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header-detail" [style.background-image]="'url(' + bannerImg + ')'" style=" padding-bottom: 390px; background-size: 100% 100%;">
                                <!-- <h3>{{job.title}}</h3>
                                <div class="job-statistic">
                                    <span>{{job.type}}</span>
                                    <p><i class="la la-map-marker"></i>{{job.city}}, {{job.country}}</p>
                                    <p><i class="la la-calendar-o"></i>Posted {{convert(job.postedOn)}}</p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 column">
                            <div class="job-single-sec">
                                <div class="job-single-head">
                                    <div class="job-thumb d-none d-md-block"> <img [src]="companyImg" onerror="this.src='assets/images/resource/employer.png';" alt="" /> </div>
                                    <div class="job-head-info">
                                        <h3>{{job.title}}</h3>
                                        <h6><a href="employer_details/{{job.company._id}}">{{job.company.name}}</a></h6>
                                        <span>{{job.address}}</span>
                                        <p>{{job.type}}</p>
                                        <p *ngIf="job.company.website"><a href="{{job.company.website}}" target="_blank"><i
                                                    class="la la-unlink"></i> {{job.company.website}}</a></p>
                                        <p><i class="la la-map-marker"></i>{{job.city}}, {{job.country}}</p>
                                        <p><i class="la la-calendar-o"></i>Posted {{convert(job.postedOn)}}</p>
                                        <!-- <p><i class="la la-phone"></i> {{company.phone}}</p> -->
                                        <!-- <p><i class="la la-envelope-o"></i> <a href="https://grandetest.com/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="71101d185f050417101f311b1e1319041f055f121e1c">[email&#160;protected]</a></p>
                                        -->
                                    </div>
                                </div>
                                <!-- Job Head -->
                                <div class="job-details">
                                    <h3>Job Description</h3>
                                    <p [innerHTML]="job.description" style="white-space: pre-wrap;"></p>
                                    <h3 *ngIf="isResponsibility">Roles and Responsibilities</h3>
                                    <p *ngFor="let responsibility of job.responsibilities; let i = index" style="white-space: pre-wrap;">• {{job.responsibilities[i]}}</p>

                                    <h3 *ngIf="job.benefits">Benefits</h3>
                                    <div class="row job-details" *ngIf="job.benefits">
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.skillTraining">
                                            <i class="la la-tencent-weibo"></i>
                                            <h3>Soft Skill Training</h3>
                                        </div>
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.jobTraining">
                                            <i class="la la-black-tie"></i>
                                            <h3>Job Training</h3>
                                        </div>
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.cafeteria">
                                            <i class="la la-coffee"></i>
                                            <h3>Cafeteria</h3>
                                        </div>
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.healthInsurance">
                                            <i class="la la-medkit"></i>
                                            <h3>Health Insurance</h3>
                                        </div>
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.teamOutings">
                                            <i class="la la-photo"></i>
                                            <h3>Team Outings</h3>
                                        </div>
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.gymnasium">
                                            <i class="la la-odnoklassniki"></i>
                                            <h3>Gymnasium</h3>
                                        </div>
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.educationAssistance">
                                            <i class="la la-mortar-board"></i>
                                            <h3>Education Assistance</h3>
                                        </div>
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.freeTransport">
                                            <i class="la la-car"></i>
                                            <h3>Free Transport</h3>
                                        </div>
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.childCare">
                                            <i class="la la-child"></i>
                                            <h3>Child Care</h3>
                                        </div>
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.workFromHome">
                                            <i class="la la-home"></i>
                                            <h3>Work From Home</h3>
                                        </div>
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.internationalRelocation">
                                            <i class="la la-fighter-jet"></i>
                                            <h3>International Relocation</h3>
                                        </div>
                                        <div class="benifits-icon col-sm-6 col-lg-3" *ngIf="job.benefits.freeFood">
                                            <i class="la la-cutlery"></i>
                                            <h3>Free Food</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 column">
                            <span class="d-none d-md-block" *ngIf="isLoggedIn && !isUserActive" style="background-color: #910B08; color: white; padding: 20px; border-radius: 10px; float: left; width: 100%; text-align: center; font-size: 15px; font-family: Open Sans; font-weight: bold; margin-bottom: 5px;">
                                Kindly verify email to apply a job!!!
                            </span>
                            <span class="d-none d-md-block" *ngIf="isLoggedIn && !isProfileUpdated" style="background-color: #910B08; color: white; padding: 20px; border-radius: 10px; float: left; width: 100%; text-align: center; font-size: 15px; font-family: Open Sans; font-weight: bold;">
                                Kindly update your profile to apply a job!!!
                            </span>
                            <span class="d-none d-md-block" *ngIf="!isLoggedIn">
                                <a class="signin-popup apply-thisjob" title=""><i class="la la-paper-plane"></i>Log in
                                    to apply for job</a>
                            </span>

                            <span class="d-none d-md-block" *ngIf="isLoggedIn && isUserActive && isProfileUpdated">
                                <a *ngIf="!isApplied" class="apply-thisjob" (click)="apply()" title=""
                                    [class.disabled]="isApplied ? true : null"><i class="la la-paper-plane"></i>Apply
                                    for job</a>
                                <a *ngIf="isApplied" class="apply-thisjob" (click)="apply()" title=""
                                    [class.disabled]="isApplied ? true : null"><i class="la la-paper-plane"></i>Already
                                    applied the job</a>
                            </span>

                            <div class="d-none d-md-block" class="apply-alternative">
                                <span *ngIf="isLoggedIn && !isApplied" (click)="shortlistJob()" [class.disabled]="isShortlisted ? true : null"><i class="la la-heart-o"></i>
                                        Shortlist</span>
                                <span *ngIf="isLoggedIn && isShortlisted" (click)="shortlistJob()" [class.disabled]="isShortlisted ? true : null"><i class="la la-heart-o"></i>
                                        Already shortlisted</span>
                            </div>
                            <!-- <div id="share">

                                <!-- facebook --
                                <a class="facebook" href="https://www.facebook.com/share.php?u={{url}}&title={{job.title}}" target="blank"><i class="la la-facebook"></i></a>

                                <!-- twitter --
                                <a class="twitter" href="https://twitter.com/intent/tweet?status={{title}}+{{url}}" target="blank"><i class="la la-twitter"></i></a>

                                <!-- google plus --
                                <a class="whatsapp" href="https://web.whatsapp.com/" target="_blank"><i
                                            class="la la-whatsapp"></i></a>


                                <!-- linkedin --
                                <a class="linkedin" href="https://www.linkedin.com/shareArticle?mini=true&url={{url}}&title={{title}}&source={{source}}" target="blank"><i class="la la-linkedin"></i></a>

                               

                            </div> -->


                            <div class="job-overview">
                                <h3>Job Overview</h3>
                                <ul>
                                    <li><i class="la la-money"></i>
                                        <h3>Offerd Salary</h3>
                                        <span *ngIf="job.offerdSalary != 'Do not wish to disclose'" style="width: auto;">{{job.currencyType}}&nbsp;</span>
                                        <span style="width: auto;">{{job.offerdSalary}}</span>
                                        <span *ngIf="job.offerdSalary != 'Do not wish to disclose'" style="width: auto;">&nbsp;per
                                            annum</span> <span *ngIf="job.negotiable">(negotiable)</span>
                                    </li>
                                    <!-- <li><i class="la la-mars-double"></i>
                                            <h3>Gender</h3><span>Female</span></li> -->
                                    <li><i class="la la-thumb-tack"></i>
                                        <h3>Career Level</h3><span>{{job.careerLevel}}</span>
                                    </li>
                                    <li><i class="la la-puzzle-piece"></i>
                                        <h3>Industry</h3><span>{{job.industry}}</span>
                                    </li>
                                    <li><i class="la la-shield"></i>
                                        <h3>Experience</h3><span>{{job.experience}} years</span>
                                    </li>
                                    <li><i class="la la-line-chart "></i>
                                        <h3>Qualification</h3><span>{{job.qualification}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div *ngIf="job.youtubeLink" style="float: left; width: 100%; margin-top: 30px;">
                                <iframe width="420" height="315" [src]="transform(job.youtubeLink)">
                                </iframe>
                            </div>
                            <div class="job-overview">
                                <h3>Address</h3>
                                <ul>
                                    <li>
                                        <i class="la la-building"></i>
                                        <h3>Address</h3>
                                        <span>{{job.address}}</span>
                                    </li>
                                    <li>
                                        <qr-code [value]="job.address"></qr-code>
                                    </li>
                                </ul>
                            </div>
                            <br>
                            <span *ngIf="isLoggedIn && isUserActive && isProfileUpdated">
                                <a *ngIf="!isApplied" class="apply-thisjob" (click)="apply()" title=""
                                    [class.disabled]="isApplied ? true : null"><i class="la la-paper-plane"></i>Apply
                                    for job</a>
                            </span>
                            <span *ngIf="!isLoggedIn">
                                <a class="signin-popup apply-thisjob" title=""><i class="la la-paper-plane"></i>Log in
                                    to apply for job</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>


</html>