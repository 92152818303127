<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_list1.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:39 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Job Hunt</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Employer</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget border">
                                <a href="employers_list" title=""><i class="la la-trash-o"></i> Clear filter</a>
                            </div>
                            <div class="widget">
                                <div class="search_widget_job">
                                    <div class="field_w_search">
                                        <input type="text" placeholder="Search Keywords" name="searchTitle" [(ngModel)]="searchTitle" (keyup.enter)="filter()" />
                                        <i class="la la-search"></i>
                                    </div>
                                    <!-- Search Widget -->
                                    <div class="field_w_search">
                                        <input type="text" placeholder="All Locations" name="searchCity" [(ngModel)]="searchCity" (keyup.enter)="filter()" />
                                        <i class="la la-map-marker"></i>
                                    </div>
                                    <!-- Search Widget -->
                                </div>
                            </div>
                            <div class="widget">
                                <h3 class="sb-title open">Specialism</h3>
                                <div class="specialism_widget">
                                    <div class="simple-checkbox">
                                        <p><input type="checkbox" name="spealism" id="as"><label for="as">Accountancy (2)</label></p>
                                        <p><input type="checkbox" name="spealism" id="asd"><label for="asd">Banking (2)</label></p>
                                        <p><input type="checkbox" name="spealism" id="errwe"><label for="errwe">Charity & Voluntary (3)</label></p>
                                        <p><input type="checkbox" name="spealism" id="fdg"><label for="fdg">Digital & Creative (4)</label></p>
                                        <p><input type="checkbox" name="spealism" id="sc"><label for="sc">Estate Agency (3)</label></p>
                                        <p><input type="checkbox" name="spealism" id="aw"><label for="aw">Graduate (2)</label></p>
                                        <p><input type="checkbox" name="spealism" id="ui"><label for="ui">IT Contractor (7)</label></p>
                                    </div>
                                </div>
                            </div>

                        </aside>
                        <div class="col-lg-9 column">
                            <div class="emply-list-sec" *ngFor="let company of displayCompanies; let i = index">
                                <a href="employer_details/{{company._id}}" title="">
                                    <div class="emply-list">
                                        <div class="emply-list-thumb">
                                            <a href="employer_details/{{company._id}}" title=""><img src="{{apiURL}}{{company.imageUrl}}" onerror="this.src='assets/images/resource/employer.png';" alt="" style="width: 100px; height: 100px;" /></a>
                                        </div>
                                        <div class="emply-list-info">
                                            <!-- <div class="emply-pstn">4 Open Position</div> -->
                                            <h3><a href="employer_details/{{company._id}}" title="">{{company.name}}</a></h3>
                                            <span>{{company.industry}}</span>
                                            <h6><i class="la la-map-marker"></i> {{company.city}}, {{company.country}}</h6>
                                            <!-- <p>The Heavy Equipment / Grader Operator is responsible for operating one or several types construction equipment, such as front end loader, roller, bulldozer, or excavator to move,…</p> -->
                                        </div>
                                    </div>
                                </a>
                                <!-- Employe List -->

                                <!-- <div class="pagination">
								<ul>
									<li class="prev"><a href="#"><i class="la la-long-arrow-left"></i> Prev</a></li>
									<li><a href="#">1</a></li>
									<li class="active"><a href="#">2</a></li>
									<li><a href="#">3</a></li>
									<li><span class="delimeter">...</span></li>
									<li><a href="#">14</a></li>
									<li class="next"><a href="#">Next <i class="la la-long-arrow-right"></i></a></li>
								</ul>
                            </div> -->
                                <!-- Pagination -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_list1.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:42 GMT -->

</html>