<!DOCTYPE html>
<html>


<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>C2C</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">
</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section>
            <div class="block  gray">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner2">
                                <div class="inner-title2">
                                    <h3>Terms and Conditions</h3>
                                    <!-- <span>Keep up to date with the latest news</span> -->
                                </div>
                                <!-- <div class="page-breacrumbs">
								<ul class="breadcrumbs">
									<li><a href="#" title="">Home</a></li>
									<li><a href="#" title="">Pages</a></li>
									<li><a href="#" title="">About Us</a></li>
								</ul>
							</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="about-us">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h3 style="text-decoration: underline;">Terms and Conditions</h3>
                                    </div>
                                    <div class="col-lg-12">
                                        <p><b>Privacy</b> Reminder - Protect your personal information by never providing credit card, bank account numbers or any other personal information open to misuse, to prospective employers.</p>
                                        <p><u><b>Terms of Use</b></u> This page states the “Terms of Use” under which you may use Click-to-Careers, an on-line service to post and search employment opportunities and user resumes.</p>

                                        <p>
                                            • The domain name clicktocareers.com (hereinafter referred to as “the Website”) is owned by <b>Resources Career Portal Private Limited</b>, a company incorporated under the Companies Act, 1956.
                                        </p>

                                        <p>
                                            • Your use of the Website and services and tools are governed by the following terms and conditions including the applicable policies which are incorporated herein by way of reference. If you transact on the Website, you shall be subject to the policies
                                            that are applicable to the Website for such transaction. By mere use of the Website, you shall be contracting with <b>Resources Career Portal Private Limited</b> and
                                            these terms and conditions including the policies constitute your binding obligations.
                                        </p>
                                        <p>
                                            • For the purpose of these Terms of Use, wherever the context so requires “you” or “user” shall mean any natural or legal person who has agreed to become a user of the Website by providing Registration Data while registering on the Website. The term “we”,
                                            “us”, “our” shall mean
                                            <b>Resources Career Portal Private Limited</b>.
                                        </p>
                                        <p>
                                            • We reserve the right, at our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to you. It is your responsibility to review these Terms of Use periodically for updates / changes.
                                            Your continued use of the Website following the posting of changes will mean that you accept and agree to the revisions. As long as you comply with these Terms of Use, we grant you a personal, non-exclusive,
                                            non- transferable, limited privilege to enter and use the Website.
                                        </p>
                                        <p>
                                            • We reserve the right, at our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to you. It is your responsibility to review these Terms of Use periodically for updates / changes.
                                            Your continued use of the Website following the posting of changes will mean that you accept and agree to the revisions. As long as you comply with these Terms of Use, we grant you a personal, non-exclusive,
                                            non- transferable, limited privilege to enter and use the Website.
                                        </p>
                                    </div>
                                    <div class="col-lg-12">
                                        <h3 style="text-decoration: underline;">Membership Eligibility</h3>
                                    </div>
                                    <div class="col-lg-12">
                                        <p>Use of the Website is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872
                                            including minors, un-discharged insolvents etc. are not eligible to use the Website. If you are a minor i.e. under the age of 18 years, you shall not register as a User of the Website and shall not transact
                                            on or use the Website. As a minor if you wish to use or transact on the Website, such use or transaction may be made by your legal guardian or parents on the Website. We reserve the right to terminate your membership
                                            and / or refuse to provide you with access to the Website if it is brought to our notice or if it is discovered that you are under the age of 18 years.</p>
                                    </div>
                                    <div class="col-lg-12">
                                        <h3 style="text-decoration: underline;">Your Account and Registration Obligations</h3>
                                    </div>
                                    <div class="col-lg-12">
                                        <p>If you use the Website, you shall be responsible for maintaining the confidentiality of your Display Name and Password and you shall be responsible for all activities that occur thereunder. You agree that if you
                                            provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance
                                            with the Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of your membership on the Website and refuse to provide You with access to the Website. Such act on your part
                                            can accrue legal implications due to violation of laws not limited to criminal liability on your part.</p>
                                        <p>Further, the User agrees to indemnify <b>Resources Career Portal Private Limited</b> for all losses incurred by it due to any false, distorted, manipulated, defamatory, libellous, vulgar, obscene, fraudulent or
                                            misleading facts or otherwise objectionable averments made by the user on the Website.
                                        </p>
                                    </div>

                                    <div class="col-lg-12">
                                        <h3 style="text-decoration: underline;">Communications </h3>
                                    </div>
                                    <div class="col-lg-12">
                                        <p>When you use the Website or send emails or provide other data, information or communication to us, you agree and understand that you are communicating with us through electronic records and you consent to receive
                                            communications via electronic records from us periodically as and when required. We may communicate with you by email or by such other mode of communication; electronic or otherwise.</p>
                                    </div>

                                    <div class="col-lg-12">
                                        <h3 style="text-decoration: underline;">Use of the Website </h3>
                                    </div>
                                    <div class="col-lg-12">
                                        <p>You agree, undertake and confirm that your use of the Website shall be strictly governed by the following binding principles:</p>
                                        <p>• You shall not host, display, upload, modify, publish, transmit, update or share any information which belongs to another person and to which you do not have any right to;</p>
                                        <p>• Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging
                                            money laundering or gambling, or otherwise unlawful in any manner whatsoever;</p>
                                        <p>• Is misleading in any way-Is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, pedophilia, racism, bigotry, hatred or physical harm of any kind against
                                            any group or individual;</p>
                                        <p>• Harasses or advocates harassment of another person;</p>
                                        <p>• Involves the transmission of “junk mail”, “chain letters”, or unsolicited mass mailing or “spamming”;
                                        </p>
                                        <p>• Promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;
                                        </p>
                                        <p>• Infringes upon or violates any third party’s rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation, unauthorized disclosure of a person’s name, email
                                            address, physical address or phone number) or rights of publicity];</p>
                                        <p>• Engages in commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of “virtual” products related to
                                            the Website.</p>
                                        <p>• Interferes with another user’s use and enjoyment of the Website or similar services;   §  Infringes any patent, trademark, copyright or other proprietary rights or third party’s trade secrets or rights of publicity
                                            or privacy or shall not be fraudulent;</p>
                                        <p>• Violates any law for the time being in force;</p>
                                    </div>

                                    <div class="col-lg-12">
                                        <h3 style="text-decoration: underline;">Consent to Use of Data </h3>
                                    </div>
                                    <div class="col-lg-12">
                                        <p>You agree that the Website can collect certain personal information about you such as your IP address, etc. and can automatically store it in its database. However, if you register yourself on the Website, you shall
                                            be required to provide certain personal information for the registration and/or access the web pages. Such information may include, without limitation, your name, email address, contact address, mobile/telephone
                                            number(s), sex, age, occupation, interests, credit card information, billing information, financial information, content, IP address, standard web log information, information about your computer hardware and
                                            software and such other information as may be required for your interaction with the services and from which your identity is discernible. We may also collect demographic information that is not unique to you
                                            such as code, preferences, favorites, etc. Further, sometimes you may be asked to provide descriptive, cultural, preferential and social &amp; life style information.</p>
                                    </div>

                                    <div class="col-lg-12">
                                        <h3 style="text-decoration: underline;">Disclaimer of Warranties and Liability</h3>
                                    </div>
                                    <div class="col-lg-12">
                                        <p>This Website, all the materials and products (including but not limited to software) and services, included on or otherwise made available to you through this site are provided on “as is” and “as available” basis
                                            without any representation or warranties, express or implied except otherwise specified in writing. Without prejudice to the forgoing paragraph, we do not warrant that:</p>
                                        <p>• This Website will be constantly available, or available at all; or</p>
                                        <p>• The information on this Website is complete, true, accurate or non-misleading.</p>
                                        <p>• The Website is a public site with free access and we assume no liability for the quality and genuineness of responses. We cannot monitor the responses that a person may receive in response to information he/she
                                            has displayed on the Website. The individual/company would have to conduct its own background checks on the bonafide nature of all response(s)</p>
                                    </div>

                                    <div class="col-lg-12">
                                        <h3 style="text-decoration: underline;">Indemnity </h3>
                                    </div>
                                    <div class="col-lg-12">
                                        <p>
                                            You shall indemnify and hold harmless <b>Resources Career Portal Private Limited</b>, its owner, licensee, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors,
                                            agents, and employees, from any claim or demand, or actions including reasonable attorneys’ fees, made by any third party or penalty imposed due to or arising out of Your breach of this Terms of Use, privacy
                                            Policy and other Policies, or Your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.</p>
                                    </div>
                                    <div class="col-lg-12">
                                        <h3 style="text-decoration: underline;">Applicable Law</h3>
                                    </div>
                                    <div class="col-lg-12">
                                        <p>Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall be exclusively in Bangalore, Karnataka.</p>
                                        <p style="font-weight: bolder;">These “Terms of Use” of the Website constitute a binding agreement between you and the Website, and is accepted by you upon your use of the Website.</p>
                                    </div>

                                </div>
                                <!-- <div class="tags-share">
						 		<div class="share-bar">
					 				<a href="#" title="" class="share-fb"><i class="fa fa-facebook"></i></a><a href="#" title="" class="share-twitter"><i class="fa fa-twitter"></i></a><a href="#" title="" class="share-google"><i class="la la-google"></i></a><span>Share</span>
					 			</div>
				 			</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/about.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:40 GMT -->

</html>