import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-constants';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/_services/company.service';


@Component({
  selector: 'app-employers-list',
  templateUrl: './employers-list.component.html',
  styleUrls: ['./employers-list.component.css']
})
export class EmployersListComponent implements OnInit {

  companies: Company[];
  displayCompanies: Company[];
  searchTitle: string;
  searchCity: string;
  apiURL = GlobalConstants.apiURL;

  constructor(private companyService: CompanyService) { }

  ngOnInit(): void {
    this.companyService.getAllCompanies().subscribe(
      data => {
        this.companies = data;
        this.displayCompanies = data;
        console.log(this.companies);
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );  
  }
  filter() {

    //  title filter
    if (this.searchTitle) {
      var temp = this.companies;
      this.displayCompanies = [];
      temp.forEach(element => {
        if (element.name) {
          if (element.name.toLowerCase().includes(this.searchTitle.toLowerCase())) {
            this.displayCompanies.push(element);
          }            
        }
      });
    } 
    else {
      this.displayCompanies = this.companies;
    }

    //  city filter
    if (this.searchCity) {
      var temp = this.displayCompanies;
      this.displayCompanies = [];
      temp.forEach(element => {
        if(element.city) {
          if (element.city.toLowerCase().includes(this.searchCity.toLowerCase())) {
            this.displayCompanies.push(element);
          }
        }
      });
    }
  }

}
