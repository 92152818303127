<!DOCTYPE html>
<html>



<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Click to careers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section>
            <div class="block gray">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner2">
                                <div class="inner-title2">
                                    <h3>Pricing</h3>
                                    <span>Keep up to date with the latest Offers</span>
                                </div>
                                <div class="page-breacrumbs">
                                    <!-- <ul class="breadcrumbs">
                                        <li><a href="#" title="">Home</a></li>
                                        <li><a href="#" title="">Pages</a></li>
                                        <li><a href="#" title="">Pricing</a></li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="heading">
                                <h2>Buy Our Employers Plans And Packages</h2>
                                <span>One of our jobs has some kind of flexibility option - such as telecommuting, a part-time schedule or a flexible or flextime schedule.</span>
                            </div>
                            <!-- Heading -->
                            <div class="plans-sec">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="pricetable">
                                            <div class="pricetable-head">
                                                <h3>Basic</h3>
                                                <h2><i></i>Free</h2>
                                                <!-- <span>15 Days</span> -->
                                            </div>
                                            <!-- Price Table -->
                                            <ul>
                                                <li>1 x Job post</li>
                                                <li>Conduct Video Interviews through Click to Careers platform</li>
                                                <li>Conduct written test through Click to Careers platform</li>
                                                <li>Logo Branding</li>
                                            </ul>
                                            <!-- <a href="#" title="">BUY NOW</a> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="pricetable active">
                                            <div class="pricetable-head">
                                                <h3>Standard(Recommended)</h3>
                                                <h2><i></i>Free</h2>
                                                <!-- <span>20 Days</span> -->
                                            </div>
                                            <!-- Price Table -->
                                            <ul>
                                                <li>3 x Job post</li>
                                                <li>Conduct Video Interview through Click to Careers platform</li>
                                                <li>Conduct written test through Click to Careers platform</li>
                                                <li>Logo Branding</li>
                                                <li>Cover Page branding</li>
                                            </ul>
                                            <!-- <a href="#" title="">BUY NOW</a> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="pricetable">
                                            <div class="pricetable-head">
                                                <h3>Premium</h3>
                                                <h2><i></i>Free</h2>
                                                <!-- <span>2 Month</span> -->
                                            </div>
                                            <!-- Price Table -->
                                            <ul>
                                                <li>5 x Job post</li>
                                                <li>Conduct Video Interview through Click to Careers platform</li>
                                                <li>Conduct written test through Click to Careers platform</li>
                                                <li>Feature your job posting</li>
                                                <li>Logo Branding</li>
                                                <li>Cover Page branding</li>
                                                <li>Video and Map interaction</li>
                                            </ul>
                                            <!-- <a href="#" title="">BUY NOW</a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <table class="pkges-table" style="margin: auto; width: 90%; margin-top: 30px;">
                            <thead>
                                <tr>
                                    <td>Features</td>
                                    <td class="text-center">Basic </td>
                                    <td class="text-center">Standard(Recommended)</td>
                                    <td class="text-center">Premium</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>30 day listing </h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Job Post</h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span>1</span>
                                    </td>
                                    <td class="text-center">
                                        <span>3</span>
                                    </td>
                                    <td class="text-center">
                                        <span>5</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Featured Jobs </h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span></span>
                                    </td>
                                    <td class="text-center">
                                        <span></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Detailed Job description </h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Logo Branding </h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Cover Photo</h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Branding through video</h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span></span>
                                    </td>
                                    <td class="text-center">
                                        <span></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Map interaction </h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span></span>
                                    </td>
                                    <td class="text-center">
                                        <span></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>View all applicants</h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Contact unlock</h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span>10</span>
                                    </td>
                                    <td class="text-center">
                                        <span>15</span>
                                    </td>
                                    <td class="text-center">
                                        <span>20</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Download Resume </h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span>10</span>
                                    </td>
                                    <td class="text-center">
                                        <span>15</span>
                                    </td>
                                    <td class="text-center">
                                        <span>20</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Shortlist Candidate</h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                    <td class="text-center">
                                        <span><i class="la la-check"></i></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Download Video Resume</h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span>1</span>
                                    </td>
                                    <td class="text-center">
                                        <span>10</span>
                                    </td>
                                    <td class="text-center">
                                        <span>20</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Conduct Video Interview</h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span>1</span>
                                    </td>
                                    <td class="text-center">
                                        <span>7</span>
                                    </td>
                                    <td class="text-center">
                                        <span>15</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="table-list-title">
                                            <h3>Conduct written test</h3>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span>1</span>
                                    </td>
                                    <td class="text-center">
                                        <span>7</span>
                                    </td>
                                    <td class="text-center">
                                        <span>15</span>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>

        
    </div>

</body>



</html>