<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/c2c-html/candidates_single2.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:11 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Click to careers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">


        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-6">

                                        </div>
                                        <div class="col-lg-6">
                                            <div class="action-inner style2">
                                                <div class="download-cv">
                                                    <a href="{{resumeUrl}}" target="_blank" download *ngIf="resumeUrl">Download CV <i class="la la-download"></i></a>
                                                    <a href="resume_template/{{user._id}}" target="_blank" *ngIf="user.profileUpdated">View Resume Template <i class="la la-eye"></i></a>
                                                </div>
                                                <!-- <a href="#" title=""><i class="la la-map-marker"></i>Istanbul / Turkey</a> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="overlape">
            <div class="block remove-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="cand-single-user">
                                <div class="share-bar circle"></div>
                                <div class="can-detail-s">
                                    <div class="cst"><img [src]="url" onerror="this.src='assets/images/resource/employee.png';" alt="" style="width: 137px; height: 137px;" /></div>
                                    <h3>{{user.firstName}} {{user.lastName}} </h3>
                                    <span><i>{{user.jobTitle}}</i></span>
                                    <p><a href="https://grandetest.com/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="0360716662776a75666f627a667170333b3b43646e626a6f2d606c6e">[email&#160;protected]</a></p>
                                    <p>Member Since {{convert(user.createdOn)}}</p>
                                    <p><i class="la la-map-marker"></i>{{user.city}} / {{user.state}} / {{user.country}}</p>
                                    <div class="skills-badge">
                                        <span *ngFor="let skill of user.skills; let i = index">{{skill}}</span>
                                    </div>
                                </div>
                                <div class="download-cv">
                                    <div *ngIf="user.videoUrl && user.showVideoResume">
                                        <video [src]="videoResumeurl" controls #videoPlayer style="width: 290px;">
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <div class="cand-details-sec">
                                <div class="row no-gape">
                                    <div class="col-lg-12 column">
                                        <div class="cand-details">
                                            <div class="job-overview style2">
                                                <ul>
                                                    <!-- <li><i class="la la-money"></i>
                                                        <h3>Offerd Salary</h3><span>{{user.expectedSalary}}</span></li> -->
                                                    <li><i class="la la-mars-double"></i>
                                                        <h3>Gender</h3><span>{{user.gender}}</span></li>
                                                    <li><i class="la la-thumb-tack"></i>
                                                        <h3>Current Job Title</h3><span>{{user.jobTitle}}</span></li>
                                                    <li><i class="la la-shield"></i>
                                                        <h3>Experience</h3><span>{{user.experienceYear}} Years</span></li>
                                                    <li><i class="la la-line-chart "></i>
                                                        <h3>Qualification</h3><span>{{user.education}}</span></li>
                                                    <li><i class="la la-puzzle-piece"></i>
                                                        <h3>Languages</h3><span>{{user.languages}} </span></li>
                                                </ul>
                                            </div>
                                            <!-- Job Overview -->
                                            <h2 *ngIf="user.description">Candidates About</h2>
                                            <p *ngIf="user.description" style="white-space: pre-wrap;">{{user.description}}</p>
                                            <div class="edu-history-sec" *ngIf="user.educationList.length != 0">
                                                <h2>Education</h2>
                                                <div class="edu-history" *ngFor="let education of user.educationList; let i = index">
                                                    <i class="la la-graduation-cap"></i>
                                                    <div class="edu-hisinfo">
                                                        <h3>{{education.educationLevel}}</h3>
                                                        <i>{{education.startYear}} - {{education.endYear}} <span *ngIf="education.isStudying">(Still in progress)</span></i>
                                                        <span>{{education.institute}}<i  style="white-space: pre-wrap;">{{education.stream}}</i></span>
                                                        <p style="white-space: pre-wrap;">{{education.highlight}}</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="edu-history-sec" *ngIf="user.workList.length != 0">
                                                <h2>Work & Experience</h2>
                                                <div class="edu-history style2" *ngFor="let work of user.workList; let i = index">
                                                    <i></i>
                                                    <div class="edu-hisinfo">
                                                        <h3>{{work.designation}} at<span style="white-space: pre-wrap;">{{work.company}}</span></h3>
                                                        <i>{{work.startYear}} - {{work.endYear}} <span *ngIf="work.isWorking">(Notice Period: {{work.noticePeriod}})</span></i>
                                                        <p style="white-space: pre-wrap;">{{work.experience}}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="edu-history-sec" *ngIf="user.skillList.length != 0">
                                                <h2>Licences & Certifications</h2>
                                                <div class="edu-history style2" *ngFor="let skill of user.skillList; let i = index">
                                                    <i></i>
                                                    <div class="edu-hisinfo">
                                                        <h3>{{skill.title}}<span *ngIf="skill.organisation">at {{skill.organisation}}</span></h3>
                                                        <i *ngIf="skill.startYear || skill.startMonth">Issued on: {{skill.startMonth}}, {{skill.startYear}}</i>
                                                        <i *ngIf="skill.endYear || skill.endMonth">Expire at: {{skill.endMonth}}, {{skill.endYear}}</i>
                                                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a ipsum tellus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p> -->
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </div>

</body>

<!-- Mirrored from grandetest.com/theme/c2c-html/candidates_single2.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:11 GMT -->

</html>