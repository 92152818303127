import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-constants';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-user-signup-report',
  templateUrl: './user-signup-report.component.html',
  styleUrls: ['./user-signup-report.component.css']
})
export class UserSignupReportComponent implements OnInit {

  users: User[] = [];
  displayUsers: User[] = [];
  employeeList: User[] = [];
  employerList: User[] = [];
  isEmployee: boolean;
  isEmployer: boolean;
  apiURL = GlobalConstants.apiURL;
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.isEmployee = true;
    this.isEmployer = false;
    this.userService.getUsersReport().subscribe(
      data => {
        this.users = data;
        this.users.forEach(user => {
            user.roles.forEach(role => {
              if (role.name == "employer" || role.name == "sub-employer") {
                this.employerList.push(user);
              } else {
                this.employeeList.push(user);
              }
              this.displayUsers = this.employeeList;
            });
        });

      },
      err => {
        alert(JSON.parse(err.error).message);
      }
    );
  }

  showEmployer(){
    this.isEmployee = false;
    this.isEmployer = true;
    this.displayUsers = this.employerList;
  }

  showEmployee() {
    this.isEmployee = true;
    this.isEmployer = false;
    this.displayUsers = this.employeeList;
  }

}
