<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_change_password.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:54 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Job Hunt</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="employer_profile" title=""><i class="la la-file-text"></i>Company Profile</a></li>
                                        <li><a href="employer_manage_jobs" title=""><i class="la la-briefcase"></i>Manage Jobs</a><span class="badge" *ngIf="isJobAlert"><i class='la la-bell'></i></span></li>
                                        <li><a href="candidates_list" title=""><i class="la la-flash"></i>Talent Search</a></li>
                                        <!-- <li><a href="employer_transactions" title=""><i class="la la-money"></i>Transactions</a></li> -->
                                        <!-- <li><a href="employer_resume.html" title=""><i class="la la-paper-plane"></i>Resumes</a></li> -->
                                        <!-- <li><a href="employer_packages.html" title=""><i class="la la-user"></i>Packages</a></li> -->
                                        <li><a href="employer_post_job" title=""><i class="la la-file-text"></i>Post a New Job</a></li>
                                        <li><a href="employer_password_reset" title=""><i class="la la-lock"></i>Change Password</a></li>
                                        <li><a href="employer_manage_users" title=""><i class="la la-users"></i>Manage Employers</a></li>
                                        <li><a href="employer_create_users" title=""><i class="la la-user"></i>Create Employers</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="manage-jobs-sec">
                                    <h3>Create new employer</h3>
                                    <div class="change-password">
                                        <!-- <div class="alert alert-danger" role="alert" *ngIf="isError">
                                            Password Mismatch!
                                        </div> -->
                                        <form name="form" (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <span class="pf-title">First Name</span>
                                                    <div class="pf-field">
                                                        <input type="text" name="firstName" [(ngModel)]="form.firstName" required #firstName="ngModel" placeholder="First Name" />
                                                    </div>
                                                    <span class="pf-title">Last Name</span>
                                                    <div class="pf-field">
                                                        <input type="text" name="lastName" [(ngModel)]="form.lastName" required #lastName="ngModel" placeholder="Last Name" />
                                                    </div>

                                                    <span class="pf-title">Role</span>
                                                    <div class="pf-field">
                                                        <select class="chosen-dropdown" name="Role" [(ngModel)]="form.roles">
													    <option value="employer">Employer</option>
													    <option value="sub-employer">Sub Employer</option>
												    </select>
                                                    </div>

                                                    <span class="pf-title">Password</span>
                                                    <div class="pf-field">
                                                        <input type="password" name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel" placeholder="********" />
                                                    </div>

                                                    <span class="pf-title">Email</span>
                                                    <div class="pf-field">
                                                        <input type="email" name="email" [(ngModel)]="form.email" required email #email="ngModel" placeholder="Email" />
                                                    </div>
                                                    <span class="pf-title">Phone Number</span>
                                                    <div class="pf-field">
                                                        <input type="text" name="phone" [(ngModel)]="form.phone" required #phone="ngModel" placeholder="Phone Number" />
                                                    </div>
                                                    <button type="submit">Create Account</button>
                                                </div>
                                                <div class="col-lg-6">
                                                    <i class="la la-key big-icon"></i>
                                                </div>
                                            </div>
                                        </form>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_change_password.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:54 GMT -->

</html>