import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import * as RecordRTC from 'recordrtc/RecordRTC.min';
import { GlobalConstants } from 'src/app/global-constants';
import { User } from 'src/app/models/user';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-upload-resume',
  templateUrl: './upload-resume.component.html',
  styleUrls: ['./upload-resume.component.css']
})
export class UploadResumeComponent implements OnInit, AfterViewInit {

  user: User;
  private stream: MediaStream;
  private recordRTC: any;
  RecordRTC = RecordRTC;
  @ViewChild('videoPlayer') video;
  isStart: boolean = false;
  videoResumeurl: string;
  showResume: boolean = false;
  isExamAlert: boolean = false;

  constructor(private userService: UserService, private tokenStorageService: TokenStorageService) {
    // Do stuff
  }

  ngOnInit(): void {
    document.getElementById("startTest").style.display = "none";
    const currentUser = this.tokenStorageService.getUser();
    this.isExamAlert = currentUser.examAlert;
    this.userService.getByUsername(currentUser.email).subscribe(
      data => {
        this.user = data;
        if (this.user.showVideoResume) {
          this.showResume = this.user.showVideoResume;
        }
        this.videoResumeurl = GlobalConstants.apiURL + data.videoUrl;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  ngAfterViewInit() {
    // set the initial state of the video
    let video:HTMLVideoElement = this.video.nativeElement;
    video.muted = false;
    video.controls = true;
    video.autoplay = false;
  }

  toggleControls() {
    let video: HTMLVideoElement = this.video.nativeElement;
    video.muted = !video.muted;
    // video.controls = !video.controls;
    video.autoplay = !video.autoplay;
  }

  successCallback(stream: MediaStream) {

    var options = {
      mimeType: 'video/webm;codecs=vp9', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 128000,
      bitsPerSecond: 128000 // if this line is provided, skip above two
    };
    this.stream = stream;
    this.recordRTC = RecordRTC(stream, options);
    this.recordRTC.startRecording();
    let video: HTMLVideoElement = this.video.nativeElement;
    video.srcObject = stream;
    // video.src = window.URL.createObjectURL(stream);
    this.toggleControls();
  }

  errorCallback() {
    //handle error here
  }

  processVideo(audioVideoWebMURL) {
    let video: HTMLVideoElement = this.video.nativeElement;
    let recordRTC = this.recordRTC;
    video.src = audioVideoWebMURL;
    this.toggleControls();
    var recordedBlob = recordRTC.getBlob();
    recordRTC.getDataURL(function (dataURL) { });
    this.download();
  }

  startRecording() {
    let mediaConstraints = {
      video: true,
      audio: true
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  stopRecording() {
    let recordRTC = this.recordRTC;
    recordRTC.stopRecording(this.processVideo.bind(this));
    let stream = this.stream;
    stream.getAudioTracks().forEach(track => track.stop());
    stream.getVideoTracks().forEach(track => track.stop());
  }

  download() {
    // this.recordRTC.save('video.webm');
    this.updateVideo();
  }

  updateVideo() {
      const uploadData = new FormData();
      var filename = this.user._id + ".webm" ;
      uploadData.append('filename', filename);
      uploadData.append('file', this.recordRTC.blob);
      this.userService.uploadVideoResume(uploadData).subscribe(
        data => {
          this.userService.updateVideoResumeUrl(this.user._id).subscribe(
            data => {
              alert("Successfully uploaded");
              window.location.reload();
            },
            err => {
              alert("error");
            }
          );
        },
        err => {
         alert(JSON.parse(err.error).message);
        }
      );
    }

    startVideo() {
    document.getElementById("startTest").style.display = "block";
    this.isStart = true;
    }

    displayVideoResume(toggle) {
      this.user.showVideoResume = toggle;
      this.userService.showVideoResume(this.user._id, this.user.showVideoResume).subscribe(
        data => {
          this.user = data;
          if (this.user.showVideoResume) {
            alert("Your video resume is visible to employers!!");
          } else {
            alert("Your video resume is not visible to employers!!");
          }
          // window.location.reload();
        },
        err => {
          alert("error");
        }
      );
    }
}
