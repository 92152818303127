<!DOCTYPE html>
<html>



<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>click to careers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

    <script src="node_modules/tinymce/tinymce.min.js"></script>
</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="admin_manage_users" title=""><i class="la la-users"></i>Manage Active Users</a></li>
                                        <li><a href="admin_manage_deactivated_users" title=""><i class="la la-users"></i>Manage Deactivated Users</a></li>
                                        <li><a href="admin_manage_jobs" title=""><i class="la la-briefcase"></i>Manage Jobs</a></li>
                                        <li><a href="exam_list" title=""><i class="la la-file-text"></i>View All Exams</a></li>
                                        <li><a href="add_blog" title=""><i class="la la-file-text"></i>Add Blog</a></li>
                                        <li><a href="admin_blog_list" title=""><i class="la la-file-text"></i>Blog List</a></li>
                                        <li><a href="monthly_user_signup_report" title=""><i class="la la-file"></i>Monthly Signup Report</a></li>
                                        <li><a href="user_signup_report" title=""><i class="la la-file"></i>Signup Report</a></li>
                                        <li><a href="job_post_report" title=""><i class="la la-file"></i>Job Posted Report</a></li>
                                        <li><a href="job_detailed_report" title=""><i class="la la-file"></i>Job Detailed Report</a></li>
                                        <li><a href="admin_password_reset" title=""><i class="la la-lock"></i>Change Password</a></li>
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="profile-title">
                                    <h3>Update Blog</h3>
                                </div>
                                <div class="upload-img-bar">
                                    <span><img  [src]="blogUrl" alt="" /></span>
                                    <div class="upload-info">
                                        <input accept="image/png, image/jpeg" style="display: none" type="file" ng2FileSelect [uploader]="blogUploader" #fileInputBlog>
                                        <button class="btn btn-new btn-round" (click)="fileInputBlog.click()"><i class="fa fa-upload"></i>Upload Blog Image</button>
                                        <span>Max file size is 1MB, Minimum dimension: 1920x1000 And Suitable files are .jpg & .png</span>
                                    </div>
                                </div>
                                <div class="profile-form-edit">
                                    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <span class="pf-title">Blog Title<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <input type="text" name="title" [(ngModel)]="blog.title" placeholder="Blog Title" />
                                                    <div *ngIf="titleError" class="alert-danger">
                                                        Title is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <span class="pf-title">Content<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <editor apiKey="ramoqi9fmhn2io9a8fjs38yhxcf4ccddq2bti95a6xzpziww" [init]="{
                                                        branding: false,
                              suffix: '.min',      
                              height: 400,
                              menubar: false,
                              toolbar: false
                                                      }" name="content" [(ngModel)]="blog.content" placeholder="Blog Content"></editor>

                                                    <!-- <textarea name="content" [(ngModel)]="blog.content" placeholder="Blog Content"></textarea>
                                                    <div *ngIf="contentError" class="alert-danger">
                                                        Description is Mandatory.
                                                    </div> -->
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <button type="submit">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>



</html>