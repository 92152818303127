import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models/company';
import { Job } from 'src/app/models/job';
import { User } from 'src/app/models/user';
import { JobService } from 'src/app/_services/job.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-employer-manage-jobs',
  templateUrl: './employer-manage-jobs.component.html',
  styleUrls: ['./employer-manage-jobs.component.css']
})
export class EmployerManageJobsComponent implements OnInit {

  jobs: Job[];
  user: User;
  company: Company = new Company();
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(private userService: UserService, private jobService: JobService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    const currentUser = this.tokenStorageService.getUser();
    currentUser.jobAlert = false;
    const USER_KEY = 'auth-user';
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(currentUser));
    this.userService.getByUsername(currentUser.email).subscribe(
      data => {
        this.user = data;
        this.company = this.user.company;
        this.jobService.getJobsByCompany(this.company._id).subscribe(
          data => {
            this.jobs = data;
          },
          err => {
           alert(JSON.parse(err.error).message);
          }
        );
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );    
  }


  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }
}
