import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Company } from '../models/company';
import { Job } from '../models/job';
import { User } from '../models/user';
import { JobService } from '../_services/job.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';
import { GlobalConstants } from 'src/app/global-constants';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {

  job: Job;
  user: User;
  company: Company;
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  currentUser;
  isApplied: boolean = false;
  isShortlisted: boolean = false;
  apiUrl = GlobalConstants.apiURL;
  companyImg: string;
  bannerImg: string;
  postedBy: User;
  isLoggedIn: boolean = false;
  isUserActive: boolean = false;
  isResponsibility: boolean = false;
  isProfileUpdated: boolean = false;
  
  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute, private jobService: JobService, private userService: UserService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.jobService.getJobDetails(this.route.snapshot.params.id).subscribe(
      data => {
        this.job = data;
        this.postedBy = data.postedBy;
        if (this.job.responsibilities.length != 0) {
          this.isResponsibility = true;
        }
        this.company = data.postedBy.company;
        if (this.job.bannerUrl) {
          this.bannerImg = this.apiUrl + this.job.bannerUrl;
        }
        this.companyImg = this.apiUrl + data.company.imageUrl;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
    this.currentUser = this.tokenStorageService.getUser();
    if (this.currentUser) {
      this.isLoggedIn = true;
      this.userService.getByUsername(this.currentUser.email).subscribe(
        data => {
          this.user = data;
          this.isUserActive = this.user.isActive;
          this.isProfileUpdated = this.user.profileUpdated;
          if(this.user.shortlistedJobs) {
            this.user.shortlistedJobs.forEach(element => {
              if(element._id  == this.route.snapshot.params.id) {
                this.isShortlisted = true;
              }
            });
          }
          if(this.user.appliedJobs) {
            this.user.appliedJobs.forEach(element => {
              if(element._id  == this.route.snapshot.params.id) {
                this.isApplied = true;
              }
            });
          }
        },
        err => {
         alert(JSON.parse(err.error).message);
        }
      );
    }
    else {
      this.isShortlisted = true;
      this.isApplied = true;
      this.isLoggedIn = false;
    }

  }
  
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }

  apply() {
    this.jobService.apply(this.job._id, this.currentUser.id).subscribe(
      data => {
        this.isApplied = true;
        alert("Successfully applied for the job !!!!")
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  shortlistJob() {
    this.jobService.shortlistJob(this.job._id, this.currentUser.id).subscribe(
      data => {
        this.isShortlisted = true;
        alert("Successfully saved the job !!!!")
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

}
