import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Exam } from '../models/exam';
import{ GlobalConstants } from '../global-constants';

const API_URL = GlobalConstants.apiURL + 'api/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ExamService {

    constructor(private http: HttpClient) { }

    getAllCompanies() {
      return this.http.get<Exam[]>(API_URL + 'getAllExams',  httpOptions);
    }

    getExamById(examId): Observable<any> {
      return this.http.post(API_URL + 'getExamById', {
        examId: examId
      }, httpOptions);
    }

    saveExam(exam: Exam): Observable<any> {
      return this.http.post(API_URL + 'saveExam', exam, httpOptions);
    }

    getExamsByJob(jobId): Observable<any> {
      return this.http.post(API_URL + 'getExamsByJob',  {jobId: jobId},  httpOptions)
    }

    approveExam(examId): Observable<any> {
      return this.http.post(API_URL + 'approveExam', {
        examId: examId
      }, httpOptions);
    }

    getExamsByJobForEmployer(jobId): Observable<any> {
      return this.http.post(API_URL + 'getExamsByJobForEmployer',  {jobId: jobId},  httpOptions)
    }

    updateExamStatus(examId, status): Observable<any> {
      return this.http.post(API_URL + 'updateExamStatus', {
        examId: examId,
        status: status
      }, httpOptions);
    }

    updateExamRemarks(examId, remarks): Observable<any> {
      return this.http.post(API_URL + 'updateExamRemarks', {
        examId: examId,
        remarks: remarks
      }, httpOptions);
    }

    uploadExamVideo(uploadData): any {
      return this.http.post<any>(API_URL + 'uploadExamVideo', uploadData);
    }

    getExamsByUser(userId): Observable<any> {
      return this.http.post(API_URL + 'getExamsByUser',  {userId: userId},  httpOptions)
    }

    updateExamCandidateFavouriteStatus(examId, status): Observable<any> {
      return this.http.post(API_URL + 'updateExamCandidateFavouriteStatus', {
        examId: examId,
        status: status
      }, httpOptions);
    }

    getAllExams(): Observable<any> {
      return this.http.post(API_URL + 'getAllExams', httpOptions)
    }

//   postJob(job: Job): Observable<any> {
//     return this.http.post(API_URL + 'saveJob', job, httpOptions);
//   }

//   getAllJobs() {
//       return this.http.get<Job[]>(API_URL + 'getAllJobs',  httpOptions);
//   }

//   getJobDetails(id): Observable<any> {
//     return this.http.post(API_URL + 'getJobDetails', {id: id}, httpOptions);
//   }

//   apply(jobId, userId): Observable<any> {
//     return this.http.post(API_URL + 'applyJob', {jobId: jobId, userId: userId}, httpOptions);
//   }

//   shortlistJob(jobId, userId): Observable<any> {
//     return this.http.post(API_URL + 'shortlistJob', {jobId: jobId, userId: userId}, httpOptions);
//   }

//   getJobsByCompany(companyId): Observable<any> {
//     return this.http.post(API_URL + 'getJobsByCompany',  {companyId: companyId},  httpOptions)
//   }

//   shortlistUser(jobId, userId): Observable<any> {
//     return this.http.post(API_URL + 'shortlistUser', {jobId: jobId, userId: userId}, httpOptions);
//   }

//   deleteJob(jobId): Observable<any> {
//     return this.http.post(API_URL + 'deleteJob', { jobId: jobId }, httpOptions);
//   }
}