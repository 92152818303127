import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-employer-create-subemployers',
  templateUrl: './employer-create-subemployers.component.html',
  styleUrls: ['./employer-create-subemployers.component.css']
})
export class EmployerCreateSubemployersComponent implements OnInit {
  form: any = {};
  isError: Boolean;
  user: User;
  isJobAlert: boolean = false;
  
  constructor(private userService: UserService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.isError = false;
    const currentUser = this.tokenStorageService.getUser();
    this.isJobAlert = currentUser.jobAlert;
    this.userService.getByUsername(currentUser.email).subscribe(
      data => {
        this.user = data;
      },
      err => {
        console.log(err.message);
       alert(JSON.parse(err.error).message);
      }
    );
  }

  onSubmit() {
    this.form.company = this.user.company._id;
    this.userService.createSubemployer(this.form).subscribe(
          data => {
            this.form = {};
            this.isError = false;
            alert("Account created successfully");
          },
          err => {
            this.isError = true;
          }
        );
    // if (this.form.newPassword == this.form.confirmPassword) {
    //   this.userService.createSubemployer(this.form).subscribe(
    //     data => {
    //       this.form = {};
    //       this.isError = false;
    //     },
    //     err => {
    //       this.isError = true;
    //     }
    //   );
    // } else {
    //   this.isError = true;
    // }
  }

}
