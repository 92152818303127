import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Country } from '../models/country';
import{ GlobalConstants } from '../global-constants';
import { Observable } from 'rxjs';

const API_URL = GlobalConstants.apiURL + 'api/';
let params = new HttpParams()
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
 //Create new 

@Injectable({
  providedIn: 'root'
})

export class GeneralUtilService {

  constructor(private http: HttpClient) { }

  getAllCountries():Observable<Country[]> {
    return this.http.get<Country[]>(API_URL + 'get/countries',  httpOptions);
}

getCitiesFromCountyName(countryName : string):Observable<any> {
  params.set("countryName",countryName)
  return this.http.get<any>(API_URL + 'get/cities?countryName=' + countryName);
}

}
