import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { GlobalConstants } from 'src/app/global-constants';
import { Blog } from 'src/app/models/blog';
import { BlogService } from 'src/app/_services/blog.service';
const BLOG_URL = GlobalConstants.apiURL + 'api/uploadBlogImage';

@Component({
  selector: 'app-edit-blog',
  templateUrl: './edit-blog.component.html',
  styleUrls: ['./edit-blog.component.css']
})
export class EditBlogComponent implements OnInit {
  blog: Blog;
  contentError: boolean = false;
  titleError: boolean = false;
  blogUploader: FileUploader = new FileUploader({url: BLOG_URL, itemAlias: 'file', autoUpload: true, allowedMimeType: ['image/png', 'image/jpeg'] });
  blogUrl:string;

  constructor(private blogService: BlogService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.blogUrl = "assets/images/resource/mpf1.jpg";
    this.blogService.getBlogDetails(this.route.snapshot.params.id).subscribe(
      data => {
        this.blog = data;
        if (this.blog.imageUrl) {
          this.blogUrl = GlobalConstants.apiURL + this.blog.imageUrl;          
        }
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );  

    this.blogUploader.onAfterAddingFile = (file) => { 
      file.file.name = this.blog._id +".jpg";
      file.withCredentials = false;

    };
    this.blogUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      console.log(JSON.parse(response).path);  
      this.blogUrl = GlobalConstants.apiURL + JSON.parse(response).path;
      this.blog.imageUrl = "public/blog/"+this.blog._id+".jpg";
    };
  }

  onSubmit() {
    console.log(this.blog);
    this.blogService.updateBlog(this.blog).subscribe(
      data => {
        alert("Blog Updated");
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }
}
