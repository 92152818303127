<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/candidates_change_password.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:14 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Job Hunt</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">


</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome {{user.firstName}} {{user.lastName}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="candidates_profile" title=""><i class="la la-file-text"></i>My Profile</a></li>
                                        <li><a href="upload_resume" title=""><i class="la la-file-text"></i>Video Resume</a></li>
                                        <li><a href="candidates_job_alert" title=""><i class="la la-flash"></i>Job Alerts</a></li>
                                        <li><a href="candidates_shortlisted_jobs" title=""><i class="la la-money"></i>Shorlisted Job</a></li>
                                        <li><a href="candidates_applied_jobs" title=""><i class="la la-paper-plane"></i>Applied Job</a></li>
                                        <li><a href="exam_pending" title=""><i class="la la-user"></i>Exam Alerts</a><span class="badge" *ngIf="isExamAlert"><i class='la la-bell'></i></span></li>
                                        <li><a href="candidates_password_reset" title=""><i class="la la-flash"></i>Change Password</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                            <!-- <div class="widget">
                                <div class="skill-perc">
                                    <h3>Skills Percentage </h3>
                                    <p>Put value for "Cover Image" field to increase your skill up to "15%"</p>
                                    <div class="skills-bar">
                                        <span>85%</span>
                                        <div class="second circle" data-size="155" data-thickness="60">
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="manage-jobs-sec">
                                    <h3>Change Password</h3>
                                    <div class="change-password">
                                        <div class="alert alert-danger" role="alert" *ngIf="isError">
                                            Password Mismatch!
                                        </div>
                                        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <span class="pf-title">New Password</span>
                                                    <div class="pf-field">
                                                        <input type="password" name="newPassword" [(ngModel)]="form.newPassword" />
                                                    </div>
                                                    <span class="pf-title">Confirm Password</span>
                                                    <div class="pf-field">
                                                        <input type="password" name="confirmPassword" [(ngModel)]="form.confirmPassword" />
                                                    </div>
                                                    <button type="submit">Update</button>
                                                </div>
                                                <div class="col-lg-6">
                                                    <i class="la la-key big-icon"></i>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="profile-sidebar">
        <span class="close-profile"><i class="la la-close"></i></span>
        <div class="can-detail-s">
            <div class="cst"><img src="assets/images/resource/es1.jpg" alt="" /></div>
            <h3>David CARLOS</h3>
            <span><i>UX / UI Designer</i> at Atract Solutions</span>
            <p><a href="https://grandetest.com/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="98fbeafdf9ecf1eefdf4f9e1fdeaeba8a0a0d8fff5f9f1f4b6fbf7f5">[email&#160;protected]</a></p>
            <p>Member Since, 2017</p>
            <p><i class="la la-map-marker"></i>Istanbul / Turkey</p>
        </div>
        <div class="tree_widget-sec">
            <ul>
                <li><a href="candidates_profile.html" title=""><i class="la la-file-text"></i>My Profile</a></li>
                <li><a href="candidates_my_resume.html" title=""><i class="la la-briefcase"></i>My Resume</a></li>
                <li><a href="candidates_shortlist.html" title=""><i class="la la-money"></i>Shorlisted Job</a></li>
                <li><a href="candidates_applied_jobs.html" title=""><i class="la la-paper-plane"></i>Applied Job</a></li>
                <li><a href="candidates_job_alert.html" title=""><i class="la la-user"></i>Job Alerts</a></li>
                <li><a href="candidates_cv_cover_letter.html" title=""><i class="la la-file-text"></i>Cv & Cover Letter</a></li>
                <li><a href="candidates_change_password.html" title=""><i class="la la-flash"></i>Change Password</a></li>
                <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
            </ul>
        </div>
    </div>
    <!-- Profile Sidebar -->
</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/candidates_change_password.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:14 GMT -->

</html>