import { AfterViewInit, Component, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { Exam } from 'src/app/models/exam';
import { ExamService } from 'src/app/_services/exam.service';
import * as RecordRTC from 'recordrtc/RecordRTC.min';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-video-exam-form',
  templateUrl: './video-exam-form.component.html',
  styleUrls: ['./video-exam-form.component.css']
})
export class VideoExamFormComponent implements OnInit, AfterViewInit {

  exam: Exam;
  question: string;
  answer: string;
  isLastQuestion: boolean = false;
  index: number = 0;
  questionLength: number = 0;
  timer;
  countDown: Subscription;
  counter = 60;
  tick = 1000;
  limit : number;
  isStartTest: boolean = false;
  isEndTest: boolean = false;
  private stream: MediaStream;
  private recordRTC: any;
  RecordRTC = RecordRTC;
  currentUser: any;
  isLoggedIn: boolean = false;
  @ViewChild('video') video;
  isConnected = true;  
  noInternetConnection: boolean;  

  constructor(private examService: ExamService, private route: ActivatedRoute, private tokenStorageService: TokenStorageService, private connectionService: ConnectionService) { 
    this.connectionService.monitor().subscribe(isConnected => {  
      this.isConnected = isConnected;  
      if (this.isConnected) {  
        this.noInternetConnection=false;  
      }  
      else {  
        this.noInternetConnection=true;  
        document.getElementById("isLoggedIn").style.display = "none";
        clearTimeout(this.timer);
        let recordRTC = this.recordRTC;
        recordRTC.stopRecording();
      }  
    })  
  }
  
  ngAfterViewInit() {
    // set the initial state of the video
    let video:HTMLVideoElement = this.video.nativeElement;
    video.muted = true;
    video.controls = false;
    video.autoplay = true;
  }

  ngOnInit(): void {
    document.getElementById("startTest").style.display = "none";
    document.getElementById("isLoggedIn").style.display = "none";
    document.getElementById("isNotLoggedIn").style.display = "none";
    this.currentUser = this.tokenStorageService.getUser();
    
    this.examService.getExamById(this.route.snapshot.params.id).subscribe(
      data => {
        this.exam = data;
        this.questionLength = this.exam.questions.length;
        this.answer = "public/exam/"+ this.exam._id + "/answer_" + this.index + ".webm" ;
        // this.exam.answers = [];
        if (this.exam.answers) {
          this.index = this.exam.answers.length;
        }
        if(this.index+1 >= this.exam.questions.length) {
          this.isLastQuestion = true;
        }
        this.question = this.exam.questions[this.index].question;
        // this.exam.timeDuration = 1;
        var time = Number(this.exam.questions[this.index].time) + Number(this.exam.questions[this.index].time);
        var remain = 60/time;
        this.counter = (60 + remain) * this.exam.questions[this.index].time;
        this.limit = this.counter * 1000;
        if (this.currentUser) {
          this.isLoggedIn = true;
          document.getElementById("isLoggedIn").style.display = "block";
        } else {
          document.getElementById("isNotLoggedIn").style.display = "block";
        }
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }


  startExam() {
    document.getElementById("startTest").style.display = "block";
    // window.scroll(0,0);
document.body.scrollTop = 240;
    this.isStartTest = true;
    this.timer = setTimeout (() => {
      this.nextQuestion();
    }, this.limit);
    this.countDown = this.getCounter(this.tick)
      .subscribe(() => this.counter--);
    this.startRecording();
  }


  getCounter(tick) {
    return timer(0, tick);
  }

  toggleControls() {
    let video: HTMLVideoElement = this.video.nativeElement;
    // video.muted = !video.muted;
    // video.controls = !video.controls;
    // video.autoplay = !video.autoplay;
  }

  successCallback(stream: MediaStream) {

    var options = {
      mimeType: 'video/webm;codecs=vp9', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 128000,
      bitsPerSecond: 128000 // if this line is provided, skip above two
    };
    this.stream = stream;
    this.recordRTC = RecordRTC(stream, options);
    this.recordRTC.startRecording();
    let video: HTMLVideoElement = this.video.nativeElement;
    video.srcObject = stream;
    // video.src = window.URL.createObjectURL(stream);
    this.toggleControls();
  }

  errorCallback() {
    //handle error here
  }

  processVideo(audioVideoWebMURL) {
    let video: HTMLVideoElement = this.video.nativeElement;
    let recordRTC = this.recordRTC;
    video.src = audioVideoWebMURL;
    this.toggleControls();
    var recordedBlob = recordRTC.getBlob();
    recordRTC.getDataURL(function (dataURL) { });
    const uploadData = new FormData();
    var dir = "public/exam/"+ this.exam._id;
    var filename = "answer_" + this.index + ".webm" ;
    this.answer = dir + "/" + filename;
    uploadData.append('filename', filename);
    uploadData.append('dir', dir);
    uploadData.append('file', this.recordRTC.blob);
    this.exam.answers.push(this.answer);
    this.index++;
    // this.answer = "";
    if(this.index+1 >= this.exam.questions.length) {
      this.isLastQuestion = true;
    }
    this.examService.uploadExamVideo(uploadData).subscribe(
      data => {
        console.log("success====");
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
    this.examService.saveExam(this.exam).subscribe(
      data => {
        console.log("success");
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
    if (this.exam.questions.length > this.index) {
      this.question = this.exam.questions[this.index].question;      
    }
  }

  startRecording() {
    let mediaConstraints = {
     video: true,
    audio: true
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));
  }

  stopRecording() {
    let recordRTC = this.recordRTC;
    recordRTC.stopRecording(this.processVideo.bind(this));
    let stream = this.stream;
    stream.getAudioTracks().forEach(track => track.stop());
    stream.getVideoTracks().forEach(track => track.stop());
  }

  download() {
    // this.recordRTC.save('video.webm');
    // this.updateVideo();
  }


  nextQuestion(clicked?) {
    alert("Next question");
    this.stopRecording();
    if (clicked) {
      clearTimeout(this.timer);
    }
    var time = Number(this.exam.questions[this.index+1].time) + Number(this.exam.questions[this.index+1].time);
    var remain = 60/time;
    this.counter = (60 + remain) *  this.exam.questions[this.index+1].time;
    this.limit = this.counter * 1000;
    
    // this.index++;
    // this.exam.answers.push(this.answer);
    // this.question = this.exam.questions[this.index];
    // this.answer = "";
    // if(this.index+1 == this.exam.questions.length) {
    //   this.isLastQuestion = true;
    // }
    // clearTimeout(setTimeout);
    this.timer = setTimeout (() => {
      if(this.index+1 == this.exam.questions.length) {
        this.onSubmit();
      } 
      else {
        this.nextQuestion();
      }
    }, this.limit);
   this.startRecording();
  }

  onSubmit() {
    this.stopRecording();
    clearTimeout(this.timer);
    this.timer = 0;
    // this.exam.answers.push(this.answer);
    document.getElementById("startTest").style.display = "none";
    this.isEndTest = true;
  }

}

@Pipe({
  name: "formatTime"
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    //MM:SS format
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );

    // for HH:MM:SS
    //const hours: number = Math.floor(value / 3600);
    //const minutes: number = Math.floor((value % 3600) / 60);
    //return ('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}

