import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-constants';
import { Job } from 'src/app/models/job';
import { JobService } from 'src/app/_services/job.service';

@Component({
  selector: 'app-job-detailed-report',
  templateUrl: './job-detailed-report.component.html',
  styleUrls: ['./job-detailed-report.component.css']
})
export class JobDetailedReportComponent implements OnInit {

  jobs: Job[];
  apiURL = GlobalConstants.apiURL;

  constructor(private jobService: JobService) { }

  ngOnInit(): void {
    this.jobService.getAllJobs().subscribe(
      data => {
        this.jobs = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );    
  }
  
}
