<!DOCTYPE html>
<html>


<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Click to careers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header wform">
                                <div class="job-search-sec">
                                    <div class="job-search">
                                        <h4>Explore Thousand Of Jobs With Just Simple Search...</h4>
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-7">
                                                    <div class="job-field">
                                                        <input type="text" placeholder="Job title, keywords or company name" name="searchTitle" [(ngModel)]="searchTitle" />
                                                        <i class="la la-keyboard-o"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="job-field">
                                                        <input type="text" placeholder="City, province or region" name="searchCity" [(ngModel)]="searchCity" />
                                                        <i class="la la-map-marker"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-1">
                                                    <button type="submit" (click)="filter()"><i class="la la-search"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="tags-bar" *ngIf="isLoggedIn">
                                            <div class="or-browser" style="width: auto; margin: auto;">
                                                <span style="margin-right: 20px;">Saved Search</span>
                                            </div>
                                            <span *ngFor="let searchItem of user.searchList; let i = index"><span (click)="search(searchItem)" style="cursor: pointer;">{{searchItem}}</span><i class="close-tag" (click)="deleteSearchItem(i)">x</i></span>
                                            <div class="action-tags" *ngIf="searchTitle">
                                                <a (click)="saveSearch()" title="Save your search"><i class="la la-cloud-download"></i> Save</a>
                                                <!-- <a href="#" title=""><i class="la la-trash-o"></i> Clean</a> -->
                                            </div>
                                        </div>
                                        <!-- Tags Bar -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block remove-top">
                <div class="container">
                    <div class="row no-gape">
                        <div class="col-lg-9 column">
                            <!-- MOdern Job LIst -->
                            <div class="job-list-modern">
                                <div class="job-listings-sec no-border">
                                    <div class="job-listing wtabs" *ngFor="let job of pageOfItems; let i = index">
                                        <a href="/job_details/{{job._id}}" title=" ">
                                            <div class="job-title-sec">
                                                <div class="c-logo" style="padding: 15px;"> <img [src]="apiURL+job.company.imageUrl" onerror="this.src='assets/images/resource/employer.png';" style="width: 100px;" /> </div>
                                                <h3 style="padding: 0 20px;">
                                                    <a href="/job_details/{{job._id}}" title=" ">{{job.title}}</a></h3>
                                                <div class="job-lctn " style="padding: 0 20px;">
                                                    <span style="padding: 0 20px; color: #888888;">
                                                        <i class="la la-building" title="Compnay"></i> {{job.company.name}}
                                                    </span>
                                                    <br>
                                                    <span style="padding: 0 20px; color: #888888;">
                                                        <i class="la la-briefcase" title="Experience"></i> {{job.experience}} years
                                                    </span>
                                                    <br>
                                                    <span style="padding: 0 20px; color: #888888;">
                                                        <i class="la la-industry" title="Industry"></i> {{job.industry}}
                                                    </span>
                                                    <br>
                                                    <span style="padding: 0 20px; color: #888888;">
                                                        <i class="la la-map-marker" title="Location"></i>{{job.city}}, {{job.country}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="job-style-bx ">
                                                <span class="job-is ft ">{{job.type}}</span>
                                                <!-- <span class="fav-job "><i class="la la-heart-o "></i></span> -->
                                                <i>{{convert(job.postedOn)}}</i>
                                            </div>
                                        </a>
                                    </div>

                                </div>
                                <section class="pagination" style="margin-bottom: 70px;">
                                    <jw-pagination [items]="displayJobs" (changePage)="onChangePage($event)"></jw-pagination>
                                </section>
                            </div>
                        </div>
                        <aside class="col-lg-3 column">
                            <div class="widget border">
                                <a href="job_list" title=""><i class="la la-trash-o"></i> Clear filter</a>
                            </div>
                            <div class="widget border">
                                <h3 class="sb-title open">Date Posted</h3>
                                <div class="posted_widget">
                                    <input type="radio" name="choose" id="232" (click)="timeFilter(1, 1)"><label for="232">Last Hour</label><br />
                                    <input type="radio" name="choose" id="wwqe" (click)="timeFilter(1, 24)"><label for="wwqe">Last 24 hours</label><br />
                                    <input type="radio" name="choose" id="erewr" (click)="timeFilter(7, 24)"><label for="erewr">Last 7 days</label><br />
                                    <input type="radio" name="choose" id="qwe" (click)="timeFilter(14, 24)"><label for="qwe">Last 14 days</label><br />
                                    <input type="radio" name="choose" id="wqe" (click)="timeFilter(30, 24)"><label for="wqe">Last 30 days</label><br />
                                    <input type="radio" name="choose" id="qweqw" (click)="timeFilter()"><label class="nm" for="qweqw">All</label><br />
                                </div>
                            </div>
                            <div class="widget border">
                                <h3 class="sb-title open">Job Type</h3>
                                <div class="type_widget">
                                    <p class="flchek"><input type="checkbox" name="choosetype" (click)="typeFilter('Freelance')" id="33r"><label for="33r">Freelance</label></p>
                                    <p class="ftchek"><input type="checkbox" name="choosetype" (click)="typeFilter('Full Time')" id="dsf"><label for="dsf">Full Time</label></p>
                                    <p class="ischek"><input type="checkbox" name="choosetype" (click)="typeFilter('Internship')" id="sdd"><label for="sdd">Internship</label></p>
                                    <p class="ptchek"><input type="checkbox" name="choosetype" (click)="typeFilter('Part Time')" id="sadd"><label for="sadd">Part Time</label></p>
                                    <p class="tpchek"><input type="checkbox" name="choosetype" (click)="typeFilter('Temporary')" id="assa"><label for="assa">Temporary</label></p>
                                    <p class="vtchek"><input type="checkbox" name="choosetype" (click)="typeFilter('Volunteer')" id="ghgf"><label for="ghgf">Volunteer</label></p>
                                </div>
                            </div>
                            <div class="widget border">
                                <h3 class="sb-title open">Industry</h3>
                                <div class="specialism_widget">
                                    <!-- Search Widget -->
                                    <div class="simple-checkbox scrollbar" style="overflow-y: scroll;">
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Accounting')" id="as"><label for="as">Accounting</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Administration and Office Support')" id="asd"><label for="asd">Administration and Office Support</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Advertisting,Arts and Media')" id="errwe"><label for="errwe">Advertisting,Arts and Media</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Banking and Financial Services')" id="fdg"><label for="fdg">Banking and Financial Services</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Call Centre and Customer Service')" id="sc"><label for="sc">Call Centre and Customer Service</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('CEO and General Mangement')" id="aw"><label for="aw">CEO and General Mangement</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Community Services and Development')" id="ui"><label for="ui">Community Services and Development</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Constructions')" id="saas"><label for="saas">Constructions</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Consultating and Strategy')" id="rrrt"><label for="rrrt">Consultating and Strategy</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Design and Architecture')" id="eweew"><label for="eweew">Design and Architecture</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Eductation and Training')" id="bnbn"><label for="bnbn">Eductation and Training</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Engineering')" id="ffd"><label for="ffd">Engineering</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Farming Animals and Conservation')" id="fac"><label for="fac">Farming Animals and Conservation</label></p>

                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Government and Defence')" id="gad"><label for="gad">Government and Defence</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Healthcare and Medical')" id="ham"><label for="ham">Healthcare and Medical</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Hospitallty and Toursim')" id="hat"><label for="hat">Hospitallty and Toursim</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Human Resources and Recruitment')" id="hrar"><label for="hrar">Human Resources and Recruitment</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Information and Communication Technology')" id="iact"><label for="iact">Information and Communication Technology</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Insurance and Superannuation')" id="ias"><label for="ias">Insurance and Superannuation</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Legal')" id="l"><label for="l">Legal</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Manufacturing, Tranport and Logistics')" id="mtal"><label for="mtal">Manufacturing, Tranport and Logistics</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Marketing and Communication')" id="mac"><label for="mac">Marketing and Communication</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Mining, Resources and Energy')" id="mrae"><label for="mrae">Mining, Resources and Energy</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Real Estate and Property')" id="reap"><label for="reap">Real Estate and Property</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Retail and Consumer Products')" id="racp"><label for="racp">Retail and Consumer Products</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Sales')" id="s"><label for="s">Sales</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Science and Technology')" id="sat"><label for="sat">Science and Technology</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Sports and Recreation')" id="sar"><label for="sar">Sports and Recreation</label></p>
                                        <p><input type="checkbox" name="spealism" (click)="industryFilter('Trades and Services')" id="tas"><label for="tas">Trades and Services</label></p>
                                    </div>
                                </div>
                            </div>
                            <div class="widget border">
                                <h3 class="sb-title closed">Career Level</h3>
                                <div class="specialism_widget">
                                    <div class="posted_widget">
                                        <form>
                                            <input type="radio" name="choose" (click)="careerFilter('Entry-Level')" id="7"><label for="7">Entry-Level</label><br />
                                            <input type="radio" name="choose" (click)="careerFilter('Intermediate')" id="5"><label for="5">Intermediate</label><br />
                                            <input type="radio" name="choose" (click)="careerFilter('Mid-Level')" id="6"><label for="6">Mid-Level</label><br />
                                            <input type="radio" name="choose" (click)="careerFilter('Executive-Level')" id="8"><label for="8">Executive-Level</label><br />

                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="widget border">
                                <h3 class="sb-title closed">Experience</h3>
                                <div class="specialism_widget">
                                    <div class="posted_widget">
                                        <form>
                                            <input type="radio" name="choose" (click)="experienceFilter(1, 2)" id="9"><label for="9">1Year to 2Year</label><br />
                                            <input type="radio" name="choose" (click)="experienceFilter(2, 3)" id="10"><label for="10">2Year to 3Year</label><br />
                                            <input type="radio" name="choose" (click)="experienceFilter(3, 4)" id="11"><label for="11">3Year to 4Year</label><br />
                                            <input type="radio" name="choose" (click)="experienceFilter(4, 5)" id="12"><label for="12">4Year to 5Year</label><br />
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="widget border">
                                <h3 class="sb-title closed">Qualification</h3>
                                <div class="specialism_widget">
                                    <div class="posted_widget">
                                        <form>
                                            <input type="radio" name="choose" (click)="qualificationFilter('10th')" id="19"><label for="19">10th</label><br />
                                            <input type="radio" name="choose" (click)="qualificationFilter('12th')" id="20"><label for="20">12th</label><br />
                                            <input type="radio" name="choose" (click)="qualificationFilter('Diploma')" id="21"><label for="21">Diploma</label><br />
                                            <input type="radio" name="choose" (click)="qualificationFilter('Graduate')" id="22"><label for="21">Graduate</label><br />
                                            <input type="radio" name="choose" (click)="qualificationFilter('Post Graduate')" id="23"><label for="21">Post Graduate</label><br />
                                            <input type="radio" name="choose" (click)="qualificationFilter('Doctorate')" id="24"><label for="21">Doctorate</label><br />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

</html>