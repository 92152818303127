<!DOCTYPE html>
<html>


<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Exam</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

    <!-- Styles -->

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3 class="m-auto">WELCOME TO CLICK TO CAREERS</h3>
                                <h3>Automated video interviewing platform ZORA</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="profile-form-edit" *ngIf="noInternetConnection" style="padding: 200px 0; text-align: center;">
                        <h1>THERE IS NO INTERNET CONNECTION</h1>
                        <img width="111px" src="assets/images/resource/nowifi.png" alt="No Internet Connection" />
                    </div>
                    <div class="row no-gape">

                        <div class="col-lg-12 column" id="isNotLoggedIn" style="padding: 70px;">
                            Please login to attend the exam!!!
                        </div>
                        <div class="col-lg-12 column" id="isLoggedIn">
                            <div class="padding-left">
                                <!-- <div class="profile-title">
                                    <h3>Online Exam</h3>
                                </div> -->
                                <div class="profile-form-edit" *ngIf="!isStartTest && !isEndTest">
                                    <!-- <span>Automated video interviewing platform ZORA</span> -->
                                    <h3 style="text-align: center; font-weight: bold;">Do's & Dont's</h3>
                                    <div class="row" style="margin-bottom: 40px;">
                                        <div class="col-md-6"><img src="assets/images/resource/dos.png" alt="" style="display: block; margin: auto; width: 150px;">
                                            <span><b>DOS</b></span><br>
                                            <span>• Do look at the camera</span><br>
                                            <span>• Do test out lighting and volume for good quality</span><br>
                                            <span>• Do check your internet connection</span><br>
                                            <span>• Do dress as if you were going to an in person interview</span><br>
                                            <span>• Do practice!</span><br>
                                        </div>
                                        <!-- <div class="col-md-4"><img src="assets/images/resource/logo.png" alt="" style="display: block; margin: auto; width: 150px;"></div> -->
                                        <div class="col-md-6"><img src="assets/images/resource/donts.png" alt="" style="display: block; margin: auto; width: 150px;">
                                            <span><b>DON'TS</b></span><br>
                                            <span>• Don't sit in a distracting environment</span><br>
                                            <span>• Don't rely on notes</span><br>
                                            <span>• Don't go unprepared</span><br>
                                            <span>• Don't be too casual</span><br>
                                            <span>• Don't be intimidated by the video interview</span><br></div>
                                    </div>
                                    <h2 style="text-align: left; font-weight: bold;">IMPORTANT NOTE:</h2>
                                    <span style="text-align: left;">It is recommended to use “Google Chrome, Internet Edge or Mozilla Firefox” to take the interview. If you are using Apple products make sure you have the latest version of “SAFARI BROWSER-(VERSION 14 & above)” installed and kindly follow the below steps to take the video interview.</span>
                                    <h3 style="text-align: left; font-weight: bold;">For MAC:</h3>
                                    <span style="text-align: left;">1.	Go to Safari → Preferences → Advanced<br>
                                        2.	Enable the option to “Show Develop menu in menu bar” at the bottom <br>
                                        <img class="safari" src="assets/images/resource/safari1.png" alt=""><br>
                                        3.	Go to Develop → Experimental Features<br>
                                        4.	Enable MediaRecorder<br>
                                        <img class="safari" src="assets/images/resource/safari2.png" alt=""><br>

                                        </span><br><br>
                                    <h3 style="text-align: left; font-weight: bold;">For IOS:</h3>
                                    <span style="text-align: left;">1.	Go to Settings → Safari → Advanced → Experimental Features<br>
                                        2.	Enable MediaRecorder<br>
                                        
                                        <img class="safari" src="assets/images/resource/safari3.png" alt=""><br>
                                       

                                        </span><br><br>
                                    <h3 style="text-align: center; font-weight: bold;">GOOD LUCK</h3>
                                    <span style="text-align: center; display: block;">Please click the Start button when you are ready to begin your interview</span>

                                    <!-- <div class="row">
                                        <div class="col-md-6">

                                        </div>
                                        <div class="col-md-6">

                                        </div>
                                    </div> -->
                                    <button type="button" style="background: #910B08; margin-right: 54px;" (click)="startExam()">Start video interview</button>
                                </div>
                                <div class="profile-form-edit" id="startTest">
                                    <!-- <span class="videotimer">{{counter | formatTime}}</span> -->
                                    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                        <div class="row">
                                            <div class="row no-gape">
                                                <div class="pf-field">
                                                    <!-- <textarea name="answer" [(ngModel)]="answer" placeholder="Answer"></textarea> -->
                                                    <div class="row no-gape">
                                                        <div class="col-lg-12">
                                                            <video #video class="video"></video>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="row">
                                                        <div class="col-xs-12">
                                                            <button md-raised-button color="primary" (click)="startRecording()"> Record </button>
                                                            <button md-raised-button color="primary" (click)="stopRecording()"> Stop</button>
                                                            <button md-raised-button color="primary" (click)="download()"> Download</button>
                                                        </div>
                                                    </div> -->
                                                    <p class="note"><b>NOTE: There is a 30 seconds grace time<br> to read each question and dont forget to<br> allow access to microphone and camera!!</b></p>
                                                </div>

                                                <div class="videxamrow">
                                                    <span class="videotimer">Time Left: {{counter | formatTime}}</span>
                                                    <div class="pf-title span-txt mt-auto">Question {{index+1}}) {{question}}</div>
                                                    <div class=" span-txt col-lg-5 m-auto text-center">Question: {{index+1}}/{{questionLength}}</div>
                                                    <div class=" span-txt col-lg-5 m-auto text-center" *ngIf="!isLastQuestion" (click)="nextQuestion(true)" style="cursor: pointer;">
                                                        NEXT
                                                    </div>
                                                    <div class=" span-txt col-lg-5 m-auto text-center" *ngIf="isLastQuestion" (click)="onSubmit()" style="cursor: pointer;">
                                                        SUBMIT
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <!-- <button type="button" *ngIf="!isLastQuestion" (click)="nextQuestion(true)">Next</button>
                                                <button type="submit" *ngIf="isLastQuestion">Submit</button> -->
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="profile-form-edit" *ngIf="isEndTest" style="padding: 200px 0;">
                                    <h3 style="text-align: center; font-weight: bold;">Thank you for using Click To Careers automated video interviewing platform</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>


</html>