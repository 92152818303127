import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../global-constants';
import { Blog } from '../models/blog';
import { BlogService } from '../_services/blog.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {

  blogs: Blog[];
  apiURL = GlobalConstants.apiURL;
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(private blogService: BlogService) { }

  ngOnInit(): void {
    this.blogService.getAllBlogs().subscribe(
      data => {
        this.blogs = data;
        console.log(this.blogs);
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }
}
