import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  
  user: User;
  emailId: string;
  emailError: boolean = false;
  isSuccess: boolean = false;
  isError: boolean = false;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    
  }

  onSubmit(): void {
    this.invalidEmail();
    if (!this.emailError) {
      this.userService.resetPassword(this.emailId).subscribe(
        data => {
          this.user = data;
          this.emailId = "";
          this.isSuccess = true;
        },
        err => {
          this.isError = true;
         alert(JSON.parse(err.error).message);
        }
      );
    }
  }

  invalidEmail() {
    if( this.emailId == "" || this.emailId == undefined){
      this.emailError =  true;
    } else {
      this.emailError = false;
    }
  }
}
