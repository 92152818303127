import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  fullNameError: boolean = false;
  emailError: boolean = false;
  subjectError: boolean = false;
  messageError: boolean = false;
  form: any;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.form = {};
    this.form.fullName = "";
    this.form.email = "";
    this.form.subject = "";
    this.form.message = "";
  }

  onSubmit() {
    this.invalidName();
    this.invalidEmail();
    this.invalidSubject();
    this.invalidMessage();
    if (!this.fullNameError && !this.emailError && !this.subjectError && !this.messageError) {
        this.userService.sendMail(this.form).subscribe(
          data => {
            alert("Successfully send the mail")
            this.form = {};
          },
          err => {
           alert("fail");
          }
        );
    }

  }

  invalidName(){
    if(this.form.fullName == ""){
      this.fullNameError =  true;
    } else {
      this.fullNameError = false;
    }
  }

  invalidEmail(){
    if( this.form.email == ""){
      this.emailError =  true;
    } else {
      this.emailError = false;
    }
  }

  invalidSubject(){
    if( this.form.subject == ""){
      this.subjectError =  true;
    } else {
      this.subjectError = false;
    }
  }

  invalidMessage(){
    if( this.form.message == ""){
      this.messageError =  true;
    } else {
      this.messageError = false;
    }
  }
}
