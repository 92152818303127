<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/candidates_shortlist.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:14 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Job Hunt</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome {{user.firstName}} {{user.lastName}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block remove-top">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="candidates_profile" title=""><i class="la la-file-text"></i>My Profile</a></li>
                                        <li><a href="upload_resume" title=""><i class="la la-file-text"></i>Video Resume</a></li>
                                        <li><a href="candidates_job_alert" title=""><i class="la la-flash"></i>Job Alerts</a></li>
                                        <li><a href="candidates_shortlisted_jobs" title=""><i class="la la-money"></i>Shorlisted Job</a></li>
                                        <li><a href="candidates_applied_jobs" title=""><i class="la la-paper-plane"></i>Applied Job</a></li>
                                        <li><a href="exam_pending" title=""><i class="la la-user"></i>Exam Alerts</a><span class="badge" *ngIf="isExamAlert"><i class='la la-bell'></i></span></li>
                                        <li><a href="candidates_password_reset" title=""><i class="la la-flash"></i>Change Password</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                            <!-- <div class="widget">
                                <div class="skill-perc">
                                    <h3>Skills Percentage </h3>
                                    <p>Put value for "Cover Image" field to increase your skill up to "15%"</p>
                                    <div class="skills-bar">
                                        <span>85%</span>
                                        <div class="second circle" data-size="155" data-thickness="60">
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="manage-jobs-sec">
                                    <div class="border-title">
                                        <h3>Job Alerts</h3>
                                    </div>
                                    <div class="job-listing wtabs" *ngFor="let job of jobs; let i = index">
                                        <div class="job-title-sec">
                                            <div class="c-logo"> <img [src]="apiURL+job.company.imageUrl" onerror="this.src='assets/images/resource/employer.png';" style="width: 75px;" /> </div>
                                            <h3><a href="/job_details/{{job._id}}" title="">{{job.title}}</a></h3>
                                            <span>{{job.company.name}}</span>
                                            <div class="job-lctn">{{convert(job.postedOn)}}</div>
                                        </div>
                                    </div>
                                    <!-- Job -->
                                    <!-- <div class="pagination">
                                        <ul>
                                            <li class="prev"><a href="#"><i class="la la-long-arrow-left"></i> Prev</a></li>
                                            <li><a href="#">1</a></li>
                                            <li class="active"><a href="#">2</a></li>
                                            <li><a href="#">3</a></li>
                                            <li><span class="delimeter">...</span></li>
                                            <li><a href="#">14</a></li>
                                            <li class="next"><a href="#">Next <i class="la la-long-arrow-right"></i></a></li>
                                        </ul>
                                    </div> -->
                                    <!-- Pagination -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/candidates_shortlist.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:14 GMT -->

</html>