import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import * as RecordRTC from 'recordrtc/RecordRTC.min';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-exam-video',
  templateUrl: './exam-video.component.html',
  styleUrls: ['./exam-video.component.css']
})
export class ExamVideoComponent implements AfterViewInit {
private stream: MediaStream;
  private recordRTC: any;
  RecordRTC = RecordRTC;

  @ViewChild('video') video;

  constructor(private userService: UserService) {
    // Do stuff
  }

  ngAfterViewInit() {
    // set the initial state of the video
    let video:HTMLVideoElement = this.video.nativeElement;
    video.muted = false;
    video.controls = true;
    video.autoplay = true;
  }

  toggleControls() {
    let video: HTMLVideoElement = this.video.nativeElement;
    video.muted = !video.muted;
    // video.controls = !video.controls;
    // video.autoplay = !video.autoplay;
  }

  successCallback(stream: MediaStream) {

    var options = {
      mimeType: 'video/webm', // or video/webm\;codecs=h264 or video/webm\;codecs=vp9
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 128000,
      bitsPerSecond: 128000 // if this line is provided, skip above two
    };
    this.stream = stream;
    this.recordRTC = RecordRTC(stream, options);
    this.recordRTC.startRecording();
    let video: HTMLVideoElement = this.video.nativeElement;
    video.srcObject = stream;
    video.src = window.URL.createObjectURL(stream);
    this.toggleControls();
  }

  errorCallback() {
    //handle error here
  }

  processVideo(audioVideoWebMURL) {
    let video: HTMLVideoElement = this.video.nativeElement;
    let recordRTC = this.recordRTC;
    video.src = audioVideoWebMURL;
    this.toggleControls();
    var recordedBlob = recordRTC.getBlob();
    recordRTC.getDataURL(function (dataURL) { });
  }

  startRecording() {
    let mediaConstraints = {
     video: true,
    audio: true
    };
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then(this.successCallback.bind(this), this.errorCallback.bind(this));


  }

  stopRecording() {
    let recordRTC = this.recordRTC;
    recordRTC.stopRecording(this.processVideo.bind(this));
    let stream = this.stream;
    stream.getAudioTracks().forEach(track => track.stop());
    stream.getVideoTracks().forEach(track => track.stop());
  }

  download() {
    // this.recordRTC.save('video.webm');
    this.updateVideo();
  }

  updateVideo() {
    
    const uploadData = new FormData();
    this.recordRTC.blob.name = 'test.webm';
    uploadData.append('filename', 'test.webm');
    uploadData.append('file', this.recordRTC.blob);
    this.userService.updateVideo(uploadData).subscribe(
      data => {
        console.log("data====");
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }
}
