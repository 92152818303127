<!DOCTYPE html>
<html>


<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Clicktocareers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">


</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="employer_profile" title=""><i class="la la-file-text"></i>Company Profile</a></li>
                                        <li><a href="employer_manage_jobs" title=""><i class="la la-briefcase"></i>Manage Jobs</a></li>
                                        <li><a href="candidates_list" title=""><i class="la la-flash"></i>Talent Search</a></li>
                                        <!-- <li><a href="employer_transactions" title=""><i class="la la-money"></i>Transactions</a></li> -->
                                        <!-- <li><a href="employer_resume.html" title=""><i class="la la-paper-plane"></i>Resumes</a></li> -->
                                        <!-- <li><a href="employer_packages.html" title=""><i class="la la-user"></i>Packages</a></li> -->
                                        <li><a href="employer_post_job" title=""><i class="la la-file-text"></i>Post a New Job</a></li>
                                        <li><a href="employer_password_reset" title=""><i class="la la-lock"></i>Change Password</a></li>
                                        <li><a href="employer_manage_users" title=""><i class="la la-users"></i>Manage Employers</a></li>
                                        <li><a href="employer_create_users" title=""><i class="la la-user"></i>Create Employers</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left manage-jobs-sec">
                                <div class="extra-job-info">
                                    <hr style="height:2px;border-width:3px;color:gray;background-color:#1E4066">
                                    <p>ONCE YOU SHORTLIST THE CANDIDATE VIDEO INTERVIEW & WRITTEN INTERVIEW LINK WILL BE AUTOMATICALLY SENT TO THE CANDIDATES</p>
                                    <hr style="height:2px;border-width:3px;color:gray;background-color:#1E4066">
                                    <!-- <span><i class="la la-clock-o"></i><strong>9</strong> Job Posted</span> -->
                                    <!-- <span (click)="allApplication()" style="cursor: pointer;"><i class="la la-file-text"></i>All Application</span> -->
                                    <span (click)="viewApplications()" style="cursor: pointer; width: 50%;"><i class="la la-users"></i>Job Applications</span>
                                    <span (click)="viewExams()" style="cursor: pointer; width: 50%;"><i class="la la-file-text"></i>Exam</span>
                                </div>

                                <div class="emply-resume-sec" *ngIf="isApplication">
                                    <h3>Job Application</h3>
                                    <div class="manage-jobs-sec">
                                        <div class="extra-job-info">
                                            <!-- <span><i class="la la-clock-o"></i><strong>9</strong> Job Posted</span> -->
                                            <span (click)="allApplication()" style="cursor: pointer;"><i class="la la-file-text"></i>All Application</span>
                                            <span (click)="shortlistedApplication()" style="cursor: pointer;"><i class="la la-users"></i>Shortlisted Application</span>
                                            <span (click)="rejectedApplication()" style="cursor: pointer;"><i class="la la-users"></i>Rejected Application</span>
                                        </div>
                                    </div>
                                    <div class="emply-resume-list" *ngFor="let user of displayedUsers; let i = index">
                                        <div class="emply-resume-thumb">
                                            <img [src]="apiURL+user.imageUrl" onerror="this.src='assets/images/resource/employee.png';" style="width: 75px;" />
                                        </div>
                                        <div class="emply-resume-info">
                                            <h3><a href="candidates_details/{{user._id}}" title="" target="_blank">{{user.firstName}} {{user.lastName}}</a></h3>
                                            <span><i>{{user.jobTitle}}</i></span>
                                            <p><i class="la la-map-marker"></i>{{user.city}} / {{user.country}}</p>
                                        </div>
                                        <div class="action-resume">
                                            <div class="action-center">
                                                <span *ngIf="user.resumeUrl" style="width: auto;"><a href="{{apiURL}}{{user.resumeUrl}}" target="_blank" download>Resume</a></span>
                                                <span *ngIf="isApplied" style="width: auto;"><a (click)="shortlist(user._id)" title="">Shortlist</a></span>
                                                <span *ngIf="isApplied" style="width: auto;"><a (click)="reject(user._id)" title="">Reject</a></span>
                                            </div>
                                        </div>
                                        <!-- <div class="action-resume">
                                            <div class="action-center">
                                                <span>Action <i class="la la-angle-down"></i></span>
                                                <ul>
                                                    <li class="open-letter"><a href="#" title="">Cover Letter</a></li>
                                                    <li><a href="#" title="">Download CV</a></li>
                                                    <li><a (click)="shortlist(user._id)" title="">Shortlist</a></li>
                                                    <li class="open-contact"><a href="#" title="">Send a Message</a></li>
                                                    <li><a href="#" title="">View Profile</a></li>
                                                </ul>
                                            </div>
                                        </div> -->
                                        <!-- <div class="del-resume">
                                            <a href="#" title=""><i class="la la-trash-o"></i></a>
                                        </div> -->
                                    </div>

                                </div>

                                <div class="emply-resume-sec" *ngIf="isExam">
                                    <h3>Exam Details</h3>
                                    <div class="manage-jobs-sec">
                                        <div class="extra-job-info">
                                            <!-- <span><i class="la la-clock-o"></i><strong>9</strong> Job Posted</span> -->
                                            <span (click)="allExam()" style="cursor: pointer;"><i class="la la-file-text"></i>All Exam</span>
                                            <span (click)="allWrittenInteview()" style="cursor: pointer;"><i class="la la-file-text"></i>All Written Interview</span>
                                            <span (click)="allVideoInteview()" style="cursor: pointer;"><i class="la la-file-text"></i>All Video Interview</span>
                                            <span (click)="selectedCandidates()" style="cursor: pointer;"><i class="la la-users"></i>Selected Candidates</span>
                                            <!-- <span (click)="rejectedApplication()" style="cursor: pointer;"><i class="la la-users"></i>Rejected Application</span> -->
                                        </div>
                                    </div>
                                    <div class="manage-jobs-sec" style="height: 600px; overflow-y: scroll;">

                                        <table style="width: 95%;">
                                            <thead>
                                                <tr>
                                                    <td>Candidate</td>
                                                    <td>Remarks</td>
                                                    <td style="width: 5%;"></td>
                                                    <td style="width: 10%;">Status</td>
                                                    <td style="width: 10%;"></td>
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let exam of displayExams; let i = index">
                                                    <td>
                                                        <div class="table-list-title">
                                                            <div class="emply-resume-info">
                                                                <h3><a href="candidates_details/{{exam.candidate._id}}" title="" target="_blank">{{exam.candidate.firstName}} {{exam.candidate.lastName}}</a></h3>
                                                                <span><i>{{exam.candidate.jobTitle}}</i></span>
                                                                <span *ngIf="exam.examType == 'Online Test'"><i>Written Interview</i></span>
                                                                <span *ngIf="exam.examType == 'Video Test'"><i>Video Interview</i></span>
                                                                <p><i class="la la-map-marker"></i>{{exam.candidate.city}} / {{exam.candidate.country}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>{{exam.remarks}}</span>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <span class="fav-job " (click)="addFavourite(exam._id)" *ngIf="!exam.favourite" title="Add Favourite"><i class="la la-heart"></i></span>
                                                            <span class="fav-job " (click)="removeFavourite(exam._id)" *ngIf="exam.favourite" title="Remove Favourite"><i class="la la-heart" style="color: #f35d5d;"></i></span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>{{exam.status}}</span><br />
                                                    </td>
                                                    <td>
                                                        <ul class="action_job">
                                                            <li><span>Select</span><a (click)="selectExamCandidate(exam._id)"><i class="la la-check"></i></a></li>
                                                            <li><span>Reject</span><a (click)="rejectExamCandidate(exam._id)"><i class="la la-close"></i></a></li>
                                                        </ul>
                                                        <!-- <span><span>Edit</span><a (click)="approveExam(exam._id)"><i class="la la-pencil"></i></a></span><br /> -->
                                                    </td>

                                                    <td>
                                                        <ul class="action_job">
                                                            <li><span>View Exam</span><a href="/exam_details/{{exam._id}}"><i class="la la-eye"></i></a></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>




                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_resume.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:54 GMT -->

</html>