import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { GlobalConstants } from 'src/app/global-constants';
import { Blog } from 'src/app/models/blog';
import { BlogService } from 'src/app/_services/blog.service';
const BLOG_URL = GlobalConstants.apiURL + 'api/uploadBlogImage';

@Component({
  selector: 'app-add-blog',
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.css']
})
export class AddBlogComponent implements OnInit {

  blog: Blog;
  contentError: boolean = false;
  titleError: boolean = false;
  blogUploader: FileUploader = new FileUploader({url: BLOG_URL, itemAlias: 'file', autoUpload: true, allowedMimeType: ['image/png', 'image/jpeg'] });
  blogUrl:string;

  constructor(private blogService: BlogService) { }

  generateId() {
    const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    const oid = timestamp + 'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, _ => (Math.random() * 16 | 0).toString(16))
      .toLowerCase();
  
    return oid;
  }

  ngOnInit(): void {
    this.blogUrl = "assets/images/resource/mpf1.jpg";

    this.blog = new Blog();
    this.blog._id = this.generateId();
    this.blogUploader.onAfterAddingFile = (file) => { 
      file.file.name = this.blog._id +".jpg";
      file.withCredentials = false;

    };
    this.blogUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      console.log(JSON.parse(response).path);  
      this.blogUrl = GlobalConstants.apiURL + JSON.parse(response).path;
      this.blog.imageUrl = "public/blog/"+this.blog._id+".jpg";
    };
  }

  onSubmit() {
    console.log(this.blog);
    this.blogService.saveBlog(this.blog).subscribe(
      data => {
        this.blog = new Blog();
        alert("Blog Posted");
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }
}
