import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-constants';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-candidates-list',
  templateUrl: './candidates-list.component.html',
  styleUrls: ['./candidates-list.component.css']
})
export class CandidatesListComponent implements OnInit {

  users: User[];
  displayUsers: User[];
  minExperience: number;
  maxExperience: number;
  career: string;
  qualification: string;
  apiURL = GlobalConstants.apiURL;
  searchTitle: string;
  searchCity: string;
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    // const currentUser = this.tokenStorageService.getUser();
    this.userService.getAllEmployees().subscribe(
      data => {
        this.users = data;
        this.displayUsers = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );    
  }


  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }

  qualificationFilter(qualification) {  
    this.qualification = qualification;
    this.filter();
  }

  experienceFilter(min?, max?) {
    this.minExperience = min;
    this.maxExperience = max;
    this.filter();
  }

  careerFilter(career) {  
    this.career = career;
    this.filter();
  }

  filter() {

    //  title filter
    if (this.searchTitle) {
      var temp = this.users;
      this.displayUsers = [];
      temp.forEach(element => {
        if (element.jobTitle.toLowerCase().includes(this.searchTitle.toLowerCase()) || (element.firstName.toLowerCase().includes(this.searchTitle.toLowerCase())) ) {
          this.displayUsers.push(element);
        }
      });
    } 
    else {
      this.displayUsers = this.users;
    }

    //  city filter
    if (this.searchCity) {
      var temp = this.displayUsers;
      this.displayUsers = [];
      temp.forEach(element => {
        if (element.city.toLowerCase().includes(this.searchCity.toLowerCase())) {
          this.displayUsers.push(element);
        }
      });
    }

    // if (this.career) {
    //   var temp = this.displayUsers;
    //   this.displayUsers = [];
    //   temp.forEach(job => {
        
    //       if(job.careerLevel == this.career) {
    //         this.displayUsers.push(job);
          
    //     }
    //   });        
    // }


    //  qualification filter
    if (this.qualification) {
      var temp = this.displayUsers;
      this.displayUsers = [];
      temp.forEach(job => {
          if(job.education == this.qualification) {
            this.displayUsers.push(job);
          } 
      });        
    }

    // experience filter
    if(this.minExperience && this.maxExperience) {
      var temp = this.displayUsers;
      this.displayUsers = [];
      temp.forEach(element => {
        if( element.experienceYear >= this.minExperience && element.experienceYear < this.maxExperience) {
          this.displayUsers.push(element);
        }
      });    
    }

  }
}
