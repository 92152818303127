import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-constants';
import { Job } from 'src/app/models/job';
import { JobService } from 'src/app/_services/job.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-manage-jobs',
  templateUrl: './manage-jobs.component.html',
  styleUrls: ['./manage-jobs.component.css']
})
export class ManageJobsComponent implements OnInit {

  apiURL = GlobalConstants.apiURL;
  jobs: Job[];
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(private jobService: JobService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    const currentUser = this.tokenStorageService.getUser();
    this.jobService.getAllJobs().subscribe(
      data => {
        this.jobs = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );    
  }


  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }

  deleteJob(jobId) {
    this.jobService.deleteJob(jobId).subscribe(
      data => {
        this.ngOnInit();
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }


}
