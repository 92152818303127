<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/register.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:44 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Job Hunt</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section>
            <div class="block gray">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner2">
                                <div class="inner-title2">
                                    <h3>Reset Password</h3>
                                    <!-- <span>Keep up to date with the latest news</span> -->
                                </div>
                                <!-- <div class="page-breacrumbs">
								<ul class="breadcrumbs">
									<li><a href="#" title="">Home</a></li>
									<li><a href="#" title="">Pages</a></li>
									<li><a href="#" title="">Register</a></li>
								</ul>
							</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block remove-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="account-popup-area signup-popup-box static">
                                <div class="account-popup">
                                    <h3>Reset Password</h3>
                                    <!-- <span>Lorem ipsum dolor sit amet consectetur adipiscing elit odio duis risus at lobortis ullamcorper</span> -->
                                    <span *ngIf="isSuccess" style="margin-top: 20px; color: green;">Successful. Please check your mail for new password.</span>
                                    <span *ngIf="isError" style="margin-top: 20px; color: red;">Can't find an account with this email id.</span>
                                    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                        <div class="cfield">
                                            <input type="text" name="emailId" [(ngModel)]="emailId" placeholder="Email" />
                                            <i class="la la-envelope-o"></i>
                                            <div *ngIf="emailError" class="alert-danger">
                                                Email is Mandatory.
                                            </div>
                                        </div>
                                        <button type="submit">Reset Password</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/register.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:44 GMT -->

</html>