<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_resume.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:52 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Job Hunt</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">


</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="admin_manage_users" title=""><i class="la la-users"></i>Manage Active Users</a></li>
                                        <li><a href="admin_manage_deactivated_users" title=""><i class="la la-users"></i>Manage Deactivated Users</a></li>
                                        <li><a href="admin_manage_jobs" title=""><i class="la la-briefcase"></i>Manage Jobs</a></li>
                                        <li><a href="exam_list" title=""><i class="la la-file-text"></i>View All Exams</a></li>
                                        <li><a href="add_blog" title=""><i class="la la-file-text"></i>Add Blog</a></li>
                                        <li><a href="admin_blog_list" title=""><i class="la la-file-text"></i>Blog List</a></li>
                                        <li><a href="monthly_user_signup_report" title=""><i class="la la-file"></i>Monthly Signup Report</a></li>
                                        <li><a href="user_signup_report" title=""><i class="la la-file"></i>Signup Report</a></li>
                                        <li><a href="job_post_report" title=""><i class="la la-file"></i>Job Posted Report</a></li>
                                        <li><a href="job_detailed_report" title=""><i class="la la-file"></i>Job Detailed Report</a></li>
                                        <li><a href="admin_password_reset" title=""><i class="la la-lock"></i>Change Password</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="emply-resume-sec">
                                    <h3>Exam Details</h3>

                                    <div class="manage-jobs-sec">

                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>Candidate</td>
                                                    <td>Status</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let exam of exams; let i = index">
                                                    <td>
                                                        <div class="table-list-title">
                                                            <div class="emply-resume-info">
                                                                <h3><a href="#" title="">{{exam.candidate.firstName}} {{exam.candidate.lastName}}</a></h3>
                                                                <span><i>{{exam.candidate.jobTitle}}</i></span>
                                                                <p><i class="la la-map-marker"></i>{{exam.candidate.city}} / {{exam.candidate.country}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span>{{exam.status}}</span><br />
                                                    </td>
                                                    <td>
                                                        <ul class="action_job">
                                                            <li><span>Approve</span><a (click)="approveExam(exam._id)"><i class="la la-check"></i></a></li>
                                                        </ul>
                                                        <!-- <span><span>Edit</span><a (click)="approveExam(exam._id)"><i class="la la-pencil"></i></a></span><br /> -->
                                                    </td>

                                                    <td>
                                                        <ul class="action_job">
                                                            <li><span>View Exam</span><a href="/admin_exam_details/{{exam._id}}"><i class="la la-eye"></i></a></li>
                                                            <!-- <li><span>Edit</span><a href="#" title=""><i class="la la-pencil"></i></a></li> -->
                                                            <!-- <li><span>Delete</span><a (click)="deleteJob(job._id)" style="cursor: pointer;" title=""><i class="la la-trash-o"></i></a></li> -->
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_resume.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:54 GMT -->

</html>