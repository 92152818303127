import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/models/company';
import { Job } from 'src/app/models/job';
import { User } from 'src/app/models/user';
import { JobService } from 'src/app/_services/job.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-employer-manage-subemployers',
  templateUrl: './employer-manage-subemployers.component.html',
  styleUrls: ['./employer-manage-subemployers.component.css']
})
export class EmployerManageSubemployersComponent implements OnInit {
  jobs: Job[];
  users: User[];
  company: Company = new Company();
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  isJobAlert: boolean = false;

  constructor(private userService: UserService, private jobService: JobService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    const currentUser = this.tokenStorageService.getUser();
    this.isJobAlert = currentUser.jobAlert;
    this.userService.getUsersByCompany(currentUser.email).subscribe(
      data => {
        this.users = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );    
  }


  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }

  deleteUser(userId) {
    this.userService.deleteUser(userId).subscribe(
      data => {
        this.ngOnInit();
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  changeEmployerRole(userId) {
    this.userService.changeEmployerRole(userId).subscribe(
      data => {
        this.ngOnInit();
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }
}
