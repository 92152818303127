import { Component, OnInit } from '@angular/core';
import { Job } from 'src/app/models/job';
import { JobService } from 'src/app/_services/job.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { GeneralUtilService } from 'src/app/_services/general-util.service';
import { Country } from '../../models/country';
import { City } from '../../models/city';
import { Questions } from '../../models/questions';
import { Observable } from 'rxjs';
import { GlobalConstants } from 'src/app/global-constants';
import { FileUploader } from 'ng2-file-upload';
import { User } from 'src/app/models/user';
import { Benefits } from 'src/app/models/benefits';
import { UserService } from 'src/app/_services/user.service';
import { SkillList } from 'src/app/models/skill-list';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

const BANNER_URL = GlobalConstants.apiURL + 'api/updateJobBannerImage';

@Component({
  selector: 'app-employer-post-job',
  templateUrl: './employer-post-job.component.html',
  styleUrls: ['./employer-post-job.component.css']
})
export class EmployerPostJobComponent implements OnInit {

  job: Job; 
  skills: string;
  currentUser;
  questions: Questions[];
  questions2: Questions[];
  question: Questions;
  question2: Questions;
  countries: Country[];
  states: any[];
  cityList: any[] = [];
  stateList: any[] = [];
  cities: any[] ;
  selectedCountry: string;
  titleError: boolean;
  descriptionError: boolean;
  offerdSalaryError: boolean;
  experienceError: boolean;
  skillError: boolean;
  addressError: boolean;
  jobId: any;
  bannerUrl:string;
  bannerUploader: FileUploader = new FileUploader({url: BANNER_URL, itemAlias: 'file', autoUpload: true, allowedMimeType: ['image/png', 'image/jpeg'] });
  isLoggedIn: boolean = false;
  isUserActive: boolean = false;
  isProfileUpdated: boolean = false;
  user: User;
  isEmployer: boolean = false;
  questionError: boolean = false;
  questionTimeError: boolean = false;
  questionError2: boolean = false;
  questionTimeError2: boolean = false;
  roles: any;
  responsibility: string;
  responsibilityError: boolean = false;
  industryError: boolean = false;
  jobtypeError: boolean = false;
  benefits: Benefits;
  skillList = SkillList.skillList;
  skill: string;
  filteredOptions: Observable<string[]>;
  myControl = new FormControl();
  isJobAlert: boolean = false;

  constructor( private jobService: JobService, private userService: UserService, private tokenStorageService: TokenStorageService , private utilService : GeneralUtilService) { }
  generateId() {
    const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    const oid = timestamp + 'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, _ => (Math.random() * 16 | 0).toString(16))
      .toLowerCase();
  
    return oid;
  }
  ngOnInit(): void {
    this.jobId = this.generateId();
    this.skills = "";
    this.responsibility = "";
    this.job = new Job();
    this.job.responsibilities = [];
    this.job._id = this.jobId;
    this.job.skills = [];
    this.question = new Questions;
    this.question2 = new Questions;
    this.benefits = new Benefits;
    this.questions = [];
    this.questions2 = [];
    this.titleError = false;
    this.descriptionError = false;
    this.offerdSalaryError = false;
    this.experienceError = false;
    this.skillError = false;
    this.addressError = false;
    this.bannerUrl = "assets/images/resource/mpf1.jpg";
    // this.job.examQuestions= ["hi"];
    this.currentUser = this.tokenStorageService.getUser();
    this.isJobAlert = this.currentUser.jobAlert;
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

      
    if (this.isLoggedIn) {
      this.userService.getByUsername(this.currentUser.email).subscribe(
        data => {
          this.user = data;
          this.isUserActive = this.user.isActive;
          this.isProfileUpdated = this.user.profileUpdated;
          this.roles = this.user.roles;
          if (this.roles.includes('ROLE_EMPLOYER')) {
            this.isEmployer = true;
          }
        },
        err => {
         alert(JSON.parse(err.error).message);
        }
      );
      
    }
    this.utilService.getAllCountries().subscribe(data =>{
      this.countries = data
    });
    this.bannerUploader.onAfterAddingFile = (file) => { 
      file.file.name = this.jobId +".jpg";
      file.withCredentials = false;

    };
    this.bannerUploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        this.bannerUrl = GlobalConstants.apiURL + JSON.parse(response).path;
        this.job.bannerUrl = "public/banner/"+this.jobId+".jpg";
    };

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.skillList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }


  onSubmit() {
    if (this.job.youtubeLink) {
      var youtubeId = this.job.youtubeLink.substring(32);
      var youtubeUrl = "https://www.youtube.com/embed/" + youtubeId;
      this.job.youtubeLink = youtubeUrl;
    }
    this.job.postedBy = this.currentUser.email;
    this.job.examQuestions = this.questions;
    this.job.examQuestions2 = this.questions2;
    this.job.benefits = this.benefits;
    this.invalidTitle();
    this.invaliddescription();
    this.invalidsalary();
    this.invalidexperience();
    this.invalidskill();
    this.invalidaddress();
    this.invalidindustry();
    this.invalidjobtype();
    if (!this.job.responsibilities.length) {
      this.responsibilityError = true;
    }
    if (!this.responsibilityError && !this.titleError && !this.descriptionError && !this.offerdSalaryError && !this.experienceError && !this.skillError && !this.addressError && !this.industryError && !this.jobtypeError) {
      this.jobService.postJob(this.job).subscribe(
        data => {
          this.skills = "";
          this.job = new Job();
          this.questions = [];
          alert("Job Posted");
          window.location.href = 'employer_manage_jobs';
        },
        err => {
         alert(JSON.parse(err.error).message);
        }
      );
    }
    else {
      alert("Please fill mandatory fields");
    }
    
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  addSkill() {
    if( this.skill == undefined || this.skill == ""){
      this.skillError =  true;
    } else {
      this.skillError = false;
      this.job.skills.push(this.skill);  
      this.skill = "";
    }
  }
  deleteSkill(i) {
    this.job.skills.splice(i, 1);
  }
  addQuestion() {
    if (this.question.question == undefined) {
      this.questionError = true
    } else {
      this.questionError = false;
    }
    if (this.question.time == undefined) {
      this.questionTimeError = true
    } else {
      this.questionTimeError = false;
    }
    if (!this.questionError && !this.questionTimeError) {
      this.questions.push(this.question);
      this.question = new Questions;
    }
    
  }

  deleteQuestion(i) {
    this.questions.splice(i, 1);
  }

  addQuestion2() {
    if (this.question2.question == undefined) {
      this.questionError2 = true
    } else {
      this.questionError2 = false;
    }
    if (this.question2.time == undefined) {
      this.questionTimeError2 = true
    } else {
      this.questionTimeError2 = false;
    }
    if (!this.questionError2 && !this.questionTimeError2) {
      this.questions2.push(this.question2);
      this.question2 = new Questions;
    }
  }

  deleteQuestion2(i) {
    this.questions2.splice(i, 1);
  }

  getStates(countryName): any{
    this.states = [];
    this.stateList = [];
    this.utilService.getCitiesFromCountyName(countryName).subscribe(data=>{
    
      data[0].states.map((stateList) => {
        this.stateList.push(stateList)
      }
      );
      for(var i=0 ; i < this.stateList.length ; i++){
        this.states = this.states.concat(this.stateList[i])
      }
    });
  }

    getCities(statename): any{
      this.cities = [];
      this.cityList = [];
      for(var i=0 ; i < this.stateList.length ; i++){
        if (this.stateList[i].name == statename) {
          this.cityList.push(this.stateList[i].cities);
        }
      }
      for(var i=0 ; i < this.cityList.length ; i++){
        this.cities = this.cities.concat(this.cityList[i])
      }
    }

    addResponsibility() {
      if (this.responsibility == "") {
        this.responsibilityError = true
      } else {
        this.responsibilityError = false;
      }
      if (this.job.responsibilities.length >= 14) {
        document.getElementById("responsibilityBtn").style.display = "none";
        
      }
      if (!this.responsibilityError) {
        this.job.responsibilities.push(this.responsibility);
        this.responsibility = ""; 
      }
    }

    deleteResponsibility(i) {
      this.job.responsibilities.splice(i, 1);
    }
    invalidTitle(){
     
      if( this.job.title == undefined){
        this.titleError =  true;
      } else {
        this.titleError = false;
      }
    }

    invaliddescription(){
     
      if( this.job.description == undefined){
        this.descriptionError =  true;
      } else {
        this.descriptionError = false;
      }
    }

    invalidsalary(){
     
      if( this.job.offerdSalary == undefined){
        this.offerdSalaryError =  true;
      } else {
        this.offerdSalaryError = false;
      }
    } 
 
    invalidexperience(){
     
      if( this.job.experience == undefined){
        this.experienceError =  true;
      } else {
        this.experienceError = false;
      }
    }

    invalidskill(){
     
      if( this.job.skills.length == 0){
        this.skillError =  true;
      } else {
        this.skillError = false;
      }
    }
    
    invalidaddress(){
     
      if( this.job.address == undefined){
        this.addressError =  true;
      } else {
        this.addressError = false;
      }
    }

    invalidindustry(){
      if( this.job.industry == undefined){
        this.industryError =  true;
      } else {
        this.industryError = false;
      }
    }

    invalidjobtype(){
      if( this.job.type == undefined){
        this.jobtypeError =  true;
      } else {
        this.jobtypeError = false;
      }
    }

    getInterviewType(interviewtype){
      this.job.examType = interviewtype;
    }
}
