import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from './_services/auth.service';
import { TokenStorageService } from './_services/token-storage.service';
import { UserService } from './_services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  private roles: string[];
  isLoggedIn = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  showEmployeeBoard = false;
  showEmployerBoard = false
  username: string;
  form: any = {};
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  content: string;
  isLoginFailed = false;
  isChecked = false;
  isCheckedError = false;
  roleError = false;

  constructor(private userService: UserService,private authService: AuthService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.loadScript('/assets/js/jquery.min.js');
    this.loadScript('/assets/js/modernizr.js');
    this.loadScript('/assets/js/script.js');
    this.loadScript('/assets/js/bootstrap.min.js');
    this.loadScript('/assets/js/wow.min.js');
    this.loadScript('/assets/js/slick.min.js');
    this.loadScript('/assets/js/parallax.js');
    this.loadScript('/assets/js/select-chosen.js');
    this.loadScript('/assets/js/jquery.scrollbar.min.js');
    this.loadScript('/assets/js/circle-progress.min.js');
    this.loadScript('/assets/js/tag.js');
   
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');
      this.showEmployerBoard = this.roles.includes('ROLE_EMPLOYER');
      this.showEmployeeBoard = this.roles.includes('ROLE_EMPLOYEE');

      this.username = user.username;
    }
  }

  resetForm(f: NgForm) {
    this.form = {};
    f.resetForm(f.value);
  }

  onSubmit(): void {
    this.form.username = this.form.email;
    if (this.form.roles == undefined) {
      this.roleError = true;
    } else {
      this.roleError = false;
    }
    if (this.isChecked && !this.roleError) {
      this.isCheckedError = false;
      this.authService.register(this.form).subscribe(
        data => {
          this.isSuccessful = true;
          this.isSignUpFailed = false;
          this.onLogin();
        },
        err => {
          this.errorMessage = err.error.message;
          this.isSignUpFailed = true;
        }
      );
    } else {
      this.isCheckedError = true;
    }
    
  }

  onLogin(): void {
    this.authService.login(this.form).subscribe(
      data => {
        this.tokenStorageService.saveToken(data.accessToken);
        this.tokenStorageService.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.tokenStorageService.getUser().roles;
        this.reloadPage();
        this.roles.forEach(element => {
          if (element == "ROLE_EMPLOYEE") {
            window.location.href = 'candidates_profile';
          }
          else if (element == "ROLE_EMPLOYER" || element == "ROLE_SUB-EMPLOYER") {
            window.location.href = 'employer_profile';
          }
          else {
            window.location.href = 'admin_manage_users';
          }
        });
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }
  getRole(role) {
    this.form.roles = role;
  }

  sendSurvey() {
    this.form.email = this.tokenStorageService.getUser().email;
    this.userService.sendSurveyMail(this.form).subscribe(
      data => {
        this.form = {};
        alert("Thank you for your feedback!!");
        this.logout();
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );    
  }

  reloadPage(): void {
    window.location.reload();
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
    window.location.href = 'home';
  }

  public loadScript(url: string) {
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

}
