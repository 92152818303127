import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/_services/user.service';
import{ GlobalConstants } from '../../global-constants';

@Component({
  selector: 'app-candidates-detail',
  templateUrl: './candidates-detail.component.html',
  styleUrls: ['./candidates-detail.component.css']
})
export class CandidatesDetailComponent implements OnInit {

  user: User;
  url: any;
  videoResumeurl: any;
  apiURL = GlobalConstants.apiURL;
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  resumeUrl: string;

  constructor(private route: ActivatedRoute, private userService: UserService) { }

  ngOnInit(): void {
    this.userService.getUserById(this.route.snapshot.params.id).subscribe(
      data => {
        this.user = data;
        this.url = GlobalConstants.apiURL + this.user.imageUrl;
        this.videoResumeurl = GlobalConstants.apiURL + data.videoUrl;
        if (this.user.resumeUrl) {
          this.resumeUrl = GlobalConstants.apiURL + this.user.resumeUrl;
        }
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );  
  }

  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }

}
