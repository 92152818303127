import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-employer-password-reset',
  templateUrl: './employer-password-reset.component.html',
  styleUrls: ['./employer-password-reset.component.css']
})
export class EmployerPasswordResetComponent implements OnInit {

  form: any = {};
  isError: Boolean;
  user: User;
  isJobAlert: boolean = false;
  
  constructor(private userService: UserService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.isError = false;
    const currentUser = this.tokenStorageService.getUser();
    this.isJobAlert = currentUser.jobAlert;
    this.userService.getByUsername(currentUser.email).subscribe(
      data => {
        this.user = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  onSubmit() {
    this.form.username = this.user.username;
    if (this.form.newPassword == this.form.confirmPassword) {
      this.userService.updatePassword(this.form).subscribe(
        data => {
          this.form = {};
          this.isError = false;
          alert("Your password has been updated");
        },
        err => {
          this.isError = true;
        }
      );
    } else {
      this.isError = true;
    }
  }

}
