import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import{ GlobalConstants } from '../global-constants';

const API_URL = GlobalConstants.apiURL + 'api/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const httpOptionsImag = {
  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getPublicContent(): Observable<any> {
    return this.http.get(API_URL + 'all', { responseType: 'text' });
  }

  getUserBoard(): Observable<any> {
    return this.http.get(API_URL + 'user', { responseType: 'text' });
  }

  getModeratorBoard(): Observable<any> {
    return this.http.get(API_URL + 'mod', { responseType: 'text' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(API_URL + 'admin');
  }

  getAll() {
    return this.http.get<User[]>(`${API_URL}/users`);
  }

  getByUsername(username: string): Observable<any> {
    return this.http.post(API_URL + 'getUser', {
      username: username
    }, httpOptions);
  }

  emailVerification(userId): Observable<any> {
    return this.http.post(API_URL + 'emailVerification', {
      userId: userId
    }, httpOptions);
  }
  
  resetPassword(username: string): Observable<any> {
    return this.http.post(API_URL + 'resetPassword', {
      username: username
    }, httpOptions);
  }

  getUserById(userId): Observable<any> {
    return this.http.post(API_URL + 'getUserById', {
      userId: userId
    }, httpOptions);
  }
  
  updateUserProfile(user: User): Observable<any> {
    return this.http.post(API_URL + 'updateUserProfile', {
      user
    }, httpOptions);
  }
  
  updateUserProfilePic(uploadData): any {
    return this.http.post<any>(API_URL + 'updateUserProfilePic', uploadData);
  }

  updateVideo(uploadData): any {
    return this.http.post<any>(API_URL + 'updateVideo', uploadData);
  }
  
  updatePassword(form): Observable<any> {
    return this.http.post(API_URL + 'updatePassword', form, httpOptions);
  }
  
  updateEmployerProfile(user: User): Observable<any> {
    return this.http.post(API_URL + 'updateEmployerProfile', {
      user
    }, httpOptions);
  }
  
  sendMail(form): Observable<any> {
    return this.http.post(API_URL + 'sendMail', form, httpOptions);
  }
  
  updateImageUrl(username: string): Observable<any> {
    return this.http.post(API_URL + 'updateImageUrl', {
      username: username
    }, httpOptions);
  }

  updateCompanyImageUrl(companyId): Observable<any> {
    return this.http.post(API_URL + 'updateCompanyImageUrl', {
      companyId: companyId
    }, httpOptions);
  }

  updateCompanyBannerUrl(companyId): Observable<any> {
    return this.http.post(API_URL + 'updateCompanyBannerUrl', {
      companyId: companyId
    }, httpOptions);
  }
  
  updateResumeUrl(username: string, resumeUrl: string, fileType: string): Observable<any> {
    return this.http.post(API_URL + 'updateResumeUrl', {
      username: username,
      resumeUrl: resumeUrl,
      fileType: fileType
    }, httpOptions);
  }
  
  getUsersByCompany(username: string): Observable<any> {
    return this.http.post(API_URL + 'getUsersByCompany', {
      username: username
    }, httpOptions);
  }
  
  getAllUsers(): Observable<any> {
    return this.http.post(API_URL + 'getAllUsers', httpOptions);
  }
  
  createSubemployer(user): Observable<any> {
    return this.http.post(API_URL + 'createSubemployer', {
      username: user.email,
      email: user.email,
      password: user.password,
      roles: user.roles,
      phone: user.phone,
      firstName: user.firstName,
      lastName: user.lastName,
      company: user.company
    }, httpOptions);
  }
  
  updateUserStatus(userId): Observable<any> {
    return this.http.post(API_URL + 'updateUserStatus', {
      userId: userId
    }, httpOptions);
  }
  
  deleteUser(userId): Observable<any> {
    return this.http.post(API_URL + 'deleteUser', { userId: userId }, httpOptions);
  }

  changeEmployerRole(userId): Observable<any> {
    return this.http.post(API_URL + 'changeEmployerRole', { userId: userId }, httpOptions);
  }
  
  getAllEmployees(): Observable<any> {
    return this.http.post(API_URL + 'getAllEmployees', httpOptions);
  }

  uploadVideoResume(uploadData): any {
    return this.http.post<any>(API_URL + 'uploadVideoResume', uploadData);
  }

  saveSearchItem(userId, searchItem): Observable<any> {
    return this.http.post(API_URL + 'saveSearchItem', {
      userId: userId,
      searchItem: searchItem
    }, httpOptions);
  }

  deleteSearchItem(userId, searchItem): Observable<any> {
    return this.http.post(API_URL + 'deleteSearchItem', {
      userId: userId,
      searchItem: searchItem
    }, httpOptions);
  }

  updateVideoResumeUrl(userId): Observable<any> {
    return this.http.post(API_URL + 'updateVideoResumeUrl', {
      userId: userId
    }, httpOptions);
  }

  showVideoResume(userId, showResume): Observable<any> {
    return this.http.post(API_URL + 'showVideoResume', {
      userId: userId,
      showResume: showResume
    }, httpOptions);
  }

  resendActivationMail(userId): Observable<any> {
    return this.http.post(API_URL + 'resendActivationMail', {
      userId: userId
    }, httpOptions);
  }

  sendSurveyMail(form): Observable<any> {
    return this.http.post(API_URL + 'sendSurveyMail', {
      email: form.email,
      serviceRate: form.serviceRate,
      websiteRate: form.websiteRate,
      recommendRate: form.recommendRate,
      hearAbout: form.hearAbout,
      comment: form.comment
    }, httpOptions);
  }

  addFavouriteCompany(companyId, userId): Observable<any> {
    return this.http.post(API_URL + 'addFavouriteCompany', {
      userId: userId,
      companyId: companyId
    }, httpOptions);
  }

  getUsersReport(): Observable<any> {
    return this.http.post(API_URL + 'getUsersReport', httpOptions);
  }

  getUsersReportTimePeriod(startDate, endDate):Observable<any> {
    return this.http.post(API_URL + 'getUsersReportTimePeriod', {
      startDate: startDate,
      endDate: endDate
    }, httpOptions);
  }
  
// update(id, params) {
//     return this.http.put(`${API_URL}/users/${id}`, params)
//         .pipe(map(x => {
//             // update stored user if the logged in user updated their own record
//             if (id == this.userValue.id) {
//                 // update local storage
//                 const user = { ...this.userValue, ...params };
//                 localStorage.setItem('user', JSON.stringify(user));

//                 // publish updated user to subscribers
//                 this.userSubject.next(user);
//             }
//             return x;
//         }));
// }

// delete(id: string) {
//     return this.http.delete(`${API_URL}/users/${id}`)
//         .pipe(map(x => {
//             // auto logout if the logged in user deleted their own record
//             if (id == this.userValue.id) {
//                 this.logout();
//             }
//             return x;
//         }));
// }
}
