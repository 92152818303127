export class Benefits {
    skillTraining: boolean;
    jobTraining: boolean;
    cafeteria: boolean;
    healthInsurance: boolean;
    teamOutings: boolean;
    gymnasium: boolean;
    educationAssistance: boolean;
    freeTransport: boolean;
    childCare: boolean;
    workFromHome: boolean;
    internationalRelocation: boolean;
    freeFood: boolean;
}