export class Work {
    designation : string;
    startYear: number;
    startMonth: string;
    endYear: number;
    endMonth: string;
    company: string;
    isWorking: boolean;
    noticePeriod: string;
    experience: string;
}