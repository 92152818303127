<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_resume.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:52 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Job Hunt</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">


</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="employer_profile" title=""><i class="la la-file-text"></i>Company Profile</a></li>
                                        <li><a href="employer_manage_jobs" title=""><i class="la la-briefcase"></i>Manage Jobs</a><span class="badge" *ngIf="isJobAlert"><i class='la la-bell'></i></span></li>
                                        <li><a href="candidates_list" title=""><i class="la la-flash"></i>Talent Search</a></li>
                                        <!-- <li><a href="employer_transactions" title=""><i class="la la-money"></i>Transactions</a></li> -->
                                        <!-- <li><a href="employer_resume.html" title=""><i class="la la-paper-plane"></i>Resumes</a></li> -->
                                        <!-- <li><a href="employer_packages.html" title=""><i class="la la-user"></i>Packages</a></li> -->
                                        <li><a href="employer_post_job" title=""><i class="la la-file-text"></i>Post a New Job</a></li>
                                        <li><a href="employer_password_reset" title=""><i class="la la-lock"></i>Change Password</a></li>
                                        <li><a href="employer_manage_users" title=""><i class="la la-users"></i>Manage Employers</a></li>
                                        <li><a href="employer_create_users" title=""><i class="la la-user"></i>Create Employers</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="emply-resume-sec manage-jobs-sec">
                                    <h3>Employers</h3>

                                    <table>
                                        <thead>
                                            <tr>
                                                <td>User</td>
                                                <td>Role</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let user of users; let i = index">
                                                <td>
                                                    <div class="table-list-title">
                                                        <div class="emply-resume-thumb">
                                                            <img src="{{apiURL}}{{user.imageUrl}}" alt="" />
                                                        </div>
                                                        <div class="emply-resume-info">
                                                            <h3><a href="#" title="">{{user.firstName}} {{user.lastName}}</a></h3>
                                                            <p><i class="la la-map-marker"></i>{{user.city}} / {{user.country}}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span *ngFor="let role of user.roles; let i = index">{{role.name}}</span><br />
                                                </td>
                                                <td class="action-center">
                                                    <span style="display: block; color: #1E4066;"><a  (click)="changeEmployerRole(user._id)">Change Role</a></span><br />
                                                </td>

                                                <td>
                                                    <ul class="action_job">
                                                        <!-- <li><span>View Job</span><a routerLink="/employer_manage_applications/{{job._id}}"><i class="la la-eye"></i></a></li> -->
                                                        <!-- <li><span>Edit</span><a href="#" title=""><i class="la la-pencil"></i></a></li> -->
                                                        <li><span>Delete</span><a (click)="deleteUser(user._id)" style="cursor: pointer;" title=""><i class="la la-trash-o"></i></a></li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_resume.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:54 GMT -->

</html>