<div class="row">
    <div class="col-xs-12">
        <video #video class="video"></video>
    </div>
</div>
<div class="row">
    <div class="col-xs-12">
        <button md-raised-button color="primary" (click)="startRecording()"> Record </button>
        <button md-raised-button color="primary" (click)="stopRecording()"> Stop</button>
        <button md-raised-button color="primary" (click)="download()"> Download</button>
    </div>
</div>

<div class="video">
    <video controls #videoPlayer>
		<source src="http://localhost:3000/public/video/blob" type="video/mp4" />
		Browser not supported
	</video>
</div>