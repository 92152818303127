import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Exam } from 'src/app/models/exam';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-employer-manage-exams',
  templateUrl: './employer-manage-exams.component.html',
  styleUrls: ['./employer-manage-exams.component.css']
})
export class EmployerManageExamsComponent implements OnInit {

  exams: Exam[];

  constructor(private route: ActivatedRoute, private examService: ExamService) { }

  ngOnInit(): void {
    this.examService.getExamsByJobForEmployer(this.route.snapshot.params.id).subscribe(
      data => {
        this.exams = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  selectExamCandidate(examId) {
    this.examService.updateExamStatus(examId, "Selected").subscribe(
      data => {
        this.ngOnInit();
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  rejectExamCandidate(examId) {
    this.examService.updateExamStatus(examId, "Rejected").subscribe(
      data => {
        this.ngOnInit();
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

}
