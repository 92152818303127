import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { Education } from 'src/app/models/education';
import { Work } from 'src/app/models/work';
import { SkillList } from 'src/app/models/skill-list';
import { Languages } from 'src/app/models/languages';
import { Skills } from 'src/app/models/skills';
import { UserService } from 'src/app/_services/user.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { FileUploadModule, FileUploader } from 'ng2-file-upload';
import { GlobalConstants } from 'src/app/global-constants';
import { Country } from 'src/app/models/country';
import { GeneralUtilService } from 'src/app/_services/general-util.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import {map, startWith} from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';


const URL = GlobalConstants.apiURL + 'api/updateUserProfilePic';
const URL_RESUME = GlobalConstants.apiURL + 'api/updateUserResume';

@Component({
  selector: 'app-candidates-profile',
  templateUrl: './candidates-profile.component.html',
  styleUrls: ['./candidates-profile.component.css']
})
export class CandidatesProfileComponent implements OnInit {
  user: User;
  resumeUrl: string;
  imageChanged: boolean = false;
  url: string;
  fileData;
  imageSrc: string;
  skills: string;
  skill: string;
  language: string;
  fileType: string;
  uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'file', autoUpload: true, allowedMimeType: ['image/png', 'image/jpeg'] });
  uploaderResume: FileUploader = new FileUploader({ url: URL_RESUME, itemAlias: 'file', autoUpload: true, allowedFileType: ['pdf', 'doc', 'docx'] });

  jobTitleError: boolean = false;
  skillError: boolean = false;
  descriptionError: boolean = false;
  countryError: boolean = false;
  cityError: boolean = false;
  stateError: boolean = false;
  phoneError: boolean = false;
  syearError: boolean = false;
  eyearError: boolean = false;
  instituteError: boolean = false;
  streamError: boolean = false;
  eduError: boolean = false;
  experienceError: boolean = false;
  genderError: boolean = false;
  educationError: boolean = false;
  educationListError: boolean = false;
  languageError: boolean = false;
  isExamAlert: boolean = false;
  countries: Country[];
  states: any[];
  cityList: any[] = [];
  stateList: any[] = [];
  cities: any[];
  skillList = SkillList.skillList;
  languages = Languages.languages;
  filteredOptions: Observable<string[]>;
  languagesOptions: Observable<string[]>;
  myControl = new FormControl();
  languagesControl = new FormControl();
  test: boolean = false;
  eduListError: any[];
  eduListStartYearError: any[];
  eduListEndYearError: any[];
  eduListInstituteError: any[];
  eduListStreamError: any[];
  eduDetailsError: boolean = false;


  fileContent: string | null = null;

  
  constructor(private userService: UserService, private tokenStorageService: TokenStorageService, private utilService: GeneralUtilService, private http: HttpClient) { }

  ngOnInit(): void {
    this.eduListError = [];
    this.eduListStartYearError = [];
    this.eduListEndYearError = [];
    this.eduListInstituteError = [];
    this.eduListStreamError = [];
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    this.languagesOptions = this.languagesControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._languagesFilter(value))
      );

    this.uploader.onAfterAddingFile = (file) => {
      file.file.name = this.user._id + ".jpg";
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.url = GlobalConstants.apiURL + JSON.parse(response).path;
      this.userService.updateImageUrl(currentUser.email).subscribe(
        data => {
          this.user = data;
          alert("Uploaded Successfully");
          window.location.reload();
        },
        err => {
          alert(JSON.parse(err.error).message);
        }
      );
    };

    this.uploaderResume.onAfterAddingFile = (file) => {
      if (file.file.type == "application/pdf") {
        file.file.name = this.user._id + ".pdf";
        this.fileType = "pdf";
      } else {
        file.file.name = this.user._id + ".doc";
        this.fileType = "doc";
      }
      file.withCredentials = false;
    };
    this.uploaderResume.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.resumeUrl = JSON.parse(response).destination + "/" + JSON.parse(response).filename;
      this.userService.updateResumeUrl(currentUser.email, this.resumeUrl, this.fileType).subscribe(
        data => {
          this.user = data;
          alert("Uploaded Successfully");
          this.ngOnInit();
        },
        err => {
          alert(JSON.parse(err.error).message);
        }
      );
    };

    this.url = "assets/images/resource/candidateprofile.png";
    this.skills = "";
    const currentUser = this.tokenStorageService.getUser();
    this.isExamAlert = currentUser.examAlert;
    this.userService.getByUsername(currentUser.email).subscribe(
      data => {
        this.user = data;
        if (this.user.imageUrl) {
          this.url = GlobalConstants.apiURL + this.user.imageUrl;
        }
        if (this.user.resumeUrl) {
          this.resumeUrl = GlobalConstants.apiURL + this.user.resumeUrl;
	  this.http.get(this.resumeUrl, { responseType: 'text' }).subscribe({
            next: (data) => {
              this.fileContent = data;
            },
            error: (error) => {
              console.error('Error fetching file data:', error);
            }
          });
        }
        if (this.user.skills) {
          for (let index = 0; index < this.user.skills.length; index++) {
            const element = this.user.skills[index];
            this.skills = this.skills + element;
            if (this.user.skills.length != index + 1) {
              this.skills = this.skills + ", ";

            }
          }
        }
      },
      err => {
        alert(JSON.parse(err.error).message);
      }
    );

    this.utilService.getAllCountries().subscribe(data => {
      this.countries = data;
      if (this.user.state) {
        this.getStates(this.user.country);
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.skillList.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _languagesFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  disableFields(i) {
    this.user.workList[i].endYear = null;
    this.user.workList[i].endMonth = "";    
  }

  onSubmit() {
    this.invalidtitle();
    this.invalidskill();
    this.invaliddescription();
    this.invalidcountry();
    this.invalidstate();
    this.invalidcity();
    this.invalidexperience();
    this.invalidgender();
    this.invalideducation();
    this.invalidlanguage();
    this.invalidEducationList();
    this.invalidEduListError();
    this.invalidPhone();
    if (!this.stateError && !this.phoneError && !this.eduDetailsError && !this.jobTitleError && !this.skillError && !this.countryError && !this.cityError && !this.syearError && !this.eyearError && !this.instituteError && !this.streamError && !this.eduError && !this.experienceError && !this.genderError && !this.educationError && !this.educationListError && !this.languageError) {
      this.userService.updateUserProfile(this.user).subscribe(
        data => {
          this.user = data;
          alert("Updated");
          window.location.reload();
        },
        err => {
          alert(JSON.parse(err.error).message);
        }
      );
    }
    else {
      alert("Please fill mandatory fields");
    }

  }

  getStates(countryName): any {
    this.states = [];
    this.stateList = [];
    this.utilService.getCitiesFromCountyName(countryName).subscribe(data => {

      data[0].states.map((stateList) => {
        this.stateList.push(stateList)
      }
      );
      for (var i = 0; i < this.stateList.length; i++) {
        this.states = this.states.concat(this.stateList[i])
      }
      this.cities = [];
      this.cityList = [];
      for (var i = 0; i < this.stateList.length; i++) {
        if (this.stateList[i].name == this.user.state) {
          this.cityList.push(this.stateList[i].cities);
        }
      }
      for (var i = 0; i < this.cityList.length; i++) {
        this.cities = this.cities.concat(this.cityList[i])
      }
    });
  }

  getCities(statename): any {
    this.cities = [];
    this.cityList = [];
    for (var i = 0; i < this.stateList.length; i++) {
      if (this.stateList[i].name == statename) {
        this.cityList.push(this.stateList[i].cities);
      }
    }
    for (var i = 0; i < this.cityList.length; i++) {
      this.cities = this.cities.concat(this.cityList[i])
    }
  }

  onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      file.originalname = this.user._id + ".png";
      file.newname = this.user._id + ".png";
      this.fileData = file;
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result.toString();
      reader.readAsDataURL(file);
      this.uploadProfilePic();
    }
  }

  uploadProfilePic() {
    const uploadData = new FormData();
    uploadData.append('file', this.fileData);
    uploadData.append('user', this.user._id);
    this.userService.updateUserProfilePic(uploadData).subscribe(
      data => {
        console.log("data====" + data);
      },
      err => {
        alert(JSON.parse(err.error).message);
      }
    );
  }

  resendActivationMail() {
    this.userService.resendActivationMail(this.user._id).subscribe(
      data => {
        alert("Check your mail")
      },
      err => {
        alert(JSON.parse(err.error).message);
      }
    );
  }

  addEducation() {
    this.user.educationList.push(new Education);
  }
  deleteEducation(i) {
    this.user.educationList.splice(i, 1);
  }
  addWork() {
    this.user.workList.push(new Work);
  }
  deleteWork(i) {
    this.user.workList.splice(i, 1);
  }
  addSkills() {
    this.user.skillList.push(new Skills);
  }
  deleteSkills(i) {
    this.user.skillList.splice(i, 1);
  }
  addSkill() {
    if (this.skill == undefined || this.skill == "") {
      this.skillError = true;
    } else {
      this.skillError = false;
      this.user.skills.push(this.skill);
      this.skill = "";
    }
  }
  deleteSkill(i) {
    this.user.skills.splice(i, 1);
  }
  addLanguage() {
    if (this.language == undefined || this.language == "") {
      this.languageError = true;
    } else {
      this.languageError = false;
      this.user.languages.push(this.language);
      this.language = "";
    }
  }
  deleteLanguage(i) {
    this.user.languages.splice(i, 1);
  }
  videoResume() {
    window.location.href = 'upload_resume';
  }

  resumeTemplate() {
    window.location.href = 'resume_template/'+this.user._id;
  }

  invalidtitle() {

    if (this.user.jobTitle == undefined || this.user.jobTitle == "") {
      this.jobTitleError = true;
    } else {
      this.jobTitleError = false;
    }
  }

  invalidskill() {
    if (this.user.skills == undefined || this.user.skills.length == 0) {
      this.skillError = true;
    } else {
      this.skillError = false;
    }
  }
  invaliddescription() {
    if (this.user.description == undefined || this.user.description == "") {
      this.descriptionError = true;
    } else {
      this.descriptionError = false;
    }
  }

  invalidcountry() {
    if (this.user.country == undefined || this.user.country == "") {
      this.countryError = true;
    } else {
      this.countryError = false;
    }
  }

  invalidstate() {
    if (this.user.state == undefined || this.user.state == "") {
      this.stateError = true;
    } else {
      this.stateError = false;
    }
  }

  invalidcity() {
    if (this.user.city == undefined || this.user.city == "") {
      this.cityError = true;
    } else {
      this.cityError = false;
    }
  }

  invalidPhone() {
    if (this.user.phone == undefined || this.user.phone == "") {
      this.phoneError = true;
    } else {
      this.phoneError = false;
    }
  }

  invalidexperience() {
    if (this.user.experienceYear == undefined || this.user.experienceYear == null) {
      this.experienceError = true;
    } else {
      this.experienceError = false;
    }
  }

  invalidgender() {
    if (this.user.gender == undefined || this.user.gender == "") {
      this.genderError = true;
    } else {
      this.genderError = false;
    }
  }

  invalideducation() {
    if (this.user.education == undefined || this.user.education == "") {
      this.educationError = true;
    } else {
      this.educationError = false;
    }
  }

  invalidEducationList() {
    if (this.user.educationList == undefined || this.user.educationList.length == 0) {
      this.educationListError = true;
    } else {
      this.educationListError = false;
    }
  }

  invalidlanguage() {
    if (this.user.languages == undefined || this.user.languages.length == 0) {
      this.languageError = true;
    } else {
      this.languageError = false;
    }
  }

  invalidEduListError() {
    var isError = false;
    this.eduListError = [];
    this.eduListStartYearError = [];
    this.eduListEndYearError = [];
    this.eduListInstituteError = [];
    this.eduListStreamError = [];
    for (let index = 0; index < this.user.educationList.length; index++) {
      const element = this.user.educationList[index];
      if (element.educationLevel == undefined || element.educationLevel == "") {
        this.eduListError.push(true);
        isError = true;
      } else {
        this.eduListError.push(false);
      }
      if (element.stream == undefined || element.stream == "") {
        this.eduListStreamError.push(true);
        isError = true;
      } else {
        this.eduListStreamError.push(false);
      }
      if (element.institute == undefined || element.institute == "") {
        this.eduListInstituteError.push(true);
        isError = true;
      } else {
        this.eduListInstituteError.push(false);
      }
      if (element.endYear == undefined || element.endYear == null) {
        this.eduListEndYearError.push(true);
        isError = true;
      } else {
        this.eduListEndYearError.push(false);
      }

      if (element.startYear == undefined || element.startYear == null) {
        this.eduListStartYearError.push(true);
        isError = true;
      } else {
        this.eduListStartYearError.push(false);
      }
    }
    if (isError) {
      this.eduDetailsError = true;
    } else {
      this.eduDetailsError = false;
    }
  }

}
