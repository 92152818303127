import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from '../global-constants';
import { Exam } from '../models/exam';
import { ExamService } from '../_services/exam.service';

@Component({
  selector: 'app-admin-exam-details',
  templateUrl: './admin-exam-details.component.html',
  styleUrls: ['./admin-exam-details.component.css']
})
export class AdminExamDetailsComponent implements OnInit {

  exam: Exam;
  videoExam: boolean;
  writtenExam: boolean;
  apiURL = GlobalConstants.apiURL;
  question: string;
  answer: string;
  remarks: string;

  constructor(private route: ActivatedRoute, private examService: ExamService) { }

  ngOnInit(): void {
    this.examService.getExamById(this.route.snapshot.params.id).subscribe(
      data => {
        this.exam = data;
        this.remarks = this.exam.remarks;
        if (this.exam.examType == 'Video Test') {
          this.videoExam = true;
          this.writtenExam = false;
          this.question = this.exam.questions[0].question;
          this.answer = this.apiURL + this.exam.answers[0];
        } else {
          this.videoExam = false;
          this.writtenExam = true;
        }
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );

  }

  changeQuestion(i) {
    this.question = this.exam.questions[i].question;
    this.answer = this.apiURL + this.exam.answers[i];
  }

  approveExam(examId) {
    this.examService.approveExam(examId).subscribe(
      data => {
        alert("Approved!!")
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }
  
}
