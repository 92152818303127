<!-- <div class="col-md-12">
  <div class="card card-container">
    <img
      id="profile-img"
      src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
      class="profile-img-card"
    />
    <form
      *ngIf="!isLoggedIn"
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
    >
      <div class="form-group">
        <label for="username">Username</label>
        <input
          type="text"
          class="form-control"
          name="username"
          [(ngModel)]="form.username"
          required
          #username="ngModel"
        />
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="f.submitted && username.invalid"
        >
          Username is required!
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          class="form-control"
          name="password"
          [(ngModel)]="form.password"
          required
          minlength="6"
          #password="ngModel"
        />
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="f.submitted && password.invalid"
        >
          <div *ngIf="password.errors.required">Password is required</div>
          <div *ngIf="password.errors.minlength">
            Password must be at least 6 characters
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary btn-block">
          Login
        </button>
      </div>
      <div class="form-group">
        <div
          class="alert alert-danger"
          role="alert"
          *ngIf="f.submitted && isLoginFailed"
        >
          Login failed: {{ errorMessage }}
        </div>
      </div>
    </form>

    <div class="alert alert-success" *ngIf="isLoggedIn">
      Logged in as {{ roles }}.
    </div>
  </div>
</div> -->


<div class="col-md-12">
    <div class="account-popup card card-container">
        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
            <h3 class="popup-heading">Login</h3>
            <!-- <div class="select-user">
          <span>Candidate</span>
          <span>Employer</span>
      </div> -->
            <div class="cfield" style="margin-top: 30px;">
                <input type="text" class="form-control" name="username" [(ngModel)]="form.username" required #username="ngModel" placeholder="Email id" />
                <i class="la la-user"></i>
                <div class="alert-danger" role="alert" *ngIf="f.submitted && username.invalid">
                    Email id is required!
                </div>
            </div>
            <div class="cfield">
                <input type="password" class="form-control" name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel" placeholder="********" />
                <i class="la la-key"></i>
                <div class="alert-danger" role="alert" *ngIf="f.submitted && password.invalid">
                    <div *ngIf="password.errors.required">Password is required</div>
                    <div *ngIf="password.errors.minlength">
                        Password must be at least 6 characters
                    </div>
                </div>
            </div>
            <p class="remember-label">
                <input type="checkbox" name="cb" id="cb1"><label for="cb1">Remember me</label>
            </p>
            <a href="reset_password" title="">Forgot Password?</a>
            <button type="submit">Login</button>
            <div class="cfield">
                <div class="alert-warning" *ngIf="f.submitted && isLoginFailed">
                    Login failed!<br />{{ errorMessage }}
                </div>
            </div>
        </form>
        <p>Don't have an account? <a class="signup-popup" title="" style="color: #910B08;">Sign up </a></p>
    </div>
</div>