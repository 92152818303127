export class Company {
    name: string;
    establishedYear: number;
    size: number;
    categories: string[];
    website: string;
    facebookProfile: string;
    linkedinProfile: string;
    city: string;
    about: string;
    state: string; 
    country: string;
    createdOn: Date;
    modifiedOn: Date;
    industry: String;
    imageUrl: String;
    _id: any;
    youtubeVideo: string;
    bannerUrl: string;
}