import { Benefits } from './benefits';
import { Company } from './company';
import { Questions } from './questions';
import { User } from './user';

export class Job { 
    title: string;
    description: string;
    type: string;
    categories: [];
    offerdSalary: string;
    negotiable: boolean;
    currencyType: string;
    careerLevel: string;
    experience: number;
    industry: string;
    qualification: string;
    dueDate: Date;
    skills: string[];
    responsibilities: string[];
    city: string; 
    state: string; 
    country: string;
    address: string;
    postedBy: string;
    postedOn: Date;
    _id: any;
    bannerUrl: string;
    appliedUsers: User[];
    shortlistedUsers: User[];
    examType: string;
    examTime: number;
    examQuestions: Questions[];
    examQuestions2: Questions[];
    company: Company;
    rejectedUsers: User[];
    reference: string;
    youtubeLink: string;
    benefits: Benefits;
}