import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-constants';
import { Exam } from 'src/app/models/exam';
import { ExamService } from 'src/app/_services/exam.service';

@Component({
  selector: 'app-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.css']
})
export class ExamListComponent implements OnInit {
  exams: Exam[];
  displayExams: Exam[];
  apiURL = GlobalConstants.apiURL;
  videoExams: Exam[] = [];
  writtenExams: Exam[] = [];
  isVideoExam: boolean = false;
  isWrittenExam: boolean = false;

  constructor(private examService: ExamService) { }

  ngOnInit(): void {
    this.examService.getAllExams().subscribe(
      data => {
        this.exams = data;
        this.exams.forEach(element => {
          if (element.examType == "Online Test") {
            this.writtenExams.push(element);
          } else {
            this.videoExams.push(element);
          }
        });
        this.isVideoExam = true;
        this.displayExams = this.videoExams;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  approveExam(examId) {
    this.examService.approveExam(examId).subscribe(
      data => {
        this.ngOnInit();
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  showVideoExam(){
    this.isWrittenExam = false;
    this.isVideoExam = true;
    this.displayExams = this.videoExams;
  }

  showWrittenExam() {
    this.isWrittenExam = true;
    this.isVideoExam = false;
    this.displayExams = this.writtenExams;
  }


}
