import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from 'src/app/global-constants';
import { Company } from 'src/app/models/company';
import { Job } from 'src/app/models/job';
import { User } from 'src/app/models/user';
import { CompanyService } from 'src/app/_services/company.service';
import { JobService } from 'src/app/_services/job.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-employers-detail',
  templateUrl: './employers-detail.component.html',
  styleUrls: ['./employers-detail.component.css']
})
export class EmployersDetailComponent implements OnInit {

  company: Company;
  jobs: Job[];
  apiURL = GlobalConstants.apiURL;
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  currentUser;
  user: User;
  isFollowing: boolean = false;
  isLoggedIn: boolean = false;

  constructor(private route: ActivatedRoute, private companyService: CompanyService, private jobService: JobService, private userService: UserService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.currentUser = this.tokenStorageService.getUser();
    this.companyService.getCompanyById(this.route.snapshot.params.id).subscribe(
      data => {
        this.company = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );  
    this.jobService.getJobsByCompany(this.route.snapshot.params.id).subscribe(
      data => {
        this.jobs = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
    if (this.currentUser) {
      this.isLoggedIn = true;
      this.userService.getUserById(this.currentUser.id).subscribe(
        data => {
          this.user = data;
          data.favouriteCompanies.forEach(element => {
            if (element._id == this.company._id) {
              this.isFollowing = true;
            }
          });
        },
        err => {
         alert(JSON.parse(err.error).message);
        }
      ); 
    } 
  }

  addFavourite() {
    this.userService.addFavouriteCompany(this.company._id, this.user._id).subscribe(
      data => {
        this.user = data;
        this.isFollowing = true;
        alert("Successfully following the company !!!!")
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }

}
