<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/c2c-html/candidates_profile.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:13 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Exam</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

    <!-- Styles -->

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <div class="col-lg-12 column">
                            <div class="padding-left">
                                <div class="profile-title">
                                    <h3>Online Exam Details</h3><br>
                                    <ul class="action_job">
                                        <li><span>Select</span><button type="button" class="select-btn" (click)="selectExamCandidate(exam._id)"><i class="la la-check"></i>Select</button></li>
                                        <li><span>Reject</span><button type="button" class="reject-btn" (click)="rejectExamCandidate(exam._id)"><i class="la la-close"></i>Reject </button></li>
                                    </ul>
                                    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate style="margin: 150px 0;">
                                        <div class="cfield">
                                            <h5>Add Remarks</h5>
                                            <input type="text" name="remarks" [(ngModel)]="remarks" placeholder="Remarks" />

                                        </div>
                                        <button type="submit" class="select-btn">Add Remarks</button>
                                    </form>
                                </div>
                                <div class="profile-form-edit" style="padding: 40px;" *ngIf="writtenExam">
                                    <div class="row">
                                        <div class="col-lg-12" *ngFor="let question of exam.questions; let i = index" style="margin-bottom: 20px;">
                                            <h5>{{i+1}}. {{question.question}}</h5>
                                            <div class="pf-field">
                                                <p>{{exam.answers[i]}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-form-edit" style="padding: 40px;" *ngIf="videoExam">
                                    <!-- <div class="row">
                                        <div class="col-lg-12" *ngFor="let question of exam.questions; let i = index" style="margin-bottom: 20px;">
                                            <h5>{{i+1}}. {{question.question}}</h5>
                                            <div class="pf-field">
                                                <div class="video">
                                                    <video controls #videoPlayer>
                                                        <source src="{{apiURL}}{{exam.answers[i]}}" type="video/mp4" />
                                                        Browser not supported
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="video-block-right-list section-padding">
                                        <div class="row mb-4">
                                            <div class="col-md-8">
                                                <h5>{{question}}</h5>

                                                <div class="single-video">
                                                    <video [src]="answer" controls #videoPlayer>
                                                        Browser not supported
                                                    </video>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="video-slider-right-list" *ngFor="let question of exam.questions; let i = index">
                                                    <div class="video-card video-card-list" (click)="changeQuestion(i)">
                                                        <div class="video-card-image">
                                                            <i class="fas fa-play-circle"></i>
                                                            <img class="img-fluid" [src]="apiURL+exam.candidate.imageUrl" onerror="this.src='assets/images/resource/employee.png';">
                                                        </div>
                                                        <div class="video-card-body">
                                                            <div class="video-title">
                                                                {{i+1}}. {{question.question}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>





</body>

<!-- Mirrored from grandetest.com/theme/c2c-html/candidates_profile.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:14 GMT -->

</html>