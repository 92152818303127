import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-constants';
import { User } from 'src/app/models/user';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {

  users: User[];
  displayUsers: User[];
  employerList: User[];
  employeeList: User[];
  apiURL = GlobalConstants.apiURL;
  searchEmployee: string;
  searchEmployer: string;
  isEmployee: boolean;
  isEmployer: boolean;
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(private userService: UserService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.employeeList = [];
    this.employerList = [];
    this.searchEmployee = "";
    this.searchEmployer = "";
    this.isEmployee = true;
    this.isEmployer = false;
    const currentUser = this.tokenStorageService.getUser();
    this.userService.getAllUsers().subscribe(
      data => {
        this.users = data;
        this.users.forEach(user => {
          if (user.isActive) {
            user.roles.forEach(role => {
              if (role.name == "employer" || role.name == "sub-employer") {
                this.employerList.push(user);
              } else {
                this.employeeList.push(user);
              }
              this.displayUsers = this.employeeList;
            });
          }
          
        });
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );    
  }


  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }

  updateStatus(userId) {
    this.userService.updateUserStatus(userId).subscribe(
      data => {
        this.ngOnInit();
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );    
  }

  deleteUser(userId) {
    alert(userId);
    this.userService.deleteUser(userId).subscribe(
      data => {
        this.ngOnInit();
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  showEmployer(){
    this.isEmployee = false;
    this.isEmployer = true;
    this.displayUsers = this.employerList;
  }

  showEmployee() {
    this.isEmployee = true;
    this.isEmployer = false;
    this.displayUsers = this.employeeList;
  }

  employeefilter() {
    var temp = this.employeeList;
    this.displayUsers = [];
    temp.forEach(element => {
      if (element.firstName.toLowerCase().includes(this.searchEmployee.toLowerCase()) || element.lastName.toLowerCase().includes(this.searchEmployee.toLowerCase()) || element.email.toLowerCase().includes(this.searchEmployee.toLowerCase())) {
        this.displayUsers.push(element);
      }
    });
  }

  employerfilter() {
    var temp = this.employerList;
    this.displayUsers = [];
    temp.forEach(element => {
      if (element.firstName.toLowerCase().includes(this.searchEmployer.toLowerCase()) || element.lastName.toLowerCase().includes(this.searchEmployer.toLowerCase()) || element.email.toLowerCase().includes(this.searchEmployer.toLowerCase()) || (element.company.name && element.company.name.toLowerCase().includes(this.searchEmployer.toLowerCase()))) {
        this.displayUsers.push(element);
      }
    });
  }
}
