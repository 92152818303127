import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-constants';
import { Job } from 'src/app/models/job';
import { User } from 'src/app/models/user';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-candidates-sortlisted-job',
  templateUrl: './candidates-sortlisted-job.component.html',
  styleUrls: ['./candidates-sortlisted-job.component.css']
})
export class CandidatesSortlistedJobComponent implements OnInit {

  user: User;
  jobs: Job[];
  isExamAlert: boolean = false;
  apiURL = GlobalConstants.apiURL;

  constructor(private userService: UserService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    const currentUser = this.tokenStorageService.getUser();
    this.isExamAlert = currentUser.examAlert;
    this.userService.getByUsername(currentUser.email).subscribe(
      data => {
        this.user = data;
        this.jobs = data.shortlistedJobs;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

}
