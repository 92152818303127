export class SkillList {
    public static skillList = [".Net",
".NET  Architecture",
".NET Framework",
"2G",
"3D",
"3D Smax",
"3Ds Max",
"3DSmax",
"3G",
"4G",
"5G",
"Ab initio",
"ABAP",
"Abaqus",
"Abb",
"Abnitio",
"Account Management",
"Account Payable",
"Accounting",
"Accounting Payable",
"Accounting Software",
"Accounts Payable",
"Accounts Receivable",
"Accuracy",
"Acquisition",
"Actionscript",
"Active Directory",
"Active listening",
"Acute care",
"Ad design",
"Adaptability",
"Admin Executive",
"Administration",
"Administration Work",
"ADO.NET",
"ADO.NET MVC",
"Adobe",
"Adobe After Effects",
"Adobe Campaign Manger",
"Adobe Creative Cloud",
"Adobe Creative Suite: Illustrator, InDesign, Photoshop",
"Adobe Experience Manager",
"Adobe Illustrator",
"Adobe InDesign",
"Adobe Photoshop",
"Adobe Photoshop, InDesign",
"Adobe Premier Pro",
"Adobe Premier Pro CC",
"Advance Excel Macros",
"Advance Java",
"Advance Phyton",
"Advanced Cardiac Life Support (ACLS)",
"Advertising",
"Aem",
"Aero Engine",
"Aerodynamics",
"Aeronautical",
"Aeronautical Engineering",
"Aerospace",
"Affiiate Marketing",
"Affiliate Marketing",
"After Sales Service",
"After Sales Services",
"Aggregate",
"Aggressive",
"Agile",
"Agile Business Analysis",
"Agile Development",
"Agile Methodology",
"Agile Testing",
"Agriculture",
"Agriculture Marketing",
"Aha",
"Ahb",
"Ahp",
"Ahu",
"Ahus",
"Air Cargo",
"Air Conditioning",
"Air Ticketing",
"Airbrake",
"Airlines",
"Airport",
"AirWatch",
"Aix",
"Aix Administration",
"Ajax",
"Akamai",
"Akka",
"Alcatel",
"Alfresco",
"Algorithmic Trading",
"algosec",
"Allen Bradley",
"Allen Bradley Pic",
"Allen Bradley Plc",
"Allocation",
"ALM",
"Amadeus",
"Amazon Ec2",
"Amazon Web Services",
"Amc",
"Amdocs",
"American",
"AML",
"Amp",
"Analysis",
"Analytical Skills",
"Analytics",
"Analytics Sales Solution",
"Analytics SAS/R",
"Analytics- Tableau",
"Android",
"Android App Testing",
"Android Development",
"Android Development & Testing",
"Android iOs, Ipad",
"Android SDK",
"Android STB",
"Android Studio",
"AngluarJS",
"Angular2",
"Angularjs",
"AngularJS 4",
"Animal Husbandry",
"Animation",
"Animation Designing",
"Ansible",
"Ansible Automation",
"Ansys Cfx",
"Ansys Fluen",
"Anti Money Laundering",
"Antivirus EPO",
"Aod",
"Aoi",
"Aol",
"Aop",
"Apache",
"Apache  Struts 2",
"Apache Drill",
"Apache Tomcat",
"Apache Webserver",
"ApacheHadoop",
"Apachelgnite",
"ApacheSpack",
"ApacheSpark",
"Apache-Tomcat-Camel",
"API",
"API Integration",
"API testing",
"APICS",
"APM",
"App Analytics",
"App Testing",
"AppDynamics",
"Appium",
"Appium Automation Testing",
"Application Architecture",
"Application Development",
"Application Packaging",
"Application Programming Interface (API)",
"Application Secuirty",
"Application Support",
"Application Support  Analyst",
"Application Testing",
"Aql",
"Aqua",
"Aquaculture",
"AR Calling",
"AR Calling",
"ARC GIS",
"Archer",
"Architectural Design",
"Architecture",
"ArcSDE",
"ArcSight",
"ArcSight Security",
"Arduino",
"Army",
"Articles",
"AS400",
"ASCL",
"ASP.Net",
"ASP.Net Ajax",
"ASP.Net MVC",
"Asset Management",
"ATG",
"Atg Commerce",
"ATL",
"ATM",
"ATM Operations",
"Atpg",
"Attention to detail",
"Attention to detail & aesthetics",
"Attrition",
"Auditing",
"AutoCAD",
"Autocad Drafting",
"Automation ",
"Automation of AwS",
"Automation Testing",
"Automobile",
"Automobile Engineering",
"Avamar",
"Avaya",
"Aviation",
"Avionics",
"Avp",
"AWK",
"AWR",
"AWS",
"AWS-CWI",
"AWT",
"Axapta",
"Axis",
"Axure",
"Ayurveda",
"B2B Marketing",
"Back Office",
"Back Office Operations",
"Backend",
"Backend Operations",
"Background Verification",
"Banking",
"Banking Operations",
"Basic knowledge of user interface communication",
"Bba",
"Bbm",
"Bbs",
"Bca",
"Bcm",
"Bcms",
"Bcmsn",
"Bcom",
"Bcp",
"Bdc",
"Bdcs",
"Bde",
"Bdm",
"Bds",
"Beauty",
"Bench Sales",
"Beverage",
"Bfsi Sales",
"Bgas",
"Bhm",
"Bhms",
"Bidding",
"Big Data",
"Big Data Analysis & SQL",
"Big Data analytics",
"Big Data Architect",
"Big Data Platforms",
"Big Data tools ",
"BigFix",
"BigQuery",
"BIM",
"Biochemistry",
"Biometrics",
"BIOS",
"Biotechnology",
"Biztalk",
"Black Box Testing",
"Blackberry",
"Blast Furance",
"Block chain",
"Blogger",
"Blogs",
"Bluetooth",
"Bmc ",
"Bmc Patrol",
"BMC Patrol Express",
"Bmc Remedy",
"Bmi",
"Bmr",
"Bms System",
"Board Design",
"Boiler",
"Boiler Maintenance",
"Bookkeeping through Excel or TurboTax",
"Bootstrap",
"Bootstrap.js",
"Botany",
"BPM",
"BPM Application Developer",
"BPO",
"BPO Non Voice",
"BPO Operations",
"Bpo Presales",
"Bpo Solutions",
"BPR",
"Branch Banking",
"Brand Awareness",
"Brand management",
"Branding",
"Brewery",
"BRM",
"Broking",
"Brute Force Attack",
"BSCS",
"BSS",
"Btg",
"Btl Activation",
"BTS",
"BTS Commissioning",
"BTS Installation",
"Budegting",
"Bugzilla",
"Build & Release",
"Business",
"Business Alliances",
"Business Analysis",
"Business Analytics",
"Business Continuity",
"Business Continuity Planning",
"Business Development",
"Business Events",
"Business Intelligence",
"Business Intelligence Developer",
"Business Management Skills",
"Business Objects",
"Business Operations",
"Business Planning",
"Business Process",
"Business Process Modeling",
"Business Rules",
"Business Strategist",
"Buyer engagement",
"Buyer-Responsive selling",
"C & C++",
"C#",
"C# ASP.NET",
"C/C++ Architect",
"C++",
"CA NSM",
"CA Tools",
"Cache",
"CAD",
"Caffe",
"CakePHP",
"Calabash",
"Call Center",
"Campaign Management",
"Canva",
"Capacity Planning",
"Capacity Testing",
"Capital IQ",
"Capital Markets",
"Captiva",
"Cassandra",
"CATIA",
"Cbi",
"Cbio",
"Cbm",
"Cbs",
"Cbse",
"Cbt",
"Cce",
"Ccie",
"Ccie Security",
"CCNA",
"CCNP",
"CCSA",
"CCSE",
"CCTV",
"Cdd",
"Cdh",
"Cdm",
"CDMA",
"CDN",
"Cdp",
"Cds",
"Ce",
"CEH",
"Cement",
"Cement Plant",
"CentOs",
"Central Excise",
"Ceph Cloud storage",
"Ceramics",
"Certified Ethical Hacker",
"CFA",
"CFD",
"Cfo",
"CFP",
"CFS",
"CG",
"CG Lux",
"CG Lux",
"CGI",
"CGMP",
"Change Delivery",
"Change Management",
"Channel Management",
"Channel Sales",
"Charisma",
"Chartered Accountant",
"ChatBots",
"Checkpoint",
"Checkpoint CCSM",
"Chef",
"Chemical",
"Chemical Engineering",
"Chipset",
"CI/CD",
"Circuit  Design",
"CISA",
"Cisco",
"Cisco CCDA",
"Cisco CCDP",
"Cisco CCIE",
"Cisco CCNA",
"Cisco CCNP",
"Cisco CCSP",
"Cisco ICND1",
"Cisco ICND2",
"Cisco IOS",
"Cisco IP Telephony",
"Cisco UCCE",
"CISSP",
"CITRIX",
"Citrix CCP-N",
"Citrix Networking",
"Citrix Virtualization",
"Civil Construction",
"Civil Engineering",
"Civil Site Engineering",
"CIW Web Development Series",
"Clarion",
"Clarity and concision",
"Classic ASP",
"Clear communication",
"Clearcase",
"Clevtap",
"Client Acquisition",
"Client Engagement",
"Client Handling",
"Client Management",
"Client Onboarding",
"Client Relationships",
"Client Retention",
"Client Servicing",
"Clincial Psychology",
"Clinical Data Management",
"Clinical Research",
"Closing",
"Cloud",
"Cloud Architecture",
"Cloud Computing",
"Cloud Essentials",
"Cloud networking and file sharing ",
"Cloud Testing",
"Cloudera",
"Cloudera Administrator",
"Cloudera CCAH",
"Cloudera Cloudera Hbase",
"Cloudera Developer",
"CloudStack",
"Clustering",
"CMA",
"CMC",
"CMM",
"Cmm Inspection",
"CMMI",
"CMOS",
"CMS",
"CMS Tools",
"CMSS",
"CNC",
"CNC M",
"CNC Machines",
"CNC Maintenance",
"CNC Programming",
"CNC Turning",
"COBOL",
"Cocoa",
"Code Coverage",
"Coded UI",
"Codeigniter",
"Codigniter",
"Cognos",
"Cognos Analytics (IBM)",
"Cold-calling",
"Collaterals Management",
"Color sense & theory",
"Commitment",
"Communication",
"Communication Skills",
"CommValut",
"Comp TIA",
"Company Research",
"Company Secretary",
"Compassion",
"Compatibility Testing",
"Compel",
"Compensation and Benefits",
"Compiler",
"Compliance Auditing",
"CompTIA Network+",
"CompTIA Project+",
"CompTIA Security+",
"Computational Fluid Dynamics",
"Computer Hardware",
"Computer Network Architect",
"Computer Operations",
"Computer System Validation",
"Computer Vision",
"Configuring DHCP",
"Construction",
"Construction Equipment",
"Construction Management",
"Consumer Behavior Drivers",
"Consumer Behaviour",
"Consumer Durables",
"Content Management Systems (CMS)",
"Content Writing",
"Contract Negotiation",
"Contractual Agreements",
"Copywriting",
"Cordova",
"Core Java",
"Core PHP",
"Corel Draw",
"CorelDRWA",
"Corporate Affairs",
"Corporate Events",
"Corporate Gifts",
"Corporate HR",
"Corporate Planning",
"Corporate Social Responsibility",
"Cost Accounting",
"Cost Analysis",
"Cost Estimation",
"Cost Managemnet",
"Cost Optimization",
"Cost Reduction",
"Couchbase",
"CouchBase CRUD operations",
"CP",
"CPA",
"CPC",
"Cpg",
"CPLD",
"CPP",
"CPR",
"CPT",
"CQ",
"Cq5",
"Cqa",
"CQTM",
"Cramer",
"Creative Suite",
"Creative Writing",
"Creativity",
"Credit",
"Credit Control",
"Creo",
"Critical thinking",
"CRM",
"CRM Analytics",
"CRM Consulting",
"CRM Presales",
"CRM Sales",
"CRO and A/B Testing",
"Cross Site Scripting",
"CRUD Operations",
"Cryptography",
"Crystal Reports",
"CSA",
"CSC",
"CSD",
"CSE",
"CSG CSG-interconnect",
"CSG-Adaptor",
"CSG-Automated Recon",
"CSG-Financial Manager",
"CSG-Jasper",
"CSG-Roaming",
"CSG-Route",
"CSR",
"CSS",
"CSS preprocessors",
"CSS3",
"CSTM",
"CT Scan",
"CT Scan",
"CTD",
"CTI",
"Cto",
"Ctrm",
"CTV",
"Customer Care",
"Customer Number Mangement",
"Customer Relationship",
"Customer Relationship Management (CRM)",
"Customer Service",
"Customer Support",
"Cvent",
"CVI",
"CVOICE",
"CVP",
"CVS",
"CVT",
"Cwa",
"CWDM",
"CWI",
"CWNA",
"Cyber Forensics",
"Cyber Law",
"Cyber Security",
"Cyber Security ",
"Cyberark",
"Cyberark Privilege Identity Management",
"Cyberoam",
"D3.js",
"Data  Architecture",
"Data analysis",
"Data Analytics",
"Data Architect",
"Data Center",
"Data Center virtualization",
"Data Entry",
"Data Integration",
"Data Integration Testing",
"Data Loss Prevention (DLP)",
"Data Management",
"Data Mapping",
"Data mining",
"Data Modelling",
"Data Modelling and Evaluation",
"Data Quality",
"Data Science",
"Data Structure",
"Data Visualisation",
"Data Warehousing",
"Database",
"Database  Architect",
"Database Activity Monitoring",
"Database Administration",
"Database Operations",
"Database Testing",
"Database-PostgreSQL",
"Datacenter",
"Datastage",
"DataWarehouse Testing",
"Daz Studio",
"Db2",
"Dbs",
"DCA",
"Dce",
"DCF",
"Dch",
"DCOM",
"DCS",
"DCS Operation",
"DDD",
"DDK",
"DDL",
"Ddlc",
"Ddos",
"Ddr",
"Dealing with work-related stress",
"Decision Making",
"Deep Learning",
"Defect Management",
"Defect Reporting",
"Delegation",
"Delivery & Functional Testing",
"Delivery Management",
"Demand Forecasting",
"Deployments",
"DES",
"Design",
"Design and Planning",
"Design Patterns",
"Desktop Support",
"Development Tools",
"Device Driver",
"DevOps",
"Devops Jenkins",
"Devops Management",
"DFA",
"DFC",
"Dfd",
"DFM",
"DFMEA",
"DFS",
"DFT",
"Dha",
"DHCP",
"DHCP Server",
"DHTML",
"Diameter",
"Diamond Decoder Subex",
"Diary",
"Digital Marketing",
"Digital Media",
"Digital Photogrammetry",
"Digital printing",
"Digital Signage",
"Digital Transformation",
"Dip",
"Diploma",
"Direct",
"Disaster Recovery",
"Disater Recovery Planning",
"Disk & File Encryption",
"Dispatch",
"Distributed Computing",
"Distrubtion",
"Django",
"DLC",
"D-Link",
"DLL",
"DLNA",
"Dm Plant",
"Dm Plant",
"Dme",
"Dmf",
"DMI Digital Marketing",
"Dmlt",
"Dms",
"Dmx",
"DNA",
"DNA Extraction",
"DNA Isolation",
"DNB",
"DNS",
"DNS Management",
"DNS Mangement",
"DNS Server",
"Docker",
"Docker Kubernetes",
"Documentation",
"DOJO",
"Dokcer Certification",
"Dolby",
"Domestic BPO",
"DOS",
"DotNet Nuke",
"DP",
"DPI",
"DPM",
"DPR",
"DPT",
"DQ",
"DQL",
"Drafting",
"Dreamweaver",
"Driving",
"DRM",
"Drone Making",
"Drools",
"Drug Regulatory Affairs",
"Drupal",
"Drupual",
"DSA",
"DSM",
"DSP",
"DST",
"DT",
"DTC",
"DTD",
"DTH",
"DTP",
"DTP Operating",
"DTS",
"DTV",
"Dubbing",
"Due Deligence",
"Due Diligence",
"Durables",
"Dutch",
"DWBI",
"DWDM",
"DWH Testing",
"DWM",
"DWR",
"Dynatrace",
"EAI",
"Earth Moving",
"Ebay",
"Ebo",
"EBS",
"E-Business",
"E-business ",
"EC-Council CEH",
"EC-Council ECSA",
"ECE",
"Eclipse",
"Eclipse Rcp",
"ECM",
"E-Commerce",
"Economic Research",
"Economics",
"Editing",
"EDP",
"Education",
"Educational platforms (software like Elearn)",
"EEE",
"EEG",
"EEPROM",
"Effective communication and sociability",
"Effective Communication Skills",
"Effluent Treatment",
"Effluent Treatment Plant",
"EFT",
"Egate",
"EGL",
"E-governance",
"eHealth",
"EHR",
"EHS",
"EHS Management",
"EIA ",
"EIGRP",
"EIM",
"EIP",
"EIS",
"EJB",
"Elastic Search",
"E-learning",
"Electrical Engineering",
"Electrical Maintenance",
"Electricals",
"Electronics",
"Electronics Engineering",
"ELK",
"ELK Stack",
"Eloqua",
"Email marketing",
"Email Support",
"Embedded",
"Embedded C",
"Embedded Systems",
"EmberJS",
"EMC Avamar",
"EMC Storage",
"EMC Storage Administrator",
"Emotional Intelligence",
"Empathy",
"Employee Engagement",
"Employee Relations",
"EMR",
"EMS",
"EMV",
"Energy Efficiency",
"Enerprise Architect",
"Enerprise Fixe Line Testing",
"Engineering",
"Enterprise Administration",
"Enterprise Architecture",
"Enterprise Resource Planning ",
"Enterprise Risk",
"Enthusiasm",
"Entity Framework (ORM)",
"Entity Relationship Diagrams",
"Eqquity Research",
"Equipment Installation",
"Equipment Repair",
"Equity",
"Equity Research",
"Equity Trading",
"Ercission BTS",
"Ercission IIEP",
"Ercission Multi Activator",
"Ercission Network Engineer",
"ERDAS IMAGINE",
"Ericission Granite Inventory 8.1",
"Ericsson",
"Erlang",
"ERP",
"ERP Implementation",
"ERP Navision",
"ERP Sales",
"Error Code Testing",
"ESB",
"ESRI Enterprise Architecture",
"ESRI Software Integration",
"Estimation",
"ESXi",
"Ethernet",
"Ethical Hacking",
"Ethics",
"Ethitcal hacking",
"ETL",
"ETL Testing",
"Etopup/Recharge",
"Event Management",
"Event Marketing",
"Event Planning",
"Event Promotion",
"Events",
"Excel",
"Excel VB",
"Exchange",
"Exchange Server",
"Excise",
"Experian Credit Bureau",
"Exploratory Testing",
"Export",
"Export Documentation",
"Export import",
"Express.js",
"F5  F5-CA",
"F5 Cerifications",
"F5-CTS LTM",
"Fabrication",
"Facebook Apps",
"Facebook ReactJS",
"Facilities",
"Facility Management",
"Facility Management (FM)",
"Facility Mangement",
"Faculty",
"Fail Over Testing",
"Falcon",
"Fashion",
"Fashion Designing",
"Fashion Illustration",
"FatWire",
"FB application",
"FBC",
"FBD",
"FBT",
"FCPA",
"FDA",
"FDM",
"FE Analysis",
"Feature Phone App development",
"Feature Phone Testing",
"Fedora",
"Feed",
"Feedback",
"Fermentation",
"Ferro Alloys",
"Fertilizer",
"Ffmpeg",
"Ffs",
"Fiber Development",
"Fibre handling",
"Fiddler",
"Field Work",
"File System",
"Filenet",
"Filing and paper management",
"Film",
"Finacle",
"Finance",
"Financial Advising",
"Financial Analysis",
"Financial Analyst",
"Financial Consulting",
"Financial Due Deligence",
"Financial Engineering",
"Financial Forecasting",
"Financial Management",
"Financial Modeling",
"Financial Planning",
"Financial Report",
"Financial Reporting",
"Financial Risk Manager",
"Finnone",
"Firewall",
"Firewall Analysis",
"Firewall Support",
"Firework",
"Firmware",
"Fitnesse",
"Flash",
"Flash MX",
"Flask",
"Fleet",
"Flex",
"Flexcube",
"Flexibility",
"Flexible Packaging",
"Fluentd",
"Flume",
"FMCD",
"FMCG Marketing",
"FMCG Sales",
"Fme",
"Fmea",
"FMS",
"FogBugz",
"Food Processing",
"Food Technology",
"Forex",
"Fortinet NSE4",
"Fortinet NSE7",
"Foundry",
"FPA",
"FPD",
"FPGA",
"FPGA Design",
"FPS",
"Fpso",
"Fraud Analysis",
"Fraud Analytics",
"Freight Forwading",
"Freight Forwarding",
"Front Office",
"FSCM",
"FSD",
"FSI",
"FSMO",
"FSMS",
"FT",
"FTA",
"FTIR",
"FTL",
"FTO",
"FTP",
"Full Sack",
"Functional Testing",
"Fund Accounting",
"Fund Raising",
"Furniture",
"FX ",
"FxCop",
"G Steamer",
"GAAP",
"Game Development",
"Gaming",
"GAQM",
"Garments",
"Gas Turbine",
"GC",
"GC Analysis",
"GCC",
"GC-MS",
"GCP",
"General business knowledge",
"General Insurance",
"General Ledger",
"Genesys",
"Genesys CIM8-TS",
"Genesys FRR85-FND",
"Genesys FWK8-FND",
"Genesys GCP8-CVP",
"German Language",
"Gerrit",
"GHP",
"GI",
"GIAC Forensics",
"GIAC GWAPT",
"Gic",
"GIF Animation",
"GIMP",
"GIS",
"GIS Process Automation",
"GIS Software",
"GIS-IN Project Mangement",
"GIS-NE Process",
"GIS-NE Process Automation",
"GIS-NE Project Mangement",
"GIT",
"Github",
"Glassfish",
"Global Delivery",
"Global Mobility",
"Global Sourcing",
"GM IT",
"GMP",
"GNM",
"Gnoc",
"GNU",
"GNU Make",
"Golang",
"Gold Loan",
"Google",
"Google AdWords",
"Google Analytics",
"Google AngularJS",
"Google Big Query",
"Google Maps",
"Governance",
"Govt",
"Govt Slaes",
"GP",
"Gpio",
"Gpon",
"GPP",
"GPRS",
"GPS",
"GPU",
"Gradle",
"Grafana",
"Graphic Designing",
"Graphic Designning",
"Graphic User Interfaces (GUI)",
"Graphics",
"GRC",
"Greenplum",
"Groovy & Grails",
"Grunt",
"GS tools and Platform",
"GSB",
"GSM",
"GSM Network",
"GSM Protocols",
"Gss",
"Gst",
"GStreamer",
"GTA",
"GTAW",
"GTK",
"GTP",
"GTS",
"Gtstrudi",
"Guest Handling",
"Guest House",
"Guest Relations",
"Guest Satisfaction",
"Guest Service",
"GUI",
"GUI Testing",
"Guidewire",
"GWT",
"Gynecology",
"Gypsum",
"Hadoop",
"Hadoop Zookeeper",
"HANA",
"Hardware",
"Hardware Design",
"Hardware Implementation",
"Hardware Networking",
"Hbase",
"HCL",
"HCM",
"HCPCS",
"HCV",
"HDCA",
"HDD",
"Hdfs",
"HDL",
"HDLC",
"HDPE",
"HDR",
"Health Insurance",
"Healthcare",
"Heat Treatment",
"Heavy Engineering",
"Heavy Machinery",
"HelpDesk Support",
"Heroku",
"HFM",
"HFR",
"Hibernate",
"High Availability",
"High Availability Testing",
"High Level Design",
"HIPAA",
"Hive",
"HL7",
"HLOOKUP",
"HLR",
"HMC",
"HMI",
"HMI Programming",
"HMI Software",
"HMS",
"HMT",
"HMTL",
"HNI Acquisition",
"HNI Client Handling",
"HNI Sales",
"Horticulture",
"Hospital",
"Hospital Administration",
"Hospitality",
"Hotel Management",
"Housekeeping",
"Hp Alm",
"HP ALM/Quality Center",
"HP ASE- Server Solutions Architect",
"HP Asset Manager",
"HP CSA",
"HP IT Management",
"HP NA",
"HP NA Tool",
"Hp Networking",
"HP NNMi",
"HP NNMi Tool",
"HP OM",
"HP Omi Tool",
"HP Openview",
"HP Operating Systems",
"HP QTP",
"HP SA",
"HP Server Automation",
"HP Servers",
"HP Service Automation",
"HP TeMIP",
"HP TeMIP Tool",
"HP Tools",
"HP UCA",
"HP uCMOB",
"HP UTM",
"HP OpenView",
"HP QTP",
"HP Service Manager",
"HPEATP- Unified Functional Testing",
"HPLC",
"HPOO",
"HPSM",
"HP-UX",
"HR Administration",
"HR Analytics",
"HR General Activities",
"HR Operations",
"HR Administration",
"HR Generalist Activities",
"HR Operations",
"HRIS",
"HSE",
"HSE Management",
"HSE Training",
"Hsm",
"Hss",
"HSSE",
"HSUPA",
"HT Panels",
"HTC",
"HTML",
"HTML & CSS",
"HTML app testing",
"HTML/XML",
"Html5",
"HTML5 Application Development",
"HTML5 Development",
"HTRI",
"HTTP",
"Huawei",
"Hub",
"Hubspot",
"Human Machine Interface",
"Human Resource Management",
"HVAC",
"Hvac Design",
"HVAC Engineering",
"Hvac Sales",
"HVAC System",
"HVDS",
"Hybrid Automation Framework",
"Hybrid Data Center and Cloud",
"Hybrid Mobile Development",
"Hybris",
"Hydraulics",
"Hyperion",
"Hyperion Essbase",
"Hyperion Financial Management",
"Hyperion Financial Mangement",
"Hyperion Planning",
"HyperLynx",
"HyperMesh",
"Hyper-V",
"I10N Testing",
"I18N Testing",
"Iaas",
"IAM",
"IAR",
"IAS",
"IATA",
"IBM",
"Ibm Bpm",
"IBM Certified",
"IBM Certified solutions Expert",
"IBM Certified Specialist",
"IBM Cloud",
"IBM Cognos  TM1",
"IBM Collaboration Tools",
"IBM Lotus",
"IBM Rational",
"IBM Rational ClearQuest",
"IBM Security",
"IBM Software",
"IBM Solutions",
"IBM Tivoli Monitoring",
"IC Design",
"ICEM CFD",
"ICH-GCP",
"ICH-GCP Guidelines",
"ICM",
"Ideas",
"Ideas NX",
"Identity Access Management",
"Identity Access Mangement",
"Identity Management",
"IDM",
"Idq",
"IEC",
"IEC 17025",
"IEEE",
"IELTS",
"IEX",
"IGAAP",
"IGCSE",
"IGMP",
"IGNOU",
"IGRP",
"IIS",
"ILE",
"Illustration",
"Illustrator",
"Ilm",
"ILOG",
"ILT",
"Image Processing",
"Image Tracer (4.0)",
"Immigration",
"Import",
"IMS",
"Incident Mangement",
"Incident Response",
"Indesign",
"Indirect Taxation",
"Industrial",
"Industrial Design",
"Infection control",
"InfluxDb",
"Infographics",
"Informatica",
"Informatica Power Center",
"Information & Upgrade Testing",
"Information Security ",
"Information Security Analyst",
"Information System Mangement",
"Information Technology",
"Information/Data Privacy",
"Informix",
"Informix 4GI",
"Infosphere",
"Infrastructure Project Planning",
"Infrastructure project scheduling",
"Ink Space",
"Innovation",
"Inside Sales",
"Installation using unix",
"Instructional  Design",
"Insurance",
"Integrated Marketing",
"Integration of visual communication in social media platforms",
"Integration Testing",
"Intense EBPP",
"Interaction Designer",
"Interactive media design",
"Internal Audit",
"Internal Auditor",
"Internal communication",
"Internal Control",
"International BPO",
"International Call Center",
"International Marketing",
"International Sales",
"Internet of Things",
"Interpretation of Data",
"Interrupt Testing",
"Intrusion  Prevention",
"Intrusion Detection",
"In-Vehicle Infotainment",
"Inventory Mangement",
"Investment Banking",
"Investment ResearchInvisiion",
"Invision",
"Invoicing",
"IOF for SIM Cards",
"Ion Exchange",
"Ionic Framework",
"IOS",
"iOS Application Development",
"Ios Development",
"iOs Programming",
"Ios Sdk",
"iOS Testing",
"iOS/Swift",
"IOSH",
"IOT",
"IP Routing",
"IP Telephony",
"Ip Routing",
"IP Telephony",
"IP/IPX based LAN",
"iphone",
"iPhone Development",
"IPR",
"IPTV",
"IPV4",
"IPv6",
"IQC",
"IRIS",
"IRM",
"Iron Casting",
"Iron Ore",
"Ironport",
"Irrigation",
"ISACA CISM",
"ISACA COBIT 5",
"ISACA CRISC",
"ISC2 CISSP",
"ISILON Storage",
"ISO 20000",
"ISO 22301",
"ISO 27001",
"ISO 27001 LA",
"ISO 27017",
"ISO 27018",
"ISO 9000",
"ISO 9001",
"ISP",
"iSTOB Certified Tester",
"IT Helpdesk",
"IT Mangement",
"IT Recuritment",
"IT Management",
"IT Recruitment",
"ITI",
"ITI Electrical",
"ITIL",
"IVR",
"Ivr Testting",
"IVRS",
"J2Ee",
"J2Ee Architecture",
"J2ME",
"Jasper",
"Java",
"Java 8",
"Java 9",
"Java Architect",
"Java Automated Testing",
"Java Development",
"Java Script",
"Java Scriptlets",
"Java SE 7 Programmer",
"Java Swing",
"Javafx",
"Javascript",
"JavaScript Development",
"jBase",
"Jboss",
"Jboss Application Server",
"Jboss Esb",
"JBoss Seam",
"JBPM",
"Jbuilder",
"JCAPS",
"JCB",
"JCL",
"Jco",
"JCP",
"JCP tool",
"JD",
"JD Edwards",
"JDA",
"JDBC",
"JDE",
"Jde Cnc",
"JDK",
"JEE",
"Jenkins",
"Jersey",
"JES2",
"Jetty",
"Jewellery",
"Jewelry",
"Jig Fixture",
"Jigs",
"Jigsaw",
"JIRA",
"JIT",
"Jive",
"Jmeter",
"JMS",
"JMX",
"Job Analysis",
"Job Scheduling",
"Joinery",
"Joomia",
"Joomla",
"Journalism",
"JPA",
"Jquery",
"Jquery Mobile",
"JS",
"JS Docs",
"JSF",
"JSON",
"JSP",
"JTAG",
"Julia",
"Junios Troubleshooting",
"Juniper",
"Juniper JNCIA-Junos",
"Juniper JNCIP-SEC",
"Juniper-Associate",
"Junit",
"Jython",
"K",
"K2",
"K2 Blackpearl",
"K2 BPM",
"K2 BPM Practice",
"K2 BPM SETUP",
"K2 Project Management",
"K2 Smart Form",
"K2 Smart Object",
"K2 Workflow Designing",
"Kafka",
"Kali Linux",
"Kenan",
"Kenan FX",
"Kendo Ui",
"Keras",
"Kernal",
"Key Account Management",
"Key Accounts",
"Key Skills",
"Keyword Driven FrameWork",
"Kibana",
"Kibana Monitoring Metrics",
"Knits",
"Knockout  Framework",
"Know Your Customers (KYC)",
"Knowledge Fusion",
"Knowledge Management",
"KPI",
"KPI Analysis",
"KPI Monitoring",
"KPO",
"Kubermetes",
"KYC",
"LabVIEW",
"LAMP",
"LAN",
"Land Acquisition",
"Laptop/Desktop Staging",
"LATIN",
"LBS",
"LDAP",
"LDOM",
"LDP",
"LDPE",
"LDRA",
"Lead Generation",
"Lean",
"Legal",
"Legal process Outsourcing",
"Lib",
"Licensing",
"LIDAR",
"Liferay",
"LINQ",
"Linuix",
"Linux +",
"Linux Administration",
"Linux Foundation LFCS",
"Linux Kernel",
"Lisp & Visual Lisp  Programming",
"Listening",
"Live Streaming",
"LLD",
"LLDPE",
"Llm",
"LMS",
"Load Runner",
"Load Testing",
"Localization",
"Localization Testing",
"Log Analysis",
"Logical Programming",
"Logistics",
"Logistics Management",
"Logistics Operations",
"Logo creation",
"Loki JS",
"lokijs",
"lonic",
"Lotus Notes",
"Low Code Platforms",
"lua",
"Lucene",
"Lync",
"MAC OS",
"MAC testing",
"Machine Learning  Engineer",
"Machine Learning Algorithms",
"Machine learning ",
"Macromedia Dreamweaver MX",
"Macromedia Flash MX",
"Macros",
"Madme",
"Magneto",
"Magneto Front End Developer",
"Mainframe",
"MainFrames",
"Malware",
"Management Information System",
"Mangement",
"Mantis",
"Manual Functional Testing",
"Manual Testing",
"Map Reduce",
"Map Server",
"MapInfo",
"Market Intelligence",
"Market Reserarch",
"Marketing",
"Marketing Analytics",
"Marketing Automation",
"Marketing Cloud",
"Material Management",
"Mathematics",
"MATLAB",
"Matntis Bug Tracker",
"Maven",
"Maya",
"McAfee",
"McAfee Nitro SIEM",
"McAfee SIEM",
"MCITP",
"Mcommerce",
"MCP",
"MCSA",
"MCTS",
"Mdi",
"MDT",
"MDX",
"MEAN",
"Meanstack",
"Mechanical Engineering",
"Media",
"Mediation Tool",
"Mediation Zone",
"Medical Billing",
"Medical Coding",
"Medical Equipment",
"Medical Transcription",
"MemCache",
"Memory Management",
"Message Broker",
"MetaSolv",
"Meteor.JS",
"MFC",
"MFGPRO",
"Microcontroller",
"Microservices",
"microsoft Azure",
"Microsoft Dynamics",
"Microsoft Dynamics AX",
"Microsoft Dynamics AXAPTA",
"Microsoft Dynamics Gp",
"Microsoft Dynamics Navision",
"Microsoft Excel (Advanced)",
"Microsoft MCDBA",
"Microsoft MCSA",
"Microsoft MCSD",
"Microsoft MCSE",
"Microsoft Word",
"Microsoft Excel",
"Microsoft Access,",
"Microsoft Publisher",
"Microsoft Outlook",
"Microsoft Powerpoint",
"Microsoft Project Plan",
"Microsoft SQL",
"Microsoft Visio",
"Microstation 8.0",
"Microstation J",
"Microstrategy",
"Middleware",
"Midrange",
"MIS",
"MIS Reporting",
"Mitek 20-20 Tuss Designs",
"Mithril.JS",
"Mobie Device Testing",
"Mobile APK Testing",
"Mobile app security",
"Mobile Architect",
"Mobile Builder ArcGIS",
"Mobile Number Portability",
"Mobile Testing",
"Mobile Testing Application",
"Mobile UI",
"Mobile UX",
"Mobile VAS",
"MockTesting Framework",
"Mongo DiBi",
"MongoDB",
"Motivation",
"MPEG",
"MPLS",
"MS Access",
"MS Dynamics",
"MS Dynamics AXAPTA",
"MS Dynamics CRM",
"MS SQL",
"MS Visio",
"MSBI",
"Ms-Dos",
"Mster Data Management",
"Mule Esb",
"MuleSoft",
"Multimedia",
"Multimedia Softwares",
"Multimedia Streaming",
"Multiplayer Game Development",
"Multithreading",
"Mutual Funds",
"MVC",
"MVC Architecture",
"MVC Framework",
"MyCOM",
"MyCOM OSI",
"Mycom Tool",
"MySQL",
"MySQL Database Administration",
"Nagios",
"NAS",
"NBAP",
"NBFC",
"Negotiation ",
"NE-OSS Data Tramsfer",
"Nessus",
"NetBackup",
"Netcool",
"NetScaler",
"Network Access Control (NAC)",
"Network Administration",
"Network Administrator Specialist",
"Network Architecure Review",
"Network Intrusion Prevention",
"Network Operations",
"Network Security",
"Network Testing",
"Networking",
"New Product Design",
"New Product Development",
"Nework Engineer",
"Nework Engineering",
"Nework Management",
"NFC",
"NFS Server",
"Nginx",
"Ngnix",
"NGO Management",
"NGOSS",
"Nhibernate",
"NI LabView",
"NLP",
"NMS",
"NNM",
"NNMi",
"Node JS",
"Node.JS",
"Non Voice Process",
"Non-IT Assets Control",
"Non-Verbal Communication",
"Nortel",
"NoSQL",
"NoSQL - MogoDB Cassandra",
"Notification Engine",
"Novell SUSE",
"NT Backup",
"Numerical competence",
"Nursing",
"Nutition",
"OAM",
"OBIEE",
"Objective C",
"OCP",
"Odata",
"ODBC",
"Odoo",
"Office Administration",
"Office Assistance",
"Office Assistant",
"OIM",
"OLAP Intelligence",
"Onboarding",
"Online Advertising",
"Online Marketing",
"Online Reputation Mangement",
"Online Sales",
"OOAD",
"Oodata",
"OOPS",
"Open source",
"Open Stack",
"Open Stack Architecture",
"Open Stack Security",
"Open Text",
"Open Text StreamServe",
"OpenCV",
"Openersty (Nginx + Lua)",
"OpenGL",
"Openresty",
"Operating System",
"Operational Risk",
"Operational Risk Mangement",
"Operations",
"Opps Peripherals",
"Optical Networking",
"Optimisation",
"ORACLE",
"Oracle 11g : SQL Fundamentals",
"Oracle ADF",
"Oracle Advanced PL",
"Oracle APEX",
"Oracle App Dba",
"Oracle App Technical",
"Oracle Application Framework",
"Oracle Application Server",
"Oracle Apps",
"Oracle Apps Dba",
"Oracle Apps Functional",
"Oracle Apps Technical",
"Oracle AR",
"Oracle ASAP",
"Oracle ASM",
"Oracle ATG",
"Oracle AWR",
"Oracle BPEL",
"Oracle BPM",
"Oracle BRM",
"Oracle Business Intelligence",
"Oracle Certified Associate",
"Oracle Certified Expert",
"Oracle Certified Professional",
"Oracle D2K",
"Oracle DB",
"Oracle DBA",
"Oracle Developer",
"Oracle E-Business Suite",
"Oracle ERP",
"Oracle ESB",
"Oracle Exadata",
"Oracle FMW",
"Oracle Forms",
"Oracle Fusion",
"Oracle GoldenGate",
"Oracle IAM",
"Oracle Identity Manager",
"Oracle identity Manger",
"Oracle Java",
"Oracle JET",
"Oracle MySQL",
"Oracle OCA",
"Oracle OCP",
"Oracle OEM",
"Oracle OSM",
"Oracle PeopleSoft",
"Oracle PL/SQL",
"Oracle POS",
"Oracle RAC",
"Oracle RDBMS",
"Oracle RightNow",
"Oracle SOA",
"Oracle Solaris",
"Oracle Solaris 11 installation",
"Oracle Solaris Configuration",
"Oracle Spatial",
"Oracle SQL",
"Oracle UCM",
"Oracle Web Center",
"Oracle Weblogic ",
"Oracle WebLogic Server",
"Oralce UIM",
"Orale IDM",
"Order Care",
"Order Mangement",
"OSS",
"Outbond Calling",
"Outbond sales",
"Outbound process",
"Outsourcing",
"Overseas Marketing",
"OWASP",
"Paas",
"Paid Social Media",
"Paid social media advertisements",
"Palantier",
"Palo Alto",
"Palto Alto Certifications",
"Paperwork/record-keeping abilities",
"Parsing",
"Patch Management",
"Patch Testing",
"Patience",
"Patient care and assistance",
"Pay Per Click",
"Payment Gateways",
"Payment Gatway",
"Payment System",
"Payroll",
"PCB",
"PCB Designing",
"PCI:DSS",
"Penetration Testing",
"Pentaho",
"People management",
"Peoplesoft",
"PeopleSoft Fscm",
"PeopleSoft Hcm",
"Performance Engineering",
"Performance Testing",
"Performance Testing Jmeter",
"Performance Tuning",
"Performance Tuning Oracle",
"Perl",
"Persuasion",
"PESTEL ",
"PGDCA",
"PGDHRM",
"PGDM",
"PGSQL",
"Pharmacovigilance",
"Phonegap",
"Photo editing ",
"Photoshop",
"PHP",
"Physical endurance ",
"Phyton",
"PIG",
"Piping",
"Piping Design",
"Piuppet",
"PL/SQL",
"Planning",
"Planning of fiber",
"Planning Tool Usage",
"Plastic Injection Molding",
"Platform Testing",
"Play FrameWork",
"PLC",
"PMI",
"PMI PMP",
"PMO",
"PMO Mangement",
"PMP",
"PMP cert",
"Poduct Pricing",
"Polymar.JS",
"Porject Management",
"Porter’s Five Forces",
"POS",
"POSIX",
"PostgreSQL",
"Power Designer",
"Power Electronics",
"Power Shell",
"PredictionIO",
"Predictive analytics",
"Presales",
"Presentation",
"Pricing Analysis",
"PRINCE2",
"Privacy",
"Private Equity",
"Probabilistic Programming",
"Problem-solving",
"Process Automation",
"Process Compliance",
"Process Design",
"Process Excellence",
"Process Improvement",
"Procruement",
"Procuction Support",
"Procurment",
"Product Awareness",
"Product Awarness",
"Product Development",
"Product knowledge",
"Product Life Cycle",
"Product Management",
"Product Markleting",
"Product Positioning",
"Production defects reporting",
"Production defects tracking",
"Production planning",
"Production Support",
"Productivity & organization",
"Programming",
"Programming language (R, Python, Scala, Matlab)",
"Programming Languages",
"Progress 4GL",
"Project Manager - C",
"Project Manger - Oracle",
"Project Evaluation",
"Project Manager  - C++",
"Project Manager - Java/J2EE",
"Project Manager - PHP",
"Project Manager - Python",
"Project Manager - Testing",
"Project Manager - UI/UX",
"Project Manager- .Net",
"Project Manager- SAP",
"Project Mangement",
"Project Planning",
"Project Sales",
"Project/campaign management",
"Prometheous",
"Promethues",
"Proposal Management",
"Proposal writing",
"Protyping",
"Proxy Charles",
"Proxy gateway",
"Public Relations",
"Public speaking",
"Publishing",
"Purchase Mangement",
"PWA",
"Python",
"Python Arcitect",
"QA Management",
"QA Testing",
"QAD",
"QC Engineering",
"QC Inspection",
"QC Mangement",
"QC Tools",
"Qlik View",
"Qlik View Developer",
"QMS",
"QMS Implementation",
"QMS Lead Auditor",
"QRadar",
"Qradar MR4",
"QT",
"QT/ VB Script",
"QTP",
"Quality",
"Quality Assurance",
"Quality Assurance Engineering",
"Quality control",
"Quality Management",
"Quality Metrics",
"Query Handling",
"Quick Books",
"R/SAS/Matlab",
"R2V",
"Rabbit MQ",
"RAD",
"Radio Jockeying",
"RAID Configurations",
"Raido Frequency",
"Rasberry Pi",
"Rational Funcional Testing",
"RDBMS",
"React JS",
"React Native",
"Real Estate",
"Real Estate Marketing",
"Real Estate Sales",
"Real Estatee Markering",
"Real Time Communications",
"Record Keeping",
"Recruitment",
"Recuritment",
"Red Hat Linux",
"Red Hat RHCE",
"Red Hat RHCSA",
"RedHat",
"Redis",
"Redmine",
"Redux",
"Regression Testing",
"Regulatory Affairs",
"Regulatory Compliance",
"Regulatory Reporting",
"Release Management",
"Remedy",
"Remote Sensing",
"Replenishment Planning",
"Research & Data analysis",
"Research and data analysis",
"Resource Management",
"Resource Utilization",
"Responsibility",
"Responsive design principles",
"REST",
"REST API",
"Rest Assured",
"Retail",
"Retail POS hardware Testing",
"Retail Sales",
"Revenue Assurance",
"Revenue Cycle Management",
"Revenue recognition",
"REVIT Architecture",
"RF Design",
"RF Optimization",
"RF Planning",
"RFID",
"RFP",
"Risk",
"Risk Management",
"Robotic Science",
"Robotic Scientist",
"Robotics",
"ROCRA",
"Roll out budget",
"Roll out network construcion",
"Routing",
"RPA",
"RSA",
"RSA Archer",
"RSA RSA",
"RSA Security Analytics",
"RTM",
"RTOS",
"RTP",
"Ruby",
"Ruby on Rails",
"Saas",
"Sales",
"Sales Operations",
"Sales Planning",
"Sales Strategy",
"Sales Support",
"Salesforce",
"Salesforce CRM",
"SAN",
"SAP",
"SAP ",
"SAP ABAP",
"SAP ABAP CRM",
"SAP AMS",
"SAP AP",
"SAP APO DP",
"SAP Basis",
"SAP BCM",
"SAP BI",
"SAP BIBW",
"SAP BO",
"SAP BOBJ",
"SAP BODS",
"SAP BPC",
"SAP BRF",
"SAP Business intelligence (BI)",
"SAP Business one",
"Sap Business Warehouse (BW)",
"SAP BW",
"SAP BW/BI",
"SAP C4C",
"SAP CAM",
"SAP CC",
"SAP CI",
"SAP CIN",
"SAP Cloud for sales",
"SAP CO",
"SAP COPA",
"SAP CRM",
"SAP CS",
"SAP Design",
"SAP Development",
"SAP DMS",
"SAP EAM",
"SAP ECC",
"SAP EHP",
"SAP EHS",
"SAP EP",
"SAP ERP",
"SAP ESS",
"SAP EWM",
"SAP F&R",
"SAP FA",
"SAP FI",
"SAP FICA",
"SAP FICO",
"SAP FIN",
"SAP Financial Accounting",
"SAP Fiori",
"SAP FM",
"SAP FPM",
"SAP GRC",
"SAP GST",
"Sap Hana",
"SAP HCM",
"SAP HR",
"SAP HRMS",
"SAP Hybris",
"SAP ICM",
"SAP IMG",
"SAP Implementation",
"SAP integration",
"SAP IS",
"SAP ISU",
"SAP LE",
"SAP Lumira",
"SAP Management Accounting",
"SAP MDM",
"SAP MM",
"SAP Mobility",
"SAP Modelling",
"SAP MRP",
"SAP MRS",
"SAP NW",
"SAP O2C",
"SAP OM",
"SAP OSS",
"SAP OTC",
"SAP P2P",
"SAP PI",
"SAP PM",
"SAP POS",
"SAP POSDM",
"SAP PP",
"SAP PP Module",
"SAP PPPI",
"SAP PRM",
"SAP Procure to pauy (P2P)",
"SAP Procurement",
"SAP PS",
"SAP QM",
"SAP QM Module",
"SAP Real Estate",
"SAP Retail",
"SAP RFC",
"SAP SCM",
"SAP SD",
"SAP Security",
"SAP Services",
"SAP SLT",
"SAP Solution Manager",
"SAP SRM",
"SAP Success Factors",
"SAP Support",
"SAP Sybase iq",
"SAP Testing",
"SAP TM",
"SAP Transporation Management",
"SAP Treasury",
"SAP TRM",
"SAP UI5",
"SAP VIM",
"SAP Warehouse Management",
"SAP WM",
"SAP WMS",
"SAP XI",
"SAP XI/PI",
"SAP-PRM",
"SAS",
"SAS Advanced  Analytics",
"SBU Mangement",
"SCADA",
"SCALA",
"Scaled Agile",
"SCCM",
"SCM",
"SCOM",
"Scripting",
"Scrum",
"Scrum Master",
"SDK",
"SDL Tridion",
"SDLC",
"SDN",
"Search Engine and Keyword Optimization",
"Search Engine Optimization",
"Search Engine Optimization (SEO)",
"Security Analytics",
"Security Architect",
"Security ops- Monitoring",
"Security Testing",
"Selenium",
"Selenium IDE",
"Selenium WebDriver",
"SEM",
"Semantic",
"Sencha",
"Sensor",
"SEO",
"SEO/SEM",
"SEP",
"SericeNow",
"serif page plus",
"Server Administration",
"Server Configuration",
"Server Load Blancing",
"Server Management Tools",
"Service Delivery",
"Service Managemnet",
"Service Quality Mangement",
"Servlets",
"Sharepoint",
"Sharepoint Architect",
"Shell",
"Shell Scripting",
"Shell Scripting (Bash)",
"Siebel CRM",
"Siebel EIM",
"SIEM",
"Sight Survey",
"Silk Performer",
"Silk Testing",
"Silverlight",
"Simulink",
"Single Player Game Development",
"Single view",
"SIP",
"SISA PCI : DSS V3.1",
"Site Acquisition",
"Site Acquistion",
"Site Engineering",
"SiteMinder",
"Six Sigma",
"Six Sigma Black Belt",
"Six Sigma Green Belt",
"Six Sigma techniques",
"Sketch",
"Skill Development",
"Skybox",
"SLA",
"SLA Mangement",
"SME",
"SMS activity",
"smtp",
"SOA",
"SOA Certified Architect",
"SOAP",
"SOAP UI",
"SoC",
"Social Design",
"Social media and mobile marketing ",
"Social Media Marketing",
"Social media/digital communication",
"Socket Programming",
"Soft Skills",
"Soft Skills Training",
"Software",
"Software Developer",
"Software Development",
"Software Licence",
"Software QA",
"Software Services",
"Software Testing",
"SolarWinds",
"Solr",
"Solutiion Delivery",
"Solution Architect",
"Solutions Marketing",
"Source Code Analysis",
"SOX Compliance",
"SP SQM",
"Spark",
"Speech Recognition",
"Splunk",
"Sponge Iron",
"Spotfire",
"Spring",
"Spring Boot, Micoservice",
"Spring FrameWork",
"Spring Netflix Cloud",
"SPSS",
"SQL",
"SQL (a must) and Hive (optional)",
"SQL DBA",
"SQL Inection",
"SQL Server",
"SQL Server Dba",
"SQLite",
"sqoop",
"SS7",
"SSIS",
"SSRS",
"STATA, SPSS, SAS ",
"Static Equipment",
"Statistical Modelling",
"Statistics",
"Statutory Compliance",
"STB",
"STL",
"Stock Broking",
"Storage",
"Store Keeping",
"Storyboarding",
"Storytelling",
"Strategic HR",
"Strategic Management",
"Stress management",
"Stress Testing",
"Struts",
"Subex Fraud Management Testing",
"SUBEX-RA",
"Success Factors",
"Supply chain",
"Supply Chain ",
"Supply chain Analytics",
"Supply chain consulting",
"Supply Chain Management",
"Surgery preparation",
"SVN",
"SWIFT",
"Sybase",
"Syclo",
"Symantec Enpoint Protection",
"Symantec SCS Data Loss Prevention",
"Symantec STS",
"Symantec STS Messaging Gateway",
"Symbain",
"System Administration",
"System Administrator Specialist",
"System Analyst",
"System Context Diagrams",
"System Design",
"System integration",
"System Management",
"Systems Designer",
"Systems Software Engineer",
"Tableau",
"Talent Acquisition",
"Talent Mangement",
"Tally",
"Tally ERP",
"Targeting and marketing through visual communications",
"Task delegation",
"Taxation",
"TCP",
"TCP/IP",
"TDD",
"TDS Calculation",
"TDS Return",
"Team Foundation Server",
"Team Management",
"TeamSite",
"Teamwork",
"Teamwork ",
"Technical and non-technical communication",
"Technical Architecture",
"Technical Consultant",
"Technical Documentation",
"Technical Recruitment",
"Technical Support",
"Technical Training",
"Technical writing",
"Techno Commercial",
"Technological & digital literacy",
"Technological savviness",
"Telecom",
"Telecom  Operations",
"Telecom Analytics Solutions Provider",
"Telecom Marketing",
"Telecom Sales",
"Telecommunications Specialist",
"Telegraf",
"Telemetry",
"Telesales",
"Tensorflow",
"Teradata",
"Territory Management",
"Test Architecture",
"Test cases",
"Test Cases Execution",
"Test Estimation",
"Test Lead",
"Test Scenarios",
"Testing Tools",
"Testing/Debugging",
"TestLink",
"TestNG",
"TestNG Appium",
"TFS",
"The 4 P-s of Marketing",
"The McKinsey 7s Framework",
"Theano",
"Tibco",
"TIBCO ActiveDatabase Adapter",
"TIBCO Adapter ADB",
"TIBCO Adapter R3",
"TiBCO Admin",
"Tibco Administrator",
"TIBCO AS",
"TIBCO BE",
"TIBCO BW",
"Tibco BW Administrator",
"TIBCO Certifications",
"TIBCO Designer",
"TIBCO EMS",
"Tibco LogLogic",
"TIBCO MDM",
"TIBCO R/3 Adapter",
"TIBCO R3/ADB",
"TIBCO RV",
"Ticket Management",
"Time management",
"Titanium",
"TOGAF",
"Tomcat",
"Tool Design",
"Torch",
"Total Rewards",
"Trading",
"Training and Development",
"Transformation",
"Transition Management",
"Transmission - Audio/Video",
"Travel Agency",
"Truthworthiness",
"TSM",
"TSM ",
"T-SQL",
"Tutoring",
"TVJS",
"TVML",
"Twitter BootStrap",
"TypeScript",
"Typing",
"Typography: spacing, line height, layout, choosing fonts",
"UAT",
"Ubuntu",
"Ubuntx Linux",
"uDeploy",
"UFT",
"UG NX",
"UI Automation",
"UI Design",
"UI Development",
"UI/UX Testing",
"UiPath",
"Ultrasonic Testing",
"UML",
"UMTS",
"Unified Communations",
"Unigraphics ",
"Unigraphics NX",
"unit testing",
"Unity",
"Unity game development",
"Unix",
"Unix Shell Scripting",
"Unix Tools",
"Updated curriculum knowledge",
"Urban Design",
"Urban Planning",
"US Healthcare",
"Use Case Development",
"user engagement",
"User Interface Designing",
"USLAM",
"UX",
"UX Architect",
"UX design",
"UX/UI",
"Value Engineering",
"VAPT",
"VAS",
"VB",
"VB SCRIPT",
"VB.NET",
"VBA",
"VBScript",
"VC++",
"Vcloud",
"VectrPaint.net",
"Veeva",
"Vendor Development",
"Vendor Management",
"Verbal Communication",
"Verilog",
"Verilog HDL",
"Veritas Netbackup",
"Veritas VCS Cluster Server",
"VFX",
"VHDL",
"Video Conferencing",
"Video Editing",
"Video Streaming",
"Virtualization",
"Visual Basic",
"Visual Effects",
"Visual Studio",
"Visualiser",
"Visualization",
"VLAN Configuration",
"Vloume Handling",
"VLSI",
"VMC Programming",
"Vmware",
"VMWare Cloud Management",
"VMWare Network Virtualization",
"VMWare VCP",
"VMWare VCP6-Dcv",
"VMWare VCP-Cloud",
"Voice Logger",
"Voice Overs",
"Voice Process",
"VOIP",
"VPN",
"Vue.JS",
"Vulnerability Assessment",
"Vulnerability Scanning",
"WAF",
"WAN",
"Warehouse Management",
"Warehouse Operations",
"WAS",
"Waste Management",
"Waterfall model",
"WCF",
"WCM",
"WCS",
"Web  and Mobile app performer",
"Web Analytics",
"Web analytics ",
"Web API",
"Web Based Application Testing",
"Web Crawling",
"Web Designing",
"Web Hosting",
"Web Logic",
"Web scraping",
"Web Services",
"WebDynpro",
"Webmethods",
"WebRTC",
"Websphere",
"Websphere Application Server",
"Websphere Message Broker",
"WebsphereApplication Server",
"WFM",
"White Box Testing",
"WindBG",
"Window 8",
"Window System Administration",
"Windows Administartion",
"Windows Mobile Development",
"Windows Nt server",
"Windows Server Administration",
"Windows System Administration",
"Winforms",
"Wireframe",
"Wireframes",
"WIRESHARK",
"Wiring Harness",
"WLAN",
"WLAN Architect",
"WMS",
"Word processing",
"Wordpress",
"Wordpress CMS",
"Work Schedules Software",
"Worker's Compensation",
"Workflows",
"Workflows Compliance Testing",
"Workforce Management",
"WPF",
"WSDL",
"Xamarin",
"XCAP-Vuze",
"XCAp-Vuze tool",
"Xcode",
"XenDesktop",
"XHTML",
"XML Publisher Reports",
"XMPP",
"XSLT",
"YII",
"Zabbix",
"Zend Framework",
"ZigBee"]
}