<!DOCTYPE html>
<html>


<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Clicktocareers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">

</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">


        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Blog</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block">
                <div class="container">
                    <div class="row m-auto">
                        <div class="col-lg-12 column">
                            <div class="blog-single row">
                                <div class="col-lg-12">
                                    <h2 style="font-weight: bold; margin-bottom: 30px;">{{blog.title}}</h2>
                                </div>

                                <div class="col-lg-12">
                                    <div class="bs-thumb" *ngIf="blog.imageUrl">
                                        <img [src]="apiURL+blog.imageUrl" onerror="" />
                                    </div>

                                    <p class="blog-content" innerHtml="{{blog.content}}"></p>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/blog_single.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:36 GMT -->

</html>