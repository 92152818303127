import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Job } from '../models/job';
import{ GlobalConstants } from '../global-constants';

const API_URL = GlobalConstants.apiURL + 'api/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private http: HttpClient) { }

  postJob(job: Job): Observable<any> {
    return this.http.post(API_URL + 'saveJob', job, httpOptions);
  }

  getAllJobs() {
      return this.http.get<Job[]>(API_URL + 'getAllJobs',  httpOptions);
  }

  getJobDetails(id): Observable<any> {
    return this.http.post(API_URL + 'getJobDetails', {id: id}, httpOptions);
  }

  apply(jobId, userId): Observable<any> {
    return this.http.post(API_URL + 'applyJob', {jobId: jobId, userId: userId}, httpOptions);
  }

  shortlistJob(jobId, userId): Observable<any> {
    return this.http.post(API_URL + 'shortlistJob', {jobId: jobId, userId: userId}, httpOptions);
  }

  getJobsByCompany(companyId): Observable<any> {
    return this.http.post(API_URL + 'getJobsByCompany',  {companyId: companyId},  httpOptions)
  }

  shortlistUser(jobId, userId): Observable<any> {
    return this.http.post(API_URL + 'shortlistUser', {jobId: jobId, userId: userId}, httpOptions);
  }

  rejectUser(jobId, userId): Observable<any> {
    return this.http.post(API_URL + 'rejectUser', {jobId: jobId, userId: userId}, httpOptions);
  }

  deleteJob(jobId): Observable<any> {
    return this.http.post(API_URL + 'deleteJob', { jobId: jobId }, httpOptions);
  }

  updateJob(job: Job): Observable<any> {
    return this.http.post(API_URL + 'updateJob', job, httpOptions);
  }

  getJobAlerts(id): Observable<any> {
    return this.http.post(API_URL + 'getJobAlerts', {id: id}, httpOptions);
  }

  getJobsReport(): Observable<any> {
    return this.http.post(API_URL + 'getJobsReport', httpOptions);
  }
}