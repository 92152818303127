<!DOCTYPE html>
<html>


<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Click To Careers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">


</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block remove-bottom">
                <div class="container">
                    <div class="row no-gape">
                        
                        <div class="col-lg-9 column">
                            <div class="emply-resume-sec">
                                <div class="emply-resume-list square" *ngFor="let user of displayUsers; let i = index">
                                    <div class="emply-resume-thumb">
                                        <img src="{{apiURL}}{{user.imageUrl}}" onerror="this.src='assets/images/resource/employee.png';" alt="" style="width: 100px; height: 100px;" />
                                    </div>
                                    <div class="emply-resume-info">
                                        <h3><a href="candidates_details/{{user._id}}" title="">{{user.firstName}} {{user.lastName}} </a></h3>
                                        <span><i>{{user.jobTitle}}</i></span>
                                        <p *ngIf="user.experienceYear">Experince: {{user.experienceYear}} years</p>
                                        <p><i class="la la-map-marker"></i>{{user.city}} / {{user.country}}</p>
                                    </div>
                                    <div class="shortlists">
                                        <img *ngIf="user.videoUrl && user.showVideoResume" src="assets/images/resource/video.png" alt="" title="video resume" style="width: 40px; float: left;">
                                        <a href="candidates_details/{{user._id}}" title="">View Profile <i class="la la-eye"></i></a>
                                    </div>
                                </div>
                                <!-- Emply List -->

                                <!-- <div class="pagination">
								<ul>
									<li class="prev"><a href="#"><i class="la la-long-arrow-left"></i> Prev</a></li>
									<li><a href="#">1</a></li>
									<li class="active"><a href="#">2</a></li>
									<li><a href="#">3</a></li>
									<li><span class="delimeter">...</span></li>
									<li><a href="#">14</a></li>
									<li class="next"><a href="#">Next <i class="la la-long-arrow-right"></i></a></li>
								</ul>
                            </div> -->
                                <!-- Pagination -->
                            </div>
                        </div>
                        <aside class="col-lg-3 column">
                            <div class="widget border">
                                <a href="candidates_list" title=""><i class="la la-trash-o"></i> Clear filter</a>
                            </div>
                            <div class="widget">
                                <div class="search_widget_job">
                                    <div class="field_w_search">
                                        <input type="text" placeholder="Search Keywords" name="searchTitle" [(ngModel)]="searchTitle" (keyup.enter)="filter()" />
                                        <i class="la la-search"></i>
                                    </div>
                                    <!-- Search Widget -->
                                    <div class="field_w_search">
                                        <input type="text" placeholder="All Locations" name="searchCity" [(ngModel)]="searchCity" (keyup.enter)="filter()" />
                                        <i class="la la-map-marker"></i>
                                    </div>
                                    <!-- Search Widget -->
                                </div>
                            </div>
                            <div class="widget">
                                <h3 class="sb-title open">Qualification</h3>
                                <div class="specialism_widget">
                                    <div class="posted_widget">
                                        <form>
                                            <input type="radio" name="choose" (click)="qualificationFilter('Diploma')" id="19"><label for="19">Diploma</label><br />
                                            <input type="radio" name="choose" (click)="qualificationFilter('Bachelor')" id="20"><label for="20">Bachelor</label><br />
                                            <input type="radio" name="choose" (click)="qualificationFilter('Graduate')" id="21"><label for="21">Graduate</label><br />
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="widget">
                                <h3 class="sb-title open">Experience</h3>
                                <div class="specialism_widget">
                                    <div class="posted_widget">
                                        <form>
                                            <input type="radio" name="choose" (click)="experienceFilter(1, 2)" id="9"><label for="9">1Year to 2Year</label><br />
                                            <input type="radio" name="choose" (click)="experienceFilter(2, 3)" id="10"><label for="10">2Year to 3Year</label><br />
                                            <input type="radio" name="choose" (click)="experienceFilter(3, 4)" id="11"><label for="11">3Year to 4Year</label><br />
                                            <input type="radio" name="choose" (click)="experienceFilter(4, 5)" id="12"><label for="12">4Year to 5Year</label><br />
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </aside>
                    </div>
                </div>
            </div>
        </section>


    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/candidates_list3.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:10:05 GMT -->

</html>