import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from '../global-constants';
import { Blog } from '../models/blog';
import { BlogService } from '../_services/blog.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {

  blog: Blog;
  apiURL = GlobalConstants.apiURL;

  constructor(private blogService: BlogService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.blogService.getBlogDetails(this.route.snapshot.params.id).subscribe(
      data => {
        this.blog = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );  
  }

}
