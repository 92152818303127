<!DOCTYPE html>
<html>



<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>click to careers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">


</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container" *ngIf="!isUserActive" style="padding: 60px;">
                    Kindly verify email to post a job!!!
                </div>
                <div class="container" *ngIf="!isProfileUpdated" style="padding: 60px;">
                    Kindly update your profile to post a job!!!
                </div>
                <div class="container" *ngIf="isUserActive && isProfileUpdated">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="employer_profile" title=""><i class="la la-file-text"></i>Company Profile</a></li>
                                        <li><a href="employer_manage_jobs" title=""><i class="la la-briefcase"></i>Manage Jobs</a><span class="badge" *ngIf="isJobAlert"><i class='la la-bell'></i></span></li>
                                        <li><a href="candidates_list" title=""><i class="la la-flash"></i>Talent Search</a></li>
                                        <!-- <li><a href="employer_transactions" title=""><i class="la la-money"></i>Transactions</a></li> -->
                                        <!-- <li><a href="employer_resume.html" title=""><i class="la la-paper-plane"></i>Resumes</a></li> -->
                                        <!-- <li><a href="employer_packages.html" title=""><i class="la la-user"></i>Packages</a></li> -->
                                        <li><a href="employer_post_job" title=""><i class="la la-file-text"></i>Post a New Job</a></li>
                                        <li><a href="employer_password_reset" title=""><i class="la la-lock"></i>Change Password</a></li>
                                        <li><a href="employer_manage_users" title=""><i class="la la-users"></i>Manage Employers</a></li>
                                        <li><a href="employer_create_users" title=""><i class="la la-user"></i>Create Employers</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="profile-title">
                                    <h3>Post a New Job</h3>
                                </div>
                                <div class="upload-img-bar">
                                    <span><img  [src]="bannerUrl" alt="" /></span>
                                    <div class="upload-info">
                                        <input accept="image/png, image/jpeg" style="display: none" type="file" ng2FileSelect [uploader]="bannerUploader" #fileInputBanner>
                                        <button class="btn btn-new btn-round" (click)="fileInputBanner.click()"><i class="fa fa-upload"></i>Upload Banner Image</button>
                                        <span>Max file size is 1MB, Minimum dimension: 1920x1000 And Suitable files are .jpg & .png</span>
                                    </div>
                                </div>
                                <div class="profile-form-edit">
                                    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <span class="pf-title">Job Title<span style=color:#910B08>*</span><span id="tooltip_targetAdress" class="tippost" title="Keep it simple! Candidates search by job title so make sure yours is easy to find.">?</span></span>
                                                <div class="pf-field">
                                                    <input type="text" name="title" [(ngModel)]="job.title" placeholder="Job Title" />
                                                    <div *ngIf="titleError" class="alert-danger">
                                                        Title is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <span class="pf-title">Description<span style=color:#910B08>*</span><span id="tooltip_targetAdress" class="tippost" title="Describe briefly about the job requirement.">?</span></span>
                                                <div class="pf-field">
                                                    <textarea name="description" [(ngModel)]="job.description" placeholder="Job Description"></textarea>
                                                    <div *ngIf="descriptionError" class="alert-danger">
                                                        Description is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <span class="pf-title">Roles and Responsibilities<span style=color:#910B08>*</span></span>
                                                <div class="pf-field border-title" style="margin: auto;">
                                                    <textarea placeholder="responsibility" name="responsibility" [(ngModel)]="responsibility" style="width: 80%;"></textarea>
                                                    <a (click)="addResponsibility()" style="width: 20%; margin: 15px -30px;" id="responsibilityBtn"><i class="la la-plus"></i> Add </a>
                                                    <div *ngIf="responsibilityError" class="alert-danger">
                                                        <br><br><br><br>Roles and Responsibilities is Mandatory.
                                                    </div>
                                                </div>
                                                <div class="pf-field edu-history-sec" *ngFor="let responsibility of job.responsibilities; let i = index" style="margin: 10px 0;">
                                                    <span style="width: 80%;">• {{job.responsibilities[i]}}</span>
                                                    <ul class="action_job">
                                                        <li><span>Delete</span><a (click)="deleteResponsibility(i)" title=""><i class="la la-trash-o"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Job Type<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="type" [(ngModel)]="job.type">
                                                    <option>Freelance</option>
                                                    <option>Full Time</option>
                                                    <option>Internship</option>
													<option>Part Time</option>
                                                    <option>Contract</option>
                                                    <option>Volunteer</option>
													
                                                </select>
                                                    <div *ngIf="jobtypeError" class="alert-danger">
                                                        Job type is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Youtube link</span>
                                                <div class="pf-field">
                                                    <input type="text" placeholder="eg:https://www.youtube.com/watch?v=OSWUubvvC8o" name="youtubeLink" [(ngModel)]="job.youtubeLink" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Offered Salary (per annum)<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <!-- <input type="number" name="offerdSalary" [(ngModel)]="job.offerdSalary" placeholder="Offerd Salary" /> -->
                                                    <select data-placeholder="Offered Salary" class="chosen-dropdown" name="offeredSalary" [(ngModel)]="job.offerdSalary">
                                                        <option>Do not wish to disclose</option>
                                                        <option>1,00,000-2,00,000</option>
                                                        <option>2,00,000-3,00,000</option>
                                                        <option>3,00,000-4,00,000</option> 
                                                        <option>4,00,000-5,00,000</option>
                                                        <option>5,00,000-6,00,000</option>
                                                        <option>6,00,000-7,00,000</option>
                                                        <option>7,00,000-8,00,000</option>
                                                        <option>8,00,000-9,00,000</option>
                                                        <option>9,00,000-10,00,000</option>
                                                        <option>10,00,000-11,00,000</option>
                                                        <option>11,00,000-12,00,000</option>
                                                        <option>12,00,000-13,00,000</option>
                                                        <option>13,00,000-14,00,000</option>
                                                        <option>14,00,000-15,00,000</option>
                                                        <option>15,00,000-16,00,000</option>
                                                        <option>16,00,000-17,00,000</option>
                                                        <option>17,00,000-18,00,000</option>
                                                        <option>18,00,000-19,00,000</option>
                                                        <option>19,000,00-20,00,000</option>
                                                        <option>20,00,000-21,00,000</option>
                                                        <option>21,00,000-22,00,000</option>
                                                        <option>22,00,000-23,00,000</option>
                                                        <option>23,00,000-24,00,000</option>
                                                        <option>24,00,000-25,00,000</option>
                                                    </select>
                                                    <div *ngIf="offerdSalaryError" class="alert-danger">
                                                        Offered Salary is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Salary Negotiable</span>
                                                <div class="pf-field">
                                                    <select class="chosen-dropdown" name="negotiable" [(ngModel)]="job.negotiable">
													    <option value="true">Yes</option>
													    <option value="false">No</option>
												    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Salary currency<span style=color:#910B08>*</span><span id="tooltip_targetAdress" class="tippost" title="currency paid as salary.">?</span></span>
                                                <div class="pf-field">
                                                    <!-- <input type="number" name="offerdSalary" [(ngModel)]="job.offerdSalary" placeholder="Offerd Salary" /> -->
                                                    <select data-placeholder="Offered Salary currency" class="chosen-dropdown" name="currencyType" [(ngModel)]="job.currencyType">
                                                        <option>INR</option>
                                                        <option>USD</option>
                                                        <option>AUD</option>
                                                        <option>JPY</option>
                                                        <option>EUR</option>
                                                        <option>CAD</option>
                                                        <option>THB</option>
                                                        <option>AED</option>
                                                        <option>QAR</option>
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Career Level<span style=color:#910B08>*</span><span id="tooltip_targetAdress" class="tippost" title="Ideal experience you are looking in a Candidate.">?</span></span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="careerLevel" [(ngModel)]="job.careerLevel">
                                                    <option>Fresher</option>
                                                    <option>Entry-level</option>
                                                    <option>Intermediate</option>
                                                    <option>Mid-level</option>
													<option>Executive-level</option>
												</select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Experience in years<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <input type="number" name="experience" [(ngModel)]="job.experience" placeholder="Experience" />
                                                    <div *ngIf="experienceError" class="alert-danger">
                                                        Experience is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Job Post Industry<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="industry" [(ngModel)]="job.industry">
													<option>Accounting</option>
													<option>Administration and Office Support</option>
													<option>Advertisting,Arts and Media</option>
                                                    <option>Banking and Financial Services</option>
                                                    <option>Call Centre and Customer Service</option>
                                                    <option>CEO and General Mangement</option>
                                                    <option>Community Services and Development</option>
                                                    <option>Constructions</option>
                                                    <option>Consultating and Strategy</option>
                                                    <option>Design and Architecture</option>
                                                    <option>Eductation and Training</option>
                                                    <option>Engineering</option> 
                                                    <option>Farming Animals and Conservation</option>
                                                    <option>Government and Defence</option>
                                                    <option>Healthcare and Medical</option>
                                                    <option>Hospitallty and Toursim</option>
                                                    <option>Human Resources and Recruitment</option>
                                                    <option>Information and Communication Technology</option>
                                                    <option>Insurance and Superannuation</option>
                                                    <option>Legal</option>
                                                    <option>Manufacturing, Tranport and Logistics</option>
                                                    <option>Marketing and Communication</option>
                                                    <option>Mining, Resources and Energy</option>
                                                    <option>Real Estate and Property</option>
                                                    <option>Retail and Consumer Products</option>
                                                    <option>Sales</option>
                                                    <option>Science and Technology</option>
                                                    <option>Sports and Recreation</option>
                                                    <option>Trades and Services</option>
                                                </select>
                                                    <div *ngIf="industryError" class="alert-danger">
                                                        Industry is Mandatory.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Qualification</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="qualification" [(ngModel)]="job.qualification">
                                                        <option>10th</option>
                                                        <option>12th</option>
                                                        <option>Diploma</option>
                                                    <option>Graduate</option>
                                                    <option>Post Graduate</option>
                                                    <option>Doctorate</option>
												</select>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">Internal Reference(for office use only)<span id="tooltip_targetAdress" class="tippost" title="This information will not reflect on the job post and is only for your reference">?</span></span>
                                                <div class="pf-field">
                                                    <input type="text" placeholder="Reference" name="reference" [(ngModel)]="job.reference" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="border-title">
                                                    <span class="pf-title col-lg-9">Skill Requirments<span style=color:#910B08>*</span></span>
                                                    <!-- <a (click)="addSkill()" title="" class="col-lg-3"><i class="la la-plus"></i> Add Skill</a> -->
                                                </div>

                                                <div class="pf-skillfield no-margin">
                                                    <input type="text" placeholder="Skill" matInput [formControl]="myControl" [matAutocomplete]="auto" name="skill" [(ngModel)]="skill">
                                                    <mat-autocomplete #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <div class="border-title" style="width: auto; margin: 15px;">
                                                        <a (click)="addSkill()" title=""><i class="la la-plus"></i> Add Skill</a>
                                                    </div>
                                                    <div *ngIf="skillError" class="alert-danger">
                                                        Skill is Mandatory.
                                                    </div>
                                                </div>
                                                <div class="pf-field no-margin">
                                                    <ul class="tags">
                                                        <li class="addedTag" *ngFor="let skill of job.skills; let index = index;">{{skill}}<span (click)="deleteSkill(index)" class="tagRemove">x</span><input type="hidden" name="tags[]" value="skill"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 manage-jobs-sec">
                                                <div class="border-title">
                                                    <h3>Benefits</h3>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="skillTraining" id="skillTraining" [(ngModel)]="benefits.skillTraining"><label for="skillTraining">Soft Skill Training</label></p>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="jobTraining" id="jobTraining" [(ngModel)]="benefits.jobTraining"><label for="jobTraining">Job Training</label></p>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="cafeteria" id="cafeteria" [(ngModel)]="benefits.cafeteria"><label for="cafeteria">Cafeteria</label></p>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="healthInsurance" id="healthInsurance" [(ngModel)]="benefits.healthInsurance"><label for="healthInsurance">Health Insurance</label></p>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="teamOutings" id="teamOutings" [(ngModel)]="benefits.teamOutings"><label for="teamOutings">Team Outings</label></p>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="gymnasium" id="gymnasium" [(ngModel)]="benefits.gymnasium"><label for="gymnasium">Gymnasium</label></p>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="educationAssistance" id="educationAssistance" [(ngModel)]="benefits.educationAssistance"><label for="educationAssistance">Education Assistance</label></p>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="freeTransport" id="freeTransport" [(ngModel)]="benefits.freeTransport"><label for="freeTransport">Free Transport</label></p>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="childCare" id="childCare" [(ngModel)]="benefits.childCare"><label for="childCare">Child Care</label></p>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="workFromHome" id="workFromHome" [(ngModel)]="benefits.workFromHome"><label for="workFromHome">Work From Home</label></p>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="internationalRelocation" id="internationalRelocation" [(ngModel)]="benefits.internationalRelocation"><label for="internationalRelocation">International Relocation</label></p>
                                                </div>
                                                <div class="col-lg-3 simple-checkbox">
                                                    <p><input type="checkbox" name="freeFood" id="freeFood" [(ngModel)]="benefits.freeFood"><label for="freeFood">Free Food</label></p>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <span class="pf-title">Country</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select Country" class="chosen-dropdown" name="country" [(ngModel)]="job.country" (change)="getStates($event.target.value)">
                                                        <option *ngFor="let country of countries" value="{{country.name}}">{{country.name}}</option>
                                                    </select>


                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <span class="pf-title">State</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select State" class="chosen-dropdown" name="state" [(ngModel)]="job.state" (change)="getCities($event.target.value)">
                                                        <option *ngFor="let state of states" value="{{state.name}}">{{state.name}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <span class="pf-title">City</span>
                                                <div class="pf-field">
                                                    <select data-placeholder="Please Select city" class="chosen-dropdown" name="city" [(ngModel)]="job.city">
                                                        <option *ngFor="let city of cities" value="{{city.name}}">{{city.name}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <span class="pf-title">Complete Address<span style=color:#910B08>*</span></span>
                                                <div class="pf-field">
                                                    <textarea name="address" [(ngModel)]="job.address" placeholder="Address"></textarea>
                                                    <div *ngIf="addressError" class="alert-danger">
                                                        Address is Mandatory.
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <span class="pf-title">Interview Type</span>
                                                <div class="pf-field">
                                                    <div class="select-user">
                                                        <span (click)="getInterviewType('No Test')">No Interview</span>
                                                        <span (click)="getInterviewType('Online Test')">Written Interview</span>
                                                        <span (click)="getInterviewType('Video Test')">Video Interview</span>
                                                        <span (click)="getInterviewType('Video And Written Test')">Video And Written Interview</span>
                                                    </div>
                                                    <!-- <select data-placeholder="Please Select Specialism" class="chosen-dropdown" name="examType" [(ngModel)]="job.examType">
                                                        <option>Online Test</option>
                                                        <option>Video Test</option>
                                                        <option>Video And Written Test</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <!-- <span class="pf-title">Time For Questions to answer</span>
                                                <div class="pf-field">
                                                    <input type="number" name="examTime" [(ngModel)]="job.examTime" placeholder="Time" />
                                                </div> -->
                                            </div>

                                            <div class="col-lg-12" *ngIf="job.examType && job.examType != 'No Test'">
                                                <div class="border-title">
                                                    <br>
                                                    <p><b>Note:</b> Candidates will get 30 Seconds additional time to read each question by default</p>

                                                    <h3 *ngIf="job.examType == 'Video Test' || job.examType == 'Video And Written Test'">Video Interview Questions</h3>
                                                    <h3 *ngIf="job.examType == 'Online Test'">Written Interview Questions</h3>
                                                    <a (click)="addQuestion()"><i class="la la-plus" ></i> Add Questions</a>
                                                </div>
                                                <div class="pf-field col-lg-9">
                                                    <textarea class="qust" name="question" [(ngModel)]="question.question" placeholder="Question"></textarea>
                                                    <div *ngIf="questionError" class="alert-danger">
                                                        Question is Mandatory.
                                                    </div>
                                                </div>
                                                <div class="pf-field col-lg-3">
                                                    <input type="number" class="qust" name="questionTime" [(ngModel)]="question.time" placeholder="Time in minutes, eg:3" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                                                    />
                                                    <div *ngIf="questionTimeError" class="alert-danger">
                                                        Time is Mandatory.
                                                    </div>
                                                </div>
                                                <div class="edu-history-sec">
                                                    <div class="edu-history" *ngFor="let question of questions; let index = index;trackBy:trackByIndex;">
                                                        <i class="la la-graduation-cap"></i>
                                                        <div class="edu-hisinfo row">
                                                            <div class="col-lg-12">
                                                                <span class="pf-title">Question {{index+1}}</span>
                                                                <div class="pf-field">
                                                                    <textarea class="qust" name="question_{{index}}" [(ngModel)]="questions[index].question" placeholder="Question" style="width: 75%;"></textarea>
                                                                    <input type="number" class="qust" name="questionTime_{{index}}" [(ngModel)]="questions[index].time" placeholder="Time in minutes" style="width: 20%;" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                                                                    />

                                                                    <!-- <span class="pf-title" style="width: 75%;"><b>Question:</b> {{question.question}}</span>
                                                                    <span class="pf-title" style="width: 20%;"><b>Time:</b> {{question.time}} mins</span> -->
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <ul class="action_job">
                                                            <li><span>Delete</span><a (click)="deleteQuestion(index)" title=""><i class="la la-trash-o"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12" *ngIf="job.examType == 'Video And Written Test'" style="border-top: 1px solid black;">
                                                <div class="border-title">
                                                    <h3>Written Interview Questions</h3><a (click)="addQuestion2()"><i class="la la-plus" ></i> Add Questions</a>
                                                </div>
                                                <div class="pf-field col-lg-9">
                                                    <textarea class="qust" name="question2" [(ngModel)]="question2.question" placeholder="Question"></textarea>
                                                    <div *ngIf="questionError2" class="alert-danger">
                                                        Question is Mandatory.
                                                    </div>
                                                </div>
                                                <div class="pf-field col-lg-3">
                                                    <input type="number" class="qust" name="questionTime2" [(ngModel)]="question2.time" placeholder="Time in minutes, eg:3" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                                                    />
                                                    <div *ngIf="questionTimeError2" class="alert-danger">
                                                        Time is Mandatory.
                                                    </div>
                                                </div>
                                                <div class="edu-history-sec">
                                                    <div class="edu-history" *ngFor="let question of questions2; let index = index;trackBy:trackByIndex;">
                                                        <i class="la la-graduation-cap"></i>
                                                        <div class="edu-hisinfo row">
                                                            <div class="col-lg-12">
                                                                <span class="pf-title">Question {{index+1}}</span>
                                                                <div class="pf-field">
                                                                    <textarea class="qust" name="question2_{{index}}" [(ngModel)]="questions2[index].question" placeholder="Question" style="width: 75%;"></textarea>
                                                                    <input type="number" class="qust" name="questionTime2_{{index}}" [(ngModel)]="questions2[index].time" placeholder="Question Time" style="width: 20%;" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                                                                    />
                                                                    <!-- <span class="pf-title" style="width: 75%;"><b>Question:</b> {{question.question}}</span>
                                                                    <span class="pf-title" style="width: 20%;"><b>Time:</b> {{question.time}} mins</span> -->
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <ul class="action_job">
                                                            <li><span>Delete</span><a (click)="deleteQuestion2(index)" title=""><i class="la la-trash-o"></i></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <button type="submit">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>



</html>
