import { Component, OnInit } from '@angular/core';
import { Job } from '../models/job';
import { AuthService } from '../_services/auth.service';
import { JobService } from '../_services/job.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';
import { GlobalConstants } from '../global-constants';
import { User } from '../models/user';
import { BlogService } from '../_services/blog.service';
import { Blog } from '../models/blog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  form: any = {};
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  content: string;
  isLoggedIn = false;
  isLoginFailed = false;
  roles: string[] = [];
  title: string = "";
  city: string = "";
  jobs: Job[];
  user: User;
  apiURL = GlobalConstants.apiURL;
  blogs: Blog[];
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  constructor(private userService: UserService,private authService: AuthService, private tokenStorage: TokenStorageService, private jobService: JobService, private blogService: BlogService) { }

  ngOnInit(): void {

    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
    }

    this.userService.getPublicContent().subscribe(
      data => {
        this.content = data;
      },
      err => {
        this.content = JSON.parse(err.error).message;
      }
    );

    this.jobService.getAllJobs().subscribe(
      data => {
        this.jobs = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );

    this.blogService.getAllBlogs().subscribe(
      data => {
        this.blogs = data;
        console.log(this.blogs);
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );

    var currentUser = this.tokenStorage.getUser();
    if (currentUser) {
      this.isLoggedIn = true;
      this.userService.getByUsername(currentUser.email).subscribe(
        data => {
          this.user = data;
        },
        err => {
         alert(JSON.parse(err.error).message);
        }
      );
    }
  }
  
  saveSearch() {
    if(this.user && !this.user.searchList.includes(this.title)) {
      this.userService.saveSearchItem(this.user._id, this.title).subscribe(
        data => {
          this.user = data;
        },
        err => {
         alert(JSON.parse(err.error).message);
        }
      );
    } 
  }

  search(searchitem) {
    this.title = searchitem;
    this.searchJob();
  }

  deleteSearchItem(index) {
    this.userService.deleteSearchItem(this.user._id, index).subscribe(
      data => {
        this.user = data;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  searchJob() {
    if (!this.title) {
      this.title = null;
    }
    if (!this.city) {
      this.city = null;
    }
    window.location.href="/job_list/"+this.title+"/"+this.city;
  }

  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }
}
