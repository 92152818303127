import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Job } from 'src/app/models/job';
import { Company } from 'src/app/models/company';
import { JobService } from 'src/app/_services/job.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Questions } from 'src/app/models/questions';

@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.css']
})
export class EditJobComponent implements OnInit {

  job: Job; 
  company: Company;
  skills: string;
  currentUser;
  questions: Questions[];
  questions2: Questions[];
  question: Questions;
  question2: Questions;

  constructor( private jobService: JobService, private tokenStorageService: TokenStorageService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.skills = "";
    this.job = new Job();
    this.question = new Questions;
    this.question2 = new Questions;
    this.questions = [];
    this.questions2 = [];
    // this.job.examQuestions= ["hi"];
    this.currentUser = this.tokenStorageService.getUser();
    this.jobService.getJobDetails(this.route.snapshot.params.id).subscribe(
      data => {
        this.job = data;
        this.company = data.postedBy.company;
        this.questions = data.examQuestions;
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );

  }

  onSubmit() {
    var skillList = this.skills.split(',');
    this.job.skills = [];  
    skillList.forEach(element => {
      element = element.trim();
      if(element){
        this.job.skills.push(element);
      }
    });
    // this.job.postedBy = this.currentUser.email;
    this.job.examQuestions = this.questions;
    this.jobService.updateJob(this.job).subscribe(
      data => {
        alert("Successfully updated");
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  addQuestion() {
    this.questions.push(this.question);
    this.question = new Questions
  }

  deleteQuestion(i) {
    this.questions.splice(i, 1);
  }

  addQuestion2() {
    this.questions2.push(this.question2);
    this.question2 = new Questions;
  }

  deleteQuestion2(i) {
    this.questions2.splice(i, 1);
  }

  getInterviewType(interviewtype){
    this.job.examType = interviewtype;
  }
}
