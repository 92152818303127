import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalConstants } from '../global-constants';
import { Job } from '../models/job';
import { User } from '../models/user';
import { JobService } from '../_services/job.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css']
})
export class JobListComponent implements OnInit {

  jobs: Job[];
  displayJobs: Job[];
  // jobPostedOn: string;
  // jobType: string[];
  // jobIndustry: string[];
  // jobCareerLevel: string[];
  // jobQualification: string[];
  // jobExperience: string[];
  minExperience: number;
  maxExperience: number;
  typeList: string[];
  industryList: string[];
  career: string;
  qualification: string;
  day: number;
  hour: number;
  searchTitle: string;
  searchCity: string;
  user: User;
  apiURL = GlobalConstants.apiURL;
  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  isLoggedIn: boolean = false;
  pageOfItems: Array<any>;
  constructor(private route: ActivatedRoute, private jobService: JobService, private userService: UserService, private tokenStorageService: TokenStorageService) { }
 
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  ngOnInit(): void {
    // this.jobType = [];
    // this.jobIndustry = [];
    // this.jobQualification = [];
    // this.jobCareerLevel = [];
    this.minExperience = 0;
    this.maxExperience = 0;
    this.typeList = [];
    this.industryList = [];
    this.day = 0;
    this.hour = 0;
    if (this.route.snapshot.params.title && this.route.snapshot.params.title != "null") {
      this.searchTitle = this.route.snapshot.params.title;
    }
    if (this.route.snapshot.params.city && this.route.snapshot.params.city != "null") {
      this.searchCity = this.route.snapshot.params.city;
    }
    if (this.route.snapshot.params.industry && this.route.snapshot.params.industry != "null") {
      this.industryList.push(this.route.snapshot.params.industry);
    }
    this.jobService.getAllJobs().subscribe(
      data => {
        this.jobs = data;
        this.displayJobs = this.jobs.sort((a, b) => new Date(b.postedOn).getTime() - new Date(a.postedOn).getTime());
        if (this.searchCity || this.searchTitle || this.industryList) {
          this.filter();
        }
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
    var currentUser = this.tokenStorageService.getUser();
    if (currentUser) {
      this.isLoggedIn = true;
      this.userService.getByUsername(currentUser.email).subscribe(
        data => {
          this.user = data;
        },
        err => {
         alert(JSON.parse(err.error).message);
        }
      );
    }
  }

  saveSearch() {
    if(this.user && !this.user.searchList.includes(this.searchTitle)) {
      this.userService.saveSearchItem(this.user._id, this.searchTitle).subscribe(
        data => {
          this.user = data;
        },
        err => {
         alert(JSON.parse(err.error).message);
        }
      );
    } 
  }

  deleteSearchItem(index) {
      this.userService.deleteSearchItem(this.user._id, index).subscribe(
        data => {
          this.user = data;
        },
        err => {
         alert(JSON.parse(err.error).message);
        }
      );
  }

  search(searchitem) {
    this.searchTitle = searchitem;
    this.filter();
  }

  convert(str) {
    var date = new Date(str),
      // mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      var dateValue = this.monthNames[date.getMonth()] + " " + day + ", " + date.getFullYear();
    return dateValue;
  }

  timeFilter(day?, hour?) {
    if(day && hour) {
      this.day = day;
      this.hour = hour;
      // var searchTime = 60 * 60 * 1000 * day * hour; /* ms */
      // this.displayJobs = [];
      // this.jobs.forEach(element => {
      //   var time = new Date().getTime() - new Date(element.postedOn).getTime();
      //   if(time < searchTime) {
      //     this.displayJobs.push(element);
      //   }
      // });    
    }
    else {
      this.day = null;
      this.hour = null;
    }
    this.filter();
  }

  typeFilter(type) {  
    if (!this.typeList.includes(type)) {
      this.typeList.push(type);
    } else {
      const index = this.typeList.indexOf(type, 0);
      if (index > -1) {
         this.typeList.splice(index, 1);
      }    
    }
    this.filter();
  }

  industryFilter(industry) {  
    if (!this.industryList.includes(industry)) {
      this.industryList.push(industry);
    } else {
      const index = this.industryList.indexOf(industry, 0);
      if (index > -1) {
         this.industryList.splice(index, 1);
      }    
    }
    this.filter();
  }

  careerFilter(career) {  
    this.career = career;
    this.filter();
  }

  qualificationFilter(qualification) {  
    this.qualification = qualification;
    this.filter();
  }
  
  experienceFilter(min?, max?) {
    this.minExperience = min;
    this.maxExperience = max;
    this.filter();
  }

  filter() {

    //  title filter
    if (this.searchTitle) {
      var temp = this.jobs;
      this.displayJobs = [];
      temp.forEach(element => {
        if (element.title.toLowerCase().includes(this.searchTitle.toLowerCase()) || element.company.name.toLowerCase().includes(this.searchTitle.toLowerCase())) {
          this.displayJobs.push(element);
        }
      });
    } 
    else {
      this.displayJobs = this.jobs;
    }

    //  city filter
    if (this.searchCity) {
      var temp = this.displayJobs;
      this.displayJobs = [];
      temp.forEach(element => {
        if (element.city.toLowerCase().includes(this.searchCity.toLowerCase())) {
          this.displayJobs.push(element);
        }
      });
    }


    // time filter
    if(this.day && this.hour) {
      var searchTime = 60 * 60 * 1000 * this.day * this.hour; /* ms */
      var temp = this.displayJobs;
      this.displayJobs = [];
      temp.forEach(element => {
        var time = new Date().getTime() - new Date(element.postedOn).getTime();
        if(time < searchTime) {
          this.displayJobs.push(element);
        }
      });    
    }
    
    // jobtype filter
    if (this.typeList.length > 0) {
      var temp = this.displayJobs;
      this.displayJobs = [];
      temp.forEach(job => {
        this.typeList.forEach(element => {
          if(job.type == element) {
            this.displayJobs.push(job);
          } 
        });
      });        
    }

    // industry filter
    if (this.industryList.length > 0) {
      var temp = this.displayJobs;
      this.displayJobs = [];
      temp.forEach(job => {
        this.industryList.forEach(element => {
          if(job.industry == element) {
            this.displayJobs.push(job);
          } 
        });
      });        
    } 

    // career filter
    if (this.career) {
      var temp = this.displayJobs;
      this.displayJobs = [];
      temp.forEach(job => {
        
          if(job.careerLevel == this.career) {
            this.displayJobs.push(job);
          
        }
      });        
    }

    //  qualification filter
    if (this.qualification) {
      var temp = this.displayJobs;
      this.displayJobs = [];
      temp.forEach(job => {
          if(job.qualification == this.qualification) {
            this.displayJobs.push(job);
          } 
      });        
    }

    // experience filter
    if(this.minExperience && this.maxExperience) {
      var temp = this.displayJobs;
      this.displayJobs = [];
      temp.forEach(element => {
        if( element.experience >= this.minExperience && element.experience < this.maxExperience) {
          this.displayJobs.push(element);
        }
      });    
    }


  }
  
}
