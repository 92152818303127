import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import{ GlobalConstants } from '../global-constants';
import { Blog } from '../models/blog';

const API_URL = GlobalConstants.apiURL + 'api/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpClient) { }

  getAllBlogs() {
    return this.http.post<Blog[]>(API_URL + 'getAllBlogs',  httpOptions);
  }

  saveBlog(blog: Blog): Observable<any> {
    return this.http.post(API_URL + 'saveBlog', blog, httpOptions);
  }

  updateBlog(blog: Blog): Observable<any> {
    return this.http.post(API_URL + 'updateBlog', blog, httpOptions);
  }

  deleteBlog(blogId): Observable<any> {
    return this.http.post(API_URL + 'deleteBlog', { blogId: blogId }, httpOptions);
  }

  getBlogDetails(id): Observable<any> {
    return this.http.post(API_URL + 'getBlogDetails', {id: id}, httpOptions);
  }
}
