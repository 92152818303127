<!DOCTYPE html>
<html>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_resume.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:52 GMT -->

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Job Hunt</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">


</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
        <span>Skip Loader</span>
    </div>

    <div class="theme-layout" id="scrollup">

        <section class="overlape">
            <div class="block no-padding">
                <div data-velocity="-.1" style="background: url(assets/images/resource/mslider1.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="inner-header">
                                <h3>Welcome</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="block no-padding">
                <div class="container">
                    <div class="row no-gape">
                        <aside class="col-lg-3 column border-right">
                            <div class="widget">
                                <div class="tree_widget-sec">
                                    <ul>
                                        <li><a href="admin_manage_users" title=""><i class="la la-users"></i>Manage Active Users</a></li>
                                        <li><a href="admin_manage_deactivated_users" title=""><i class="la la-users"></i>Manage Deactivated Users</a></li>
                                        <li><a href="admin_manage_jobs" title=""><i class="la la-briefcase"></i>Manage Jobs</a></li>
                                        <li><a href="exam_list" title=""><i class="la la-file-text"></i>View All Exams</a></li>
                                        <li><a href="add_blog" title=""><i class="la la-file-text"></i>Add Blog</a></li>
                                        <li><a href="admin_blog_list" title=""><i class="la la-file-text"></i>Blog List</a></li>
                                        <li><a href="monthly_user_signup_report" title=""><i class="la la-file"></i>Monthly Signup Report</a></li>
                                        <li><a href="user_signup_report" title=""><i class="la la-file"></i>Signup Report</a></li>
                                        <li><a href="job_post_report" title=""><i class="la la-file"></i>Job Posted Report</a></li>
                                        <li><a href="job_detailed_report" title=""><i class="la la-file"></i>Job Detailed Report</a></li>
                                        <li><a href="admin_password_reset" title=""><i class="la la-lock"></i>Change Password</a></li>
                                        <!-- <li><a href="#" title=""><i class="la la-unlink"></i>Logout</a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div class="col-lg-9 column">
                            <div class="padding-left">
                                <div class="emply-resume-sec">
                                    <h3>User Registartion Report</h3>

                                    <div class="manage-jobs-sec">
                                        <div class="manage-jobs-sec">
                                            <div class="extra-job-info">
                                                <span> Start Date: <input type="date" placeholder="Start date" name="startDate" [(ngModel)]="startDate"/></span>
                                                <span> End Date: <input type="date" placeholder="End date" name="endDate" [(ngModel)]="endDate"/></span>
                                                <span>
                                                    <button (click)="search()" class="search-btn">Search</button>
                                                    <button (click)="exportAsXLSX()" class="download-btn">Download</button>
                                                </span>
                                            </div>
                                        </div>

                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>User</td>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let user of displayUsers; let i = index">
                                                    <td>
                                                        <div class="table-list-title">
                                                            <div class="emply-resume-thumb">
                                                                <img [src]="apiURL+user.imageUrl" onerror="this.src='assets/images/resource/employee.png';" style="width: 75px;" />
                                                            </div>
                                                            <div class="emply-resume-info">
                                                                <h3>{{user.firstName}} {{user.lastName}}</h3>
                                                                <span *ngIf="user.jobTitle"><i>{{user.jobTitle}}</i></span>
                                                                <span *ngIf="user.company"><i>{{user.company.name}}</i></span>
                                                                <span><i>{{user.email}}</i></span>
                                                                <span *ngFor="let role of user.roles; let i = index"><i>{{role.name}}</i></span>
                                                                <p *ngIf="user.city"><i class="la la-map-marker"></i>{{user.city}} / {{user.country}}</p>
                                                            </div>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>

<!-- Mirrored from grandetest.com/theme/jobhunt-html/employer_resume.html by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 10 Sep 2020 13:09:54 GMT -->

</html>