import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/global-constants';
import { Blog } from 'src/app/models/blog';
import { BlogService } from 'src/app/_services/blog.service';

@Component({
  selector: 'app-admin-blog-list',
  templateUrl: './admin-blog-list.component.html',
  styleUrls: ['./admin-blog-list.component.css']
})
export class AdminBlogListComponent implements OnInit {

  blogs: Blog[];
  apiURL = GlobalConstants.apiURL;

  constructor(private blogService: BlogService) { }

  ngOnInit(): void {
    this.blogService.getAllBlogs().subscribe(
      data => {
        this.blogs = data;
        console.log(this.blogs);
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

  deleteBlog(id) {
    this.blogService.deleteBlog(id).subscribe(
      data => {
        alert("Successfully deleted");
        this.ngOnInit();
      },
      err => {
       alert(JSON.parse(err.error).message);
      }
    );
  }

}
