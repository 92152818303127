<!DOCTYPE html>
<html>


<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Clicktocareers</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="author" content="CreativeLayers">


</head>

<body>

    <div class="page-loading">
        <img src="assets/images/loader.gif" alt="" />
    </div>

    <div class="theme-layout" id="scrollup">

        <section>
            <div class="block no-padding">
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="main-featured-sec">
                                <div class="new-slide">
                                    <!-- <img src="assets/images/resource/vector-1.png"> -->
                                </div>
                                <div class="job-search-sec">
                                    <div class="job-search">
                                        <h3>The Easiest Way to Get Your New Job</h3>
                                        <span>Find Jobs, Employment & Career Opportunities</span>
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-7 col-md-5 col-sm-12 col-xs-12">
                                                    <div class="job-field">
                                                        <input type="text" placeholder="Job title, keywords or company name" name="title" [(ngModel)]="title" />
                                                        <i class="la la-keyboard-o"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                                                    <div class="job-field">
                                                        <input type="text" placeholder="City, province or region" name="city" [(ngModel)]="city" />
                                                        <i class="la la-map-marker"></i>
                                                    </div>
                                                </div>
                                                <div class="col-lg-1 col-md-2 col-sm-12 col-xs-12">
                                                    <a (click)="searchJob()" title=""><i class="la la-search"></i></a>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="tags-bar" *ngIf="isLoggedIn">
                                            <div class="or-browser" style="width: auto; margin: auto;">
                                                <span style="margin-right: 20px;">Saved Search:</span>
                                            </div>
                                            <span *ngFor="let searchItem of user.searchList; let i = index"><span (click)="search(searchItem)" style="cursor: pointer;">{{searchItem}}</span><i class="close-tag" (click)="deleteSearchItem(i)">x</i></span>
                                            <div class="action-tags" *ngIf="title">
                                                <a (click)="saveSearch()" title="Save your search"><i class="la la-cloud-download"></i> Save Search Result</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="scroll-to">
                                    <a href="#scroll-here" title=""><i class="la la-arrow-down"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section id="scroll-here" style="z-index: 0;" class="d-none d-md-block">
            <div class="block">
                <div data-velocity="-.1" style="background: url(assets/images/resource/parallax3.jpg) repeat scroll 50% 422.28px transparent; top: -65px;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="heading">
                                <h2>Click to Careers Features</h2>
                                <span>.</span>
                            </div>
                            <!-- Heading -->
                            <div class="blog-sec">
                                <div class="rowhome">
                                    <div class="col-lg-3">
                                        <div class="my-blog">
                                            <div class="blog-thumb" style="text-align: center;">
                                                <a href="#" title=""><i class="la la-laptop" style="float: left; width: 100%; color: #910B08;font-size: 181px;"></i></a>
                                                <div>
                                                    <img src="assets/images/resource/featured.png" style="position: absolute; right: 0; width: 67px;">
                                                </div>
                                            </div>
                                            <div class="blog-details">
                                                <h3>Video Resume</h3>
                                                <p>Impress employers, not just with words but your personality, speech, and skills in action… add a video resume to your profile and be future-ready.</p>
                                                <a class="signup-popup" title="">Signup <i class="la la-long-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="my-blog">
                                            <div class="blog-thumb" style="text-align: center;">
                                                <a href="#" title=""><i class="la la-play-circle-o" style="float: left; width: 100%; color :#910B08; font-size: 181px;"></i></a>
                                                <div>
                                                    <img src="assets/images/resource/featured.png" style="position: absolute; right: 0; width: 67px;">
                                                </div>
                                            </div>
                                            <div class="blog-details">
                                                <h3>Automated Video Interviews
                                                </h3>
                                                <p>Conduct and watch video interviews where you add questions and candidates answer them as in a real interview. Assess right talent for credibility and soft-skills.
                                                </p>
                                                <a class="signup-popup" title="">Signup <i class="la la-long-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="my-blog">
                                            <div class="blog-thumb" style="text-align: center;">
                                                <a href="#" title=""><i class="la la-edit" style="float: left; width: 100%; color: #910B08;font-size: 181px;"></i></a>
                                                <div>
                                                    <img src="assets/images/resource/featured.png" style="position: absolute; right: 0; width: 67px;">
                                                </div>
                                            </div>
                                            <div class="blog-details">
                                                <h3>Online Written Interviews</h3>
                                                <p>Ensure that you have the right candidate on board with the help of a well-planned written interview process, right after the video interviews have been conducted.
                                                </p>
                                                <a class="signup-popup" title="">Signup <i class="la la-long-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="d-none d-md-block">
            <div class="block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="heading">
                                <h2>Popular Categories</h2>
                                <!-- <span>37 jobs live - 0 added today.</span> -->
                            </div>
                            <!-- Heading -->
                            <div class="cat-sec">
                                <div class="row no-gape">
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Information and Communication Technology" title="">
                                                <i class="la la-desktop"></i>
                                                <span>Information Technology</span>
                                                <!-- <p>(22 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Marketing and Communication" title="">
                                                <i class="la la-bar-chart"></i>
                                                <span>Marketing</span>
                                                <!-- <p>(6 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Sports and Recreation" title="">
                                                <i class="la la-futbol-o "></i>
                                                <span>Sports & Recreation</span>
                                                <!-- <p>(3 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Call Centre and Customer Service" title="">
                                                <i class="la la-headphones"></i>
                                                <span>Call Center & Customer Service</span>
                                                <!-- <p>(3 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cat-sec">
                                <div class="row no-gape">
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Advertisting,Arts and Media" title="">
                                                <i class="la la-bullhorn"></i>
                                                <span>Design, Art & Multimedia</span>
                                                <!-- <p>(22 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Eductation and Training" title="">
                                                <i class="la la-graduation-cap"></i>
                                                <span>Education & Training</span>
                                                <!-- <p>(6 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Accounting" title="">
                                                <i class="la la-line-chart "></i>
                                                <span>Accounting / Finance</span>
                                                <!-- <p>(3 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Human Resources and Recruitment" title="">
                                                <i class="la la-users"></i>
                                                <span>Human Resource</span>
                                                <!-- <p>(3 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cat-sec">
                                <div class="row no-gape">
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Hospitallty and Toursim" title="">
                                                <i class="la la-plane"></i>
                                                <span>Hospitality and Toursim</span>
                                                <!-- <p>(22 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Retail and Consumer Products" title="">
                                                <i class="la la-cart-plus"></i>
                                                <span>Retail and Consumer Products</span>
                                                <!-- <p>(6 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Constructions" title="">
                                                <i class="la la-building"></i>
                                                <span>Construction / Facilities</span>
                                                <!-- <p>(3 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">
                                        <div class="p-category">
                                            <a href="/job_list/Healthcare and Medical" title="">
                                                <i class="la la-user-md"></i>
                                                <span>Health</span>
                                                <!-- <p>(3 open positions)</p> -->
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="browse-all-cat">
                                <a href="/job_list" title="">Browse All Categories</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="d-none d-md-block">
            <div class="block double-gap-top double-gap-bottom">
                <div data-velocity="-.1" style="background: url(assets/images/resource/parallax1.jpg) repeat scroll 50% 705.28px transparent;" class="parallax scrolly-invisible layer color"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="simple-text-block">
                                <h3>Make a Difference with Your Online Resume!</h3>
                                <span>Your resume in minutes with clicktocareers resume assistant</span>
                                <a class="signup-popup" title="">Create an Account</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="d-none d-md-block">
            <div class="block">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="heading">
                                <h2>Featured Jobs</h2>
                                <span>Leading Employers are already looking for job seekers and talents.</span>
                            </div>
                            <!-- Heading -->
                            <div class="job-listings-sec" *ngFor="let job of jobs | slice:0:6;">
                                <a href="/job_details/{{job._id}}" title="">
                                    <div class="job-listing">
                                        <div class="job-title-sec">
                                            <div class="c-logo"> <img [src]="apiURL+job.company.imageUrl" onerror="this.src='assets/images/resource/employer.png';" style="width: 75px;" /> </div>
                                            <h3 style="padding: 0 20px;"><a href="/job_details/{{job._id}}" title="">{{job.title}}</a></h3>
                                            <span style="padding: 0 20px;">{{job.company.name}}</span>
                                        </div>
                                        <span class="job-lctn"><i class="la la-map-marker"></i>{{job.city}}, {{job.country}}</span>
                                        <!-- <span class="fav-job"><i class="la la-heart-o"></i></span> -->
                                        <span class="job-is ft">{{job.type}}</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="browse-all-cat">
                                <a href="/job_list" title="">Load more listings</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="d-none d-md-block">
            <div class="block">
                <div data-velocity="-.1" style="background: url(assets/images/resource/parallax2.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible layer color light"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="heading light">
                                <h2>Kind Words From Happy Customers</h2>
                                <span>What people think about the service provided by click to careers</span>
                            </div>
                            <!-- Heading -->
                            <div class="reviews-sec" id="reviews-carousel">
                                <div class="col-lg-6">
                                    <div class="reviews">
                                        <!-- <img src="assets/images/resource/r1.jpg" alt="" /> -->
                                        <h3>Ruben Paul <span>Human Resource</span></h3>
                                        <p>"The timeline was a tight one and the needs were specific. Thanks for coming through for us. A job well done!" <br><br><br></p>
                                    </div>
                                    <!-- Reviews -->
                                </div>
                                <div class="col-lg-6">
                                    <div class="reviews">
                                        <!-- <img src="assets/images/resource/r2.jpg" alt="" /> -->
                                        <h3>Nikhil Premdas <span>Web designer</span></h3>
                                        <p>Without Click to Careers i’d be homeless, they found me a job and got me sorted out quickly with everything! Can’t quite believe the service. <br><br><br></p>
                                    </div>
                                    <!-- Reviews -->
                                </div>
                                <div class="col-lg-6">
                                    <div class="reviews">
                                        <!-- <img src="assets/images/resource/r1.jpg" alt="" /> -->
                                        <h3>Neha Fathima <span>Digital Marketing Agency</span></h3>
                                        <p>"Working with Click to Careers has made our job considerably easier. Having a consultant who specializes in our industry and understands our specific needs adds value to the services that they provide."</p>
                                    </div>
                                    <!-- Reviews -->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="d-none d-md-block">
            <div class="block">
                <div data-velocity="-.1" style="background: url(assets/images/resource/parallax3.jpg) repeat scroll 50% 422.28px transparent;" class="parallax scrolly-invisible no-parallax"></div>
                <!-- PARALLAX BACKGROUND IMAGE -->
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="heading">
                                <h2>Quick Career Tips</h2>
                                <span>.</span>
                            </div>
                            <!-- Heading -->
                            <div class="blog-sec">
                                <div class="row">
                                    <div class="col-lg-4" *ngFor="let blog of blogs | slice: 0:3">
                                        <div class="my-blog">
                                            <div class="blog-thumb">
                                                <a href="/blog_details/{{blog._id}}" title=""><img [src]="apiURL+blog.imageUrl" onerror="" alt="" /></a>
                                                <div class="blog-metas">
                                                    <a href="/blog_details/{{blog._id}}" title="">{{convert(blog.postedOn)}}</a>
                                                </div>
                                            </div>
                                            <div class="blog-details">
                                                <h3><a href="/blog_details/{{blog._id}}" title="">{{blog.title}}</a></h3>
                                                <p class="blog-content" [innerHtml]="blog.content"></p>
                                                <a href="/blog_details/{{blog._id}}" title="">Read More <i class="la la-long-arrow-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="browse-all-cat">
                                <a href="/blog_list" title="">Load more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="d-none d-md-block">
            <div class="block no-padding">
                <div class="container fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="simple-text">
                                <h3>Got a question?</h3>
                                <span>We're here to help. Check out our FAQs, send us an email or call us at +91-9620695529</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>

</body>


</html>